import React, { useEffect, useState } from 'react'
import { APIs } from '../../../../constants/apis';
import {CheckBoxOutlineBlankRounded, CheckBoxRounded, ClearOutlined, DragIndicator } from '@mui/icons-material';
import useExiosPrivate from '../../../../utilities/hooks/useAxiosPrivate';
import { Input, Tooltip } from '@mui/joy';
import _ from 'lodash';
import { useSortable } from '@dnd-kit/sortable';
import { forwardRef } from 'react';
import { CSS } from "@dnd-kit/utilities";

const TodoItemSkeleton = ({ todo, updateCheckForTask, deleteTodo }: any) => {
    const exiosPrivate = useExiosPrivate();
    const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    } = useSortable({ id: todo.id });
    
    const style:any = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative"
    };
    
    const [todoText, setTodoText] = useState(todo.title);

  useEffect(() => {
    setTodoText(todo.title)
  }, [todo.title])

  const setTodoTextHandler = (title: string) => {
    setTodoText(title);
    if (title) {
      debouncedEditTodo(title)
    }
  }

  const debouncedEditTodo = React.useRef(
    _.debounce(async (title:string) => {
      try {
        await exiosPrivate.post(APIs.EDIT_TASK_TITLE, {
          title,
          id: todo.id,
        });
      } catch (err) {
        console.log("err", err);
      }
    }, 500)
  ).current;

  return <div style={style} ref={setNodeRef} key={todo.id} className={`todoSkeleton todoItem ${todo.isChecked ? "checked": ""}`}>
    <div className='todo-mover flex-center' {...attributes} {...listeners}>
      <DragIndicator />
    </div>
    <div onClick={() => updateCheckForTask(!todo.isChecked, todo.id)} className='todoChecker flex-center'>
      {
        todo.isChecked ? 
      <CheckBoxRounded sx={{ fontSize: "1.3rem", color: "#05bb13" }} />
          : <CheckBoxOutlineBlankRounded sx={{ fontSize: "1.3rem", color: "#a6a6a6" }} />
      }
    </div>
    <div className='full todo'>
      <Input onChange={(e:any) => setTodoTextHandler(e.currentTarget.value)} className='todoInput' value={`${todoText}`} defaultValue={todoText} />
      <div className='todo-action'>
        <Tooltip onClick={() => deleteTodo(todo.id)} title="Delete Todo">
          <ClearOutlined />
        </Tooltip>
      </div>
    </div>
  </div>
}

export default TodoItemSkeleton