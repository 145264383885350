import { TableChartOutlined, TableRows, TableRowsOutlined } from '@mui/icons-material'
import React from 'react'
import { TipTapMenuActions } from '../TiptapWrapper'

const TextTableOptions = ({actionMenuClick}:any) => {
  return (
      <div className="bubble-btn rel">
            <div className="header-text" onClick={() => actionMenuClick(TipTapMenuActions.table)}>
              <TableChartOutlined />
            </div>
          </div>
  )
}

export default TextTableOptions