import { CubicalBody } from "./components/body"
import { CubicalDrawer } from "./components/drawer"
import { CubicalHeader } from "./components/header"
import "./index.scss"

const Cubical = ({ children, isLoading }: any) => {
  return (
    <div className={`cubical ${isLoading && "loading"}`}>{children}</div>
  )
}

Cubical.header = CubicalHeader;


Cubical.drawer = CubicalDrawer;
Cubical.body = CubicalBody;



export default Cubical