import React, { useEffect, useState } from "react";
import "./index.scss";

interface FormErrorProps {
  text: string;
  clearError: any;
}
export const FormError = ({ text }: FormErrorProps) => {
  return <div className="error">{text}</div>;
};

export const FormErrorWithTimeout = ({ text, clearError }: FormErrorProps) => {
  const [errorText, setErrorText] = useState(text);

  useEffect(() => {
    setErrorText(text);
    const timer = setTimeout(() => {
      setErrorText("");
      clearError("");
    }, 6000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  if (!errorText) return <></>;

  return <div className="errorBox">{errorText}</div>;
};
