import React, { useState, useEffect } from "react";

import { Button, Checkbox, FormControl, FormLabel, Grid, Input, Stack, Tooltip, Typography } from "@mui/joy";
import { FormErrorWithTimeout } from "../../../components/cards/formError";
import { SpinnerContainer } from "../../../components/SpinnerContainer";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";
import IconSelector from "../../../components/IconSelector";
import { ChevronRight, DangerousRounded } from "@mui/icons-material";
import { AModal } from "../../../components/modal/AModal";
import { APIs } from "../../../constants/apis";
import { useAppDispatch } from "../../../redux/hooks";
import { updateUserWithNewWorkspaceConfiguration } from "../../../redux/reducers/User";

const ConfigureSpace = ({ space, close }:any) => {
    const exiosPrivate = useExiosPrivate();
    const dispatch = useAppDispatch();
    const [showAdvanceOptions, toggleAdvanceOptions] = useState(false);
    const [showDeleteOptions, toggleDeleteOptions] = useState(false);
    const [data, setData] = useState<any>({
            id: space.id,
            name: space.name,
            icon: space.icon,
            allow_children: space.allow_children
        });
    const [loader, toggleLoader] = useState(false);
    const [error, setError] = useState("");
    
    const setName = (e: any) => {
        const newData = { ...data };
        newData.name = e.currentTarget?.value;
        setData(newData);
    };

    useEffect(() => {
        setData((prev: any) => ({ name: space.name, icon: space.icon, allow_children: space.allow_children, id: space.id }))
    }, [space.name]);

    const deleteSpace = () => {
        close()
    }

    const saveConfigurations = async () => {
      await exiosPrivate.post(APIs.SPACE_CONFIGURATIONS, { ...data });
      dispatch(updateUserWithNewWorkspaceConfiguration(data))
      close();
    }

    const setAllowChildren = (e: any) => {
        const newData = { ...data };
        newData.allow_children = e.currentTarget?.checked;
        setData(newData);
    }
    
  return (
      <div>
      {loader ? (
        <SpinnerContainer height="430px" />
      ) : (
          <div>
            <Stack padding="1rem" paddingTop={0}>
              <Grid xs={12} sm={12} md={8} lg={12} mt="1rem">
                  <FormControl>
                    <FormLabel required>Name of the Space</FormLabel>
                    <Input
                      onChange={setName}
                      type="text"
                      defaultValue={data.name}
                      value={data.name}
                    />
                  </FormControl>
                          </Grid>
                    <Grid xs={12} sm={12} md={8} lg={12} mt="1rem">
                    <FormControl>
                    <IconSelector
                    from="none"
                    icon={data.icon}
                    setIcon={(icon: string) =>
                    setData((prev:any) => ({ ...prev, icon }))
                }
                noImg={true}
                name={data.name}
                />
                </FormControl>
                          </Grid>
                {/* <Grid xs={12} sm={12} md={8} lg={12} mt="1rem">
                    <FormControl>
                  <Checkbox
                      checked={data.allow_children}
                      size="sm"
                      onChange={setAllowChildren}
                      label={<Typography>Allow others to create folders and entities inside the space</Typography>}
                    />
                              </FormControl>
                              
                          </Grid> */}

                          <Typography sx={{color: "#fd5f13", cursor: "pointer"}} onClick={() => toggleAdvanceOptions((prev:any) => !prev)} mt={"1rem"}>
                              Show Advance options
                          </Typography>

                          {
                              showAdvanceOptions &&
                              <div style={{marginTop: "0.5rem"}}><FormControl>
                                <Typography onClick={() => toggleDeleteOptions((prev:any) => !prev)} sx={{textDecoration: "underline", color: "red", display: "flex", cursor: "pointer"}} fontWeight={500}>Delete Space <DangerousRounded /></Typography>
                                </FormControl></div>
                          }
                          {
                              showDeleteOptions && <AModal title="Delete Space" onClose={() => close()}>
                                  <div className="p1">
                                      <p className="text-center">
                                          <DangerousRounded color="error" fontSize="large"   />
                                     
                                        <Typography onClick={() => toggleDeleteOptions((prev:any) => !prev)} fontWeight={500}>Are you sure, you want to delete this space!</Typography>
                                        <Typography onClick={() => toggleDeleteOptions((prev:any) => !prev)} fontWeight={500}>All folders, entities and data inside space will be deleted.</Typography>
                                        <Tooltip title="Warning !!">
                                              <Typography onClick={deleteSpace} style={{ color: "red", cursor: "pointer", marginTop: "2rem", textDecoration: "underline" }}
                                               fontWeight={500}>Delete this Space</Typography>
                                              </Tooltip>
                                   </p>
                                  </div>
                              </AModal>
                            }
              <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                >
                <FormErrorWithTimeout
                    clearError={() => setError("")}
                    text={error}
                />
                
                <Button
                    onClick={saveConfigurations}
                    size="md"
                    fullWidth
                    variant="solid"
                    color="primary"
                >
                    Save Changes 
                </Button>
                </Grid>
            </Grid>
            </Stack>
          </div>
      )}
    </div>
  )
}

export default ConfigureSpace