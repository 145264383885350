import { CircularProgress, FormControl, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { SingleFileUploader } from "../../../../../components/SingleFileUploader";
import PATHS from "../../../../../constants/paths";
import { useExiosPrivateMulti } from "../../../../../utilities/hooks/useAxiosPrivate";
import { textInitials } from "../../../../../utilities/sharedUtilties";
import { APIs } from "../../../../../constants/apis";
import { useAppSelector } from "../../../../../redux/hooks";
import { User } from "../../../../../redux/reducers/User";

export const ProfilePicture = ({ refreshUser }: any) => {

  const exiosPrivateMulti = useExiosPrivateMulti();
  const [dpLoad, toggleDpLoad] = useState(false);
  const { data: user }: any = useAppSelector(User);

  const handleImageUpload = async (file: any) => {
    try {
      toggleDpLoad(true);
      const formData = new FormData();
      formData.append("file", file);
      await exiosPrivateMulti.post(APIs.USER_UPLOAD_DP, formData);
      await refreshUser();
      toggleDpLoad(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="flex-center-full ">
      <div className="dp-container">
        <div className="dp">
          <div
            data-username={textInitials("")}
            className={`${dpLoad ? `loading` : ``} ${
              user.dp === "" && `showDefault`
            } dp-wrapper`}
          >
            {dpLoad && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
                variant="soft"
              />
            )}
            {user.dp && <img src={user.dp} alt="profile dp" />}
          </div>
        </div>

        <FormControl sx={{ width: 330 }}>
          <SingleFileUploader
            setImage={handleImageUpload}
            name="dp"
            maxSize={200000}
            minSize={5000}
            types={["jpeg", "png", "jpg"]}
          >
            <Typography mt={1} textAlign="center" variant="outlined">
              Upload New Profile Picture
            </Typography>
          </SingleFileUploader>
          <Typography fontSize={12} mt={1} textAlign="center" variant="plain">
            Upload ".jpg, .jpeg, .png,", Max Size: 200KB, MinSize: 5KB Image
            dimension prefered will be 200 X 200
          </Typography>
        </FormControl>
      </div>
    </div>
  );
};
