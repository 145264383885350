import React, { useEffect, useState } from "react";
import Pages from "../index";
import PATHS from "../../constants/paths";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { UserSessionData, defaultUserSessionData } from "../../types/user";
import { HomeLayout } from "../../layouts/home";

export const Hello = () => {
  const exiosPrivate = useExiosPrivate();
  const [data, setData] = useState<UserSessionData>(defaultUserSessionData);
  const controller = new AbortController();
  let isMounted = true;

  const getUser = async () => {
    try {
      const response = await exiosPrivate.get(PATHS.user, {
        signal: controller.signal,
      });
      isMounted && setData(response.data.user);
      console.log(data);
    } catch (err) {
      //console.log("erro", err);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeLayout>
      <Pages.Home />
    </HomeLayout>
  );
};
