import { FormatListBulleted, FormatListNumbered, TextFieldsOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { TipTapMenuActions } from '../TiptapWrapper'
import { Divider, Tooltip, Typography } from '@mui/joy';

const TextFieldOptions = ({ actionMenuClick, hide, isEditorFocus }: any) => {
    const [subBubble, setSubBubble] = useState(false);

    const setAction = (type: string) => {
        setSubBubble(false);
        actionMenuClick(type)
    }

    useEffect(() => {
        if (isEditorFocus) {
            setSubBubble(false)
        }
    }, [isEditorFocus])

    return (
          <>
            <div onClick={() => setSubBubble((prev) => !prev)} className="bubble-btn rel">
          <div  className="header-text">
                <Tooltip title="Text Type">
                        <TextFieldsOutlined />
                        </Tooltip>
                </div>
            </div>
            {
              subBubble && <div className='bubble-subBubble'>
                  <ul>
                      <li>
                          <Typography onClick={() => setAction(TipTapMenuActions.h1) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} >H1 - Big</Typography>
                      </li>
                      <li>
                          <Typography onClick={() => setAction(TipTapMenuActions.h2) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} >H2 - Medium</Typography>
                      </li>
                      <li>
                          <Typography onClick={() => setAction(TipTapMenuActions.h3) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} >H3 - Small</Typography>
                      </li>
                      <li>
                          <Typography onClick={() => setAction(TipTapMenuActions.h4) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} >H4 - Smallest</Typography>
                      </li>
                      <li>
                          <Typography onClick={() => setAction(TipTapMenuActions.ordered) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} > <FormatListNumbered /> Ordered list</Typography>
                      </li>
                      <li>
                          <Typography onClick={() => setAction(TipTapMenuActions.bullet) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatListBulleted /> Unordered list</Typography>
                      </li>
              </ul>
          </div>
                }
                </>
      
  )
}

export default TextFieldOptions