import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../constants/apis";
import { SpinnerContainer } from "../SpinnerContainer";
import { FormErrorWithTimeout } from "../cards/formError";
import _ from "lodash";
import { AddOutlined } from "@mui/icons-material";

const AddFolder = ({
  root_id,
  parent_id,
  name,
  updateTree,
  close,
  scope = "public",
}: any) => {
  const exiosPrivate = useExiosPrivate();

  const [data, setData] = useState({
    name: "",
    parent_id: "",
    root_id: "",
    scope: "public",
    allow_children: true
  });
  const [loader, toggleLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setData({
      name,
      root_id,
      parent_id,
      scope,
      allow_children: true
    });
  }, [parent_id, name, root_id, scope]);


  const AddFolderModal = async () => {
    if (!data.name || data.name === "") {
      setError("Folder name cannot be empty");
    }

    const result: any = await exiosPrivate.post(APIs.FOLDER_ADD, {
      ...data
    });
    if (result && result.data.status === "Success") {
      updateTree(result.data.data);
      close();
    } else {
      setError("Something went wrong");
    }
  };

  const setName = (e: any) => {
    const newData = { ...data };
    newData.name = e.currentTarget?.value;
    setData(newData);
  };

  const setAllowChildren = (e: any) => {
    const newData = { ...data };
    newData.allow_children = e.target.checked;
    setData(newData);
  };

  return (
    <div>
      {loader ? (
        <SpinnerContainer height="430px" />
      ) : (
          <div>
            <Stack padding="1rem" paddingTop={0}>
              <Grid xs={12} sm={12} md={12} lg={12} mt="1rem">
                <FormControl>
                  <FormLabel required>Name of the folder</FormLabel>
                  <Input onChange={setName} type="text" defaultValue={data.name} value={data.name} />
                </FormControl>
              </Grid>
              {/* <Grid xs={12} sm={12} md={12} lg={12} mt="1rem">
                <FormControl>
                  
                </FormControl>
                <FormControl>
                  <Checkbox
                      defaultChecked
                      size="sm"
                      onChange={setAllowChildren}
                      label={<Typography>Allow others to create sub-folders and entities inside the folder</Typography>}
                    />
                  </FormControl>
              </Grid> */}
              <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={7}
                lg={7}
                >
                  <Button
                    onClick={AddFolderModal}
                    variant="outlined"
                    sx={{width: "max-content", borderColor: "#fd5f13"}}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                >
                  <FormErrorWithTimeout
                      clearError={() => setError("")}
                      text={error}
                  />
                  <Button
                      endDecorator={<AddOutlined/>}
                      onClick={AddFolderModal}
                      size="md"
                      fullWidth
                      variant="solid"
                      color="primary"
                  >
                      Create Folder 
                  </Button>
                </Grid>
            </Grid>
            </Stack>
        </div>
      )}
    </div>
  );
};

export default AddFolder;
