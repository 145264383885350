import { useEffect } from "react";
import FeedSkeleton from "./FeedSkeleton";
import { useState } from "react";
import { Button, Typography } from "@mui/joy";
import FeedOptions from "./FeedOptions";
import _ from "lodash";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";
const FeedItem = ({ entity, data: rawData, user, feedCardData }: any) => {
  const [data, setData] = useState<any>({});
  const [toggleReply, setToggleReply] = useState(false);

  useEffect(() => {
    if (rawData) {
      setData(rawData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  useEffect(() => {
    setData(rawData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFeedRection = (reactData: any) => {
    const newData = _.cloneDeep(data);
    newData.reactions = reactData;
    setData(newData);
  };

  const deleteContent = (id: string) => {
    window.location.reload();
  };

  return (
    <>
      <div className="feed">
        <FeedOptions
          isAdmin={data.creatorId?.id === user._id}
          reactions={data.reactions}
          updateFeedRection={updateFeedRection}
          data={data}
          feedCardData={feedCardData}
          deleteContent={deleteContent}
          showReply={() => setToggleReply(true) }
        />
        <div className="feed-primary">
          {data && (
            <FeedSkeleton
              data={data}
              userId={user._id}
              feedId={data.id}
              reactions={data.reactions}
              feedCardData={feedCardData}
              user={user}
              entity={entity}
              toggleReply={toggleReply}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const DeletePost = ({ title, deleteContent, id, close }: any) => {
  const exiosPrivate = useExiosPrivate();
  const deletePost = async (id: string) => {
    const result = await exiosPrivate.post(APIs.DELETE_CONTENT, {
      currentId: id,
    });
    if (result.data.status === "Success") {
      deleteContent(id);
      close();
    }
  };

  return (
    <div className="p1 pt0">
      <Typography fontWeight={500} mt={0}>
        {title}
      </Typography>
      <div className="mt1">
        <Button onClick={() => deletePost(id)} fullWidth>
          Confirm & Delete
        </Button>
      </div>
    </div>
  );
};

export default FeedItem;
