import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import "./index.scss";
import { ClearOutlined, PhotoOutlined } from "@mui/icons-material";
interface MediaFiles {
  extension?: string;
  name: string;
  url: string;
  type?: string;
}
interface MediaViewerType {
  files: MediaFiles[];
  defaultActiveIndex: number;
  onClose: () => void;
}
const MediaViewer = ({
  files,
  defaultActiveIndex,
  onClose,
}: MediaViewerType) => {
  const [mediaFiles, setMediaFiles] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  useEffect(() => {
    setMediaFiles(files);
  }, [files]);

  useEffect(() => {
    setActiveIndex(defaultActiveIndex);
  }, [defaultActiveIndex]);

  const setFile = (index: number) => {
    setActiveIndex(index);
  };
  const currentFile = mediaFiles[activeIndex];
  if (!currentFile) return <></>;

  let fileicon = <></>;
  switch (currentFile.type) {
    case "image":
      fileicon = <PhotoOutlined />;
      break;
  }
  const viewer = (
    <div className="media-viewer">
      <ClearOutlined
        onClick={() => onClose()}
        sx={{
          position: "absolute",
          color: "white",
          fontSize: "2rem",
          right: "1rem",
          top: "1rem",
          cursor: "pointer",
          background: "#0000005e",
        }}
      />
      <div className="media-viewer-container">
        <div className="media-viewer-sidebar">
          <ul className="media-viewer-sidebar-list">
            {mediaFiles.map((file: MediaFiles, index: number) => (
              <li
                className={index === activeIndex ? `active` : ``}
                onClick={() => setFile(index)}
              >
                <div
                  style={{
                    backgroundImage: file.url ? `url(${file.url})` : "",
                  }}
                ></div>
                <p>{file.name}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="media-viewer-main">
          <div className="media-viewer-main-header">
            <p>
              {fileicon}
              <span>
                {currentFile.name}.{currentFile.extension}
              </span>
            </p>
          </div>
          <div className="media-viewer-main-wrapper">
            <img alt={currentFile.name} src={currentFile.url} />
          </div>
        </div>
      </div>
    </div>
  );
  return createPortal(viewer, document.getElementById("root") as Element);
};
export default MediaViewer;
