import { useState } from "react";
import { ArrowForwardOutlined, DeleteOutline, ReplyOutlined, TrendingFlatOutlined } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/joy";
import { EmojiSelectButton } from "./FeedReaction";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";
import { DeletePost } from "./Feed";
import { AModal } from "../../../../modal/AModal";

const FeedOptions = ({
  feedCardData,
  setFeed,
  data,
  updateFeedRection,
  reactions,
  deleteContent,showReply
}: any) => {
  const exiosPrivate = useExiosPrivate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [reactionInProgress, toggleReactionProgress] = useState(false);
  const [showEmojiContainer, toggleEmojiContainer] = useState(false);

  const defaultEmojis = [
    {
      code: "2705",
      title: "Completed",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2705.png",
    },
    {
      code: "1f44d",
      title: "Like",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44d.png",
    },
    {
      code: "1f197",
      title: "Confirmative",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f197.png",
    },
    {
      code: "2795",
      title: "Agreed",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2795.png",
    },
    {
      code: "1f923",
      title: "Laugh",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f923.png",
    },
  ];

  const setEmoji = async (emoji: any) => {
    const ifReactionExists = reactions.find(
      (reaction: any) => reaction.code === emoji.code
    );
    if (!ifReactionExists) {
      const result = await exiosPrivate.post(APIs.CONTENT_ADD_FEED_REACTION, {
        feedId: data.id,
        reactionId: emoji.code,
      });
      if (result.data.status === "Success") {
        updateFeedRection(result.data.data);
      }
    }
  };

  const selectEmoji = async (emoji: any) => {
    const ifReactionExists = reactions.find(
      (reaction: any) => reaction.code === emoji.unified
    );
    if (!ifReactionExists) {
      try {
        toggleReactionProgress(true);
        setEmoji(emoji);
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <div className="feed-option">
      <div className="feed-option-menu-container">
        {defaultEmojis.map((emojiO: any) => (
          <div onClick={() => setEmoji(emojiO)} className="emoji-btn">
            <Tooltip title={emojiO.title}>
              <img src={emojiO.src} alt={emojiO.title} />
            </Tooltip>
          </div>
        ))}

        <div className="emoji-btn feed-emoji-picker">
          <EmojiSelectButton
            title="Add Reaction"
            showEmojiContainer={showEmojiContainer}
            toggleEmojiContainer={toggleEmojiContainer}
            selectEmoji={selectEmoji}
          />
        </div>
      </div>
      <div className="feed-option-menu-container">
        {/* <div className="emoji-btn">
              <Tooltip title="Reply">
                <span>
                  Reply
                </span>
              </Tooltip>
        </div> */}
            {/* <div className="emoji-btn reply-emoji-btn">
              <Tooltip title="Add Reply">
                <span>
                  <NorthEastRounded />
                </span>
              </Tooltip>
            </div>
            <div className="emoji-btn reply-emoji-btn">
              <Tooltip title="Add Reply">
                <span>
                  <CallOutlined />
                </span>
              </Tooltip>
            </div> */}
        <div
          onClick={() => setDeleteModal(true)}
          className="emoji-btn reply-emoji-btn"
        >
          <Tooltip title="Delete this message">
            <span>
              <DeleteOutline />
            </span>
          </Tooltip>
        </div>
      </div>
      {
        feedCardData.allow_comments && <div className="feed-option-menu-container">
        <div
          onClick={showReply}
          className="emoji-btn reply-emoji-btn"
        >
          <Tooltip title="Add a Reply">
            <Typography className="flex-row gHalf" fontSize="0.9rem" sx={{ padding: "0 1rem", display: "flex" }}>Reply <ReplyOutlined/> </Typography>
          </Tooltip>
        </div>
      </div>
      }
      
      {deleteModal && (
        <AModal title="Delete" onClose={() => setDeleteModal(false)}>
          <DeletePost
            title="Are you sure you want to delete this post?"
            deleteContent={deleteContent}
            id={data.id}
            close={() => setDeleteModal(false)}
          />
        </AModal>
      )}
      {/* <StarBorderOutlined /> */}
    </div>
  );
};

export default FeedOptions;
