import {
  Delete,
  DeleteOutline,
  PeopleAlt,
  Search,
  SearchOutlined,
  SettingsRounded,
} from "@mui/icons-material";
import { Input, Tooltip } from "@mui/joy";
import { useState } from "react";
import { AModal } from "../../../components/modal/AModal";
import ConfigureSpace from "./ConfigureSpace";

const SpaceFooter = ({ addMembers }: any) => {
  const [showSearch, toggleSearch] = useState(false);

  return (
    <div className="space-footer">
    <div
        className="flex-row g1 rel"
        style={{
          justifyContent: "space-between",
          padding: "0.5rem",
        }}
      >
        <span
          className="flex-row rel"
          style={{
            gap: "1rem",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <span className="flex-row">
            <Tooltip title="Workspace Members">
              <PeopleAlt
                onClick={addMembers}
                sx={{
                  cursor: "pointer",
                  fontSize: "1.3rem",
                  color: "#a1a1a1",
                }}
              />
            </Tooltip>
          </span>

          {/* <span className="flex-row">
            <Tooltip title="Workspace Settings">
              <SettingsRounded
                onClick={() => toggleSettings(true)}
                sx={{
                  cursor: "pointer",
                  fontSize: "1.2rem",
                  color: "#a1a1a1",
                }}
              />
            </Tooltip>
          </span> */}
        </span>
        {showSearch && (
          <div className="search-container">
            <Input
              endDecorator={
                <SearchOutlined sx={{ fontSize: "1.2rem", color: "#ccc" }} />
              }
              sx={{ fontSize: "0.9rem" }}
              placeholder="Search..."
              fullWidth
            />
          </div>
        )}
        
      </div></div>
  );
};

export default SpaceFooter;
