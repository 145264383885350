import React from "react";

import {
  Code,
  FiberManualRecord,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalicOutlined,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatUnderlined,
  Highlight,
  HorizontalRule,
  StrikethroughSOutlined,
} from "@mui/icons-material";
import { TipTapMenuActions } from "./TiptapWrapper";

export const BubbleComponentContent = ({ actionMenuClick }: any) => {
  return (
    <div className="tippy-bubble">
      <div className="basic-text-formatting">
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.bold)}
        >
          <FormatBold />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.italic)}
        >
          <FormatItalicOutlined />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.underline)}
        >
          <FormatUnderlined />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.strike)}
        >
          <StrikethroughSOutlined />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.code)}
        >
          <Code />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.h1)}
        >
          <strong>H1</strong>
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.h2)}
        >
          <strong>H2</strong>
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.h3)}
        >
          <strong>H3</strong>
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.h4)}
        >
          <strong>H4</strong>
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.block)}
        >
          <FormatQuote />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.highlight)}
        >
          <Highlight />
        </div>
      </div>

      <div className="basic-text-formatting">
        <div className="divider"></div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.bullet)}
        >
          <FormatListBulleted />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.ordered)}
        >
          <FormatListNumbered />
        </div>
        <div className="divider" />

        <div className="divider"></div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.color_red)}
        >
          <FiberManualRecord className="text-color-doer red" />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.color_green)}
        >
          <FiberManualRecord className="text-color-doer green" />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.color_blue)}
        >
          <FiberManualRecord className="text-color-doer blue" />
        </div>
        <div className="divider"></div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.textLeft)}
        >
          <FormatAlignLeft />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.textCenter)}
        >
          <FormatAlignCenter />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.textRight)}
        >
          <FormatAlignRight />
        </div>

        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.hRule)}
        >
          <HorizontalRule />
          <HorizontalRule sx={{ marginLeft: "-7px" }} />
          <HorizontalRule sx={{ marginLeft: "-7px" }} />
          <HorizontalRule sx={{ marginLeft: "-7px" }} />
          <HorizontalRule sx={{ marginLeft: "-7px" }} />
          <HorizontalRule sx={{ marginLeft: "-7px" }} />
        </div>
      </div>
    </div>
  );
};
