import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Radio,
  Typography,
} from "@mui/joy";
import React, { useState, useEffect } from "react";
import "./index.scss";
import { AModal } from "../modal/AModal";
import EmojiPicker from "emoji-picker-react";
import { useExiosPrivateMulti } from "../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../constants/apis";
import { SingleFileUploader } from "../SingleFileUploader";
import { textIconCreator } from "../../utilities/sharedUtilties";

function IconSelector({
  icon,
  setIcon,
  noImg,
  noEmoji,
  name,
  from = "A",
  children
}: any) {
  const [category, setCategory] = useState(noImg ? "emoji" : "img");
  const [showIconSelector, toggleIconSelector] = useState(false);

  const exiosPrivateMulti = useExiosPrivateMulti();
  const [imageLoad, toggleImageLoad] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    setImageUrl(icon);
  }, [icon]);

  const handleImageUpload = async (file: any) => {
    try {
      toggleImageLoad(true);
      const formData = new FormData();
      formData.append("file", file);
      const result = await exiosPrivateMulti.post(
        APIs.ORGANIZATION_LOGO_CREATE,
        formData
      );
      if (result.data.status === "success") {
        setImageUrl(result.data.imageUrl);
        setIcon(result.data.imageUrl);
      }
      toggleImageLoad(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  const processIcon = async (iconName: any) => {
    toggleIconSelector(false);
    setImageUrl(iconName.imageUrl);
    setIcon(iconName.imageUrl);
  };

  const selectImage = () => {
    toggleIconSelector(false);
  };

  return (
    <>
      <FormControl sx={{ width: "100%" }}>
        <FormLabel>Set Icon</FormLabel>
        <div className="icon-input noSelect">
          {
            from === "none" && !imageUrl ? <span className="icon-name"></span> : <span className="icon-name">
              {imageUrl ? (
                <img style={{ borderRadius: "4px" }} src={imageUrl} alt="icon" />
              ) : (
                textIconCreator(name ? name[0] : from, "l")
              )}
            </span>
          }
          <span>
            {imageUrl && (
              <span
                onClick={() => {
                  setImageUrl("");
                  setIcon("");
                }}
                className="icon-action noSelect"
              >
                Remove&nbsp;&nbsp;&nbsp;
              </span>
            )}
            <span
              onClick={() => toggleIconSelector((prev) => !prev)}
              className="icon-action noSelect"
            >
              {imageUrl ? "Change" : "Select"}
            </span>
          </span>
        </div>
      </FormControl>
      {showIconSelector && (
        <AModal
          size="sm"
          onClose={() => toggleIconSelector(false)}
          title="Icon Selector"
        >
          <Grid p={2}>
            <Typography fontWeight={600} fontSize={17}>
              Select Icon Type
            </Typography>
            <Box mb={3} gap={5} display="flex" mt={1}>
              {!noImg && (
                <Radio
                  checked={category === "img"}
                  onChange={() => setCategory("img")}
                  value="a"
                  name="radio-buttons"
                  label="Image Logo"
                />
              )}
              {!noEmoji && (
                <Radio
                  checked={category === "emoji"}
                  onChange={() => setCategory("emoji")}
                  value="b"
                  name="radio-buttons"
                  label="Emoji"
                />
              )}
            </Box>
            <Grid>
              {category === "emoji" && (
                <EmojiPicker
                  width="100%"
                  onEmojiClick={(name) => processIcon(name)}
                />
              )}
              {category === "img" && (
                <div>
                  <div className="dp-container">
                    <div className="dp">
                      <div
                        style={{ margin: "1rem auto", width: "200px" }}
                        className={`${imageLoad ? `loading` : ``} ${imageUrl === "" && `showDefault defaultOrgLogo`
                          } `}
                      >
                        {imageLoad && (
                          <CircularProgress
                            sx={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                            }}
                            variant="soft"
                          />
                        )}
                        {imageUrl && <img src={imageUrl} alt="profile dp" />}
                      </div>
                    </div>

                    <FormControl
                      sx={{
                        width: 330,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <SingleFileUploader
                        setImage={handleImageUpload}
                        name="dp"
                        maxSize={200000}
                        minSize={10}
                        types={["jpeg", "png", "jpg", "svg"]}
                      >
                        <Typography
                          mt={1}
                          textAlign="center"
                          variant="outlined"
                        >
                          Upload Logo
                        </Typography>
                      </SingleFileUploader>
                      <Typography
                        fontSize={12}
                        mt={1}
                        textAlign="center"
                        variant="plain"
                      >
                        Upload ".jpg, .jpeg, .png,", Max Size: 200KB, MinSize:
                        3KB Image dimension prefered will be 100 X 100
                      </Typography>
                    </FormControl>
                    <div
                      style={{
                        paddingBottom: 0,
                        marginTop: "2rem",
                        paddingTop: "2rem",
                      }}
                    >
                      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                        <Grid
                          display="flex"
                          justifyContent="flex-end"
                          flexDirection="column"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          {/* <FormErrorWithTimeout text={error} /> */}
                          <Button
                            disabled={imageLoad}
                            onClick={selectImage}
                            size="md"
                            fullWidth
                            variant="solid"
                            color="primary"
                          >
                            Select
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </AModal>
      )}
    </>
  );
}

export default IconSelector;
