import { Card, CardContent, Divider, Typography } from "@mui/joy";
import React from "react";
import { FormError } from "./formError";

export const FormCard = ({
  error,
  children,
  title,
  image,
  styles,
  width = {},
  noHeader = false,
  noPadding,
}: any) => {
  return (
    <Card
      sx={{ width: `${width}%`, ...styles }}
      className={`min-card no-gap ${noPadding && "no-padding"}`}
      variant="outlined"
    >
      {!noHeader && (
        <>
          <Typography className="min-card-title" fontWeight="bold">
            {image && (
              <span>
                <img alt="Smiling man" src={image} />
              </span>
            )}{" "}
            {title}
          </Typography>
          <Divider inset="none" />
        </>
      )}

      <CardContent
        sx={{
          display: "grid",
          gap: 1.5,
        }}
      >
        {children}
      </CardContent>
      {error && <FormError clearError={() => console.log("")} text={error} />}
    </Card>
  );
};
