import { Button, FormControl, FormLabel, Grid, Input, Stack } from "@mui/joy";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import useExiosPrivate from "../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../constants/apis";
import IconSelector from "../../../IconSelector";
import { FormErrorWithTimeout } from "../../../cards/formError";
import { SectionTypeNames } from "../../../Sections/Renderer";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateUserWithNewFolderList } from "../../../../redux/reducers/User";

const TodoCreatorModal = ({updateTree,parentId, close}:any) => {
  const { id: root_id } = useParams();
  const exiosPrivate = useExiosPrivate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({
    name: "",
    icon: "",
    parent_id: parentId,
    root_id: root_id,
    type: SectionTypeNames.TASKER,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    setDataHandler(parentId, "parent_id");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const setDataHandler = (value: string, type: string) => {
    const _data: any = _.cloneDeep(data);
    _data[type] = value;
    setData(_data);
  };

  useEffect(() => {
    return () => {
      setData({});
      setError("");
    };
  }, []);

  const createTodo = async () => {
    if (!data.name) {
      setError("Name cannot be empty");
    }
    dispatch(updateUserWithNewFolderList(parentId));
    const response = await exiosPrivate.post(APIs.CREATE_TASKBOARD, data);
    if (response.data.status === "Success") {
      updateTree(response.data.data);
      navigate(`../space/${root_id}/${response.data.params.type}/${response.data.params.id}`)
      close();
    }
  };

  return (
    <div>
      <Stack padding="1rem" paddingTop={0}>
        <Grid mt="1rem" xs={12} sm={12} md={7} lg={12}>
            <FormControl>
              <FormLabel required>Title of Task List</FormLabel>
              <Input
                onChange={(e) => setDataHandler(e.currentTarget.value, "name")}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid mt="1rem" className="inline-icon" xs={12} sm={12} md={5} lg={5}>
            <IconSelector
              from="T"
              icon={data.icon}
              setIcon={(icon: string) => setDataHandler(icon, "icon")}
              noImg={true}
              name={data.name}
            />
          </Grid>
        

        <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid
              display="flex"
              justifyContent="flex-end"
              flexDirection="column"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FormErrorWithTimeout
                clearError={() => setError("")}
                text={error}
              />
              <Button
                onClick={createTodo}
                size="md"
                fullWidth
                variant="solid"
                color="primary"
              >
                Create Tasker
              </Button>
            </Grid>
          </Grid>
      </Stack>
    </div>
  );
}

export default TodoCreatorModal