import { AddOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/joy'
import React, { useState } from 'react'
import { AModal } from '../../../../components/modal/AModal';
import CreateSpaceList from './CreateSpaceList';
import { UserType } from '../../../../redux/reducers/User.types';
import SpaceLister from './SpaceTree';

interface SpaceListType {
  user: UserType
}

const SpaceList = ({ user }: SpaceListType) => {
  return (
    <>
    <div className='space-container'>
      <SpaceLister
          user={user}
      />
      </div>
    </>
  )
}

export default SpaceList