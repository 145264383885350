import { useState, useEffect } from "react";
import { Divider, Grid, Stack, Tooltip, Typography } from "@mui/joy";
import {
  ChromeReaderModeOutlined,
  FolderOutlined,
  FolderSharedOutlined,
} from "@mui/icons-material";
import { AModal } from "../../components/modal/AModal";
import AddFolder from "../../components/Folder/AddFolder";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import SectionsSubMenu from "../../components/ContextMenu/SectionsSubMenu";
import FolderMenu from "../../components/ContextMenu/FolderMenu";
import SpaceTree, { ContextMenuTitle } from "../../components/ContextMenu/SpaceTree";
import SectionCreatorModal from "./SectionCreatorModal";
import SectionRenderer from "../Sections/Renderer";
import { textIconCreator } from "../../utilities/sharedUtilties";
import AddPrivateFolder from "../Folder/AddPrivateFolder";

const SidebarSection = ({ entity, type, openFile, data, updateTree, loader, navigateToMain, user,refreshSpace }: any) => {
  const [addFolderModal, setAddFolderModal] = useState(false);
  const [addPrivateSpaceModal, setAddPrivateSpaceModal] = useState(false);

  const [sectionCreatorModal, setSectionCreatorModal] = useState(false);
  const [sectionCreatorType, setSectionCreatorType] = useState("");

  if (loader) {
    return <SidebarLoading />;
  }

  const openCreator = (type: string) => {
    setSectionCreatorModal(true);
    setSectionCreatorType(type);
  };

  const closeCreatorModal = () => {
    setSectionCreatorModal(false);
    setSectionCreatorType("");
  };

  if (!data) return <SidebarLoading />;


  /* Code to render empty sidebars */
  if (
    data &&
    data.sectionTree?.length === 0 &&
    data.spaceTree?.length === 0 &&
    !loader
  ) {
    return (
      <div className="section-sidebar">
        <ContextMenuContainer
          entity={entity}
          setAddFolderModal={() => setAddFolderModal(true)}
          openCreator={openCreator}
          updateTree={updateTree}
          setAddPrivateSpaceModal={() => setAddPrivateSpaceModal(true)}
        >
          <EmptySidebar
            openCreator={openCreator}
            addFolder={() => setAddFolderModal(true)}
            type={type}
          />
        </ContextMenuContainer>
        {addFolderModal && (
          <AModal size="sm" onClose={() => setAddFolderModal(false)} title="Add Folder">
            <AddFolder
              updateTree={updateTree}
              parent_id={entity.id}
              root_id={entity.id}
              close={() => setAddFolderModal(false)}
              members={entity.members}
            />
          </AModal>
        )}
        {
        addPrivateSpaceModal && (
        <AModal size="sm" onClose={() => setAddPrivateSpaceModal(false)} title="Add Private Space">
          <AddPrivateFolder
            updateTree={updateTree}
            parent_id={entity.id}
            root_id={entity.id}
            close={() => setAddPrivateSpaceModal(false)}
            members={entity.members}
          />
        </AModal>
      )
      }
        {sectionCreatorModal && (
          <SectionCreatorModal
            onClose={closeCreatorModal}
            type={sectionCreatorType}
            parentId={entity.id}
            updateTree={updateTree}
          />
        )}
      </div>
    );
  }

  return (
    <div className="section-sidebar">
      
      <ContextMenuContainer
        entity={entity}
        setAddFolderModal={setAddFolderModal}
        openCreator={openCreator}
        updateTree={updateTree}
        setAddPrivateSpaceModal={() => setAddPrivateSpaceModal(true)}
        user={user}
      >
        <ul className="workspace-entities">
          <SectionFolder
            title="All"
            addFolder={() => setAddFolderModal(true)}
            id="root_all"
          >
            {entity.id && (
              <>
                <SpaceTree
                  updateTree={updateTree}
                  spaceTree={data.spaceTree}
                  sectionTree={data.sectionTree}
                  entity={entity}
                  openFile={openFile}
                  navigateToMain={navigateToMain}
                  refreshSpace={refreshSpace}
                />
              </>
            )}
          </SectionFolder>
        </ul>
      </ContextMenuContainer>
      {addFolderModal && (
        <AModal size="sm" onClose={() => setAddFolderModal(false)} title="Add Folder">
          <AddFolder
            updateTree={updateTree}
            parent_id={entity.id}
            root_id={entity.id}
            close={() => setAddFolderModal(false)}
            members={entity.members}
          />
        </AModal>
      )}

      {
        addPrivateSpaceModal && (
        <AModal size="sm" onClose={() => setAddPrivateSpaceModal(false)} title="Add Private Space">
          <AddPrivateFolder
            updateTree={updateTree}
            parent_id={entity.id}
            root_id={entity.id}
            close={() => setAddPrivateSpaceModal(false)}
            members={entity.members}
          />
        </AModal>
      )
      }
      
      {sectionCreatorModal && (
        <SectionCreatorModal
          onClose={closeCreatorModal}
          type={sectionCreatorType}
          parentId={entity.id}
          updateTree={updateTree}
        />
      )}
    </div>
  );
};

const EmptySidebar = ({ addFolder, type, openCreator }: any) => {
  return (
    <div className="empty-sidebar">
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography textColor="#505050" fontWeight={500} mt={4}>
          Structure your {type}
        </Typography>
        <ChromeReaderModeOutlined
          sx={{ fontSize: "50px", color: "#cdcdcd", margin: "1rem 0" }}
        />
        <Typography
          display="flex"
          alignItems="center"
          textColor="rgba(55, 53, 47, 0.5)"
          fontWeight={600}
          border="2px solid"
          borderRadius="4px"
          padding="0 10px"
          fontSize="0.9rem"
          onClick={() => addFolder()}
          sx={{ cursor: "pointer" }}
        >
          <Tooltip title="Add Folders to structure your Workspace">
            <Typography>Add Folder</Typography>
          </Tooltip>
          <FolderOutlined
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              marginLeft: "5px",
              height: "24px",
            }}
          />
        </Typography>
      </Grid>
    </div>
  );
};

export const SectionFolder = ({ children, title, addFolder, root_id }: any) => {
  const [allOpen, setAllOpen] = useState(true);
  const toggleAll = () => {
    setAllOpen((prev) => !prev);
  };
  return (
    <ul className={` all-folder ${allOpen ? "open" : "close"}`}>{children}</ul>
  );
};

const SidebarLoading = () => {
  return <div className="sidebar-loading"></div>;
};

const ContextMenuContainer = ({
  children,
  entity,
  setAddFolderModal,
  setAddPrivateSpaceModal,
  openCreator,
  updateTree,
  user
}: any) => {
  return (
    <ContextMenuTrigger id="sidebar-section">
      {
        entity.creator !== user?._id && !entity.allow_children ? null : 
          <ContextMenu className="section-tree-contextMenu " id="sidebar-section">
        <SectionsSubMenu
          updateTree={updateTree}
          parentId={entity.id}
          openCreator={openCreator}
        />
        <FolderMenu
          addFolder={() => setAddFolderModal(true)}
        />
        {/* <Divider />
        <ContextMenuItem onClick={() => setAddPrivateSpaceModal(true)} >
          <ContextMenuTitle>
            <FolderSharedOutlined sx={{ fontSize: 17 }} />
            New Private Space
          </ContextMenuTitle>
        </ContextMenuItem> */}
      </ContextMenu>
      }
      
      {children}
    </ContextMenuTrigger>
  );
};

export const SectionBoard = ({ entity, subId, subType, entityText }: any) => {
  
  const [renderId, setRenderId] = useState(subId);
  const [renderType, setRenderType] = useState(subType);
  const [renderEntity, setEntity] = useState(entity);
  const [renderEntityText, setRenderEntityText] = useState(entityText);

  let renderedSection = <>Something is wrong</>;

  useEffect(() => {
    setRenderId(subId);
    setRenderType(subType);
    setEntity(entity);
    setRenderEntityText(entityText);
  }, [subId, subType,entity, entityText]);

  if (renderType === "home" && renderId === "main") {
    renderedSection = <HelloPage entityText={renderEntityText} entity={renderEntity} />;
  } else {
    renderedSection = (
      <SectionRenderer
        entityText={renderEntityText}
        entity={renderEntity}
        type={renderType}
        id={renderId}
      />
    );
  }
  return (
    <>
      <div className="cube">{renderedSection}</div>
    </>
  );
};

export const HelloPage = ({ entity, entityText }: any) => {
  const [page, setPage] = useState<any>({});

  useEffect(() => {
    setPage(entity.data);
  }, [entity]);

  return (
    <div style={{ paddingLeft: 0 }} className="cube flex-center-full">
      <div>
        <Stack display="flex" alignItems="center">
          {page.icon !== "" ? (
            <img src={page.icon} style={{ width: "60px" }} alt={page.name} />
          ) : (
            textIconCreator(page.name, "l")
          )}
          <Typography fontSize={20} fontWeight={400} mt={1}>
            Welcome to <Typography fontWeight={600}> {page.name}</Typography>{" "}
            {entityText}
          </Typography>
          <Typography fontSize={18} fontWeight={500} mb={5} mt={1}>
            Use this {entityText} to accomplish your work
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const PrivateSection = ({ entity, entityText }: any) => {
  return (
    <>
      <div style={{ paddingLeft: 0 }} className="cube flex-center-full">
        <div>
          <Stack display="flex" alignItems="center">
            <img style={{ width: "60px" }} src={entity.icon} alt="team-icon" />
            <Typography fontSize={20} fontWeight={600} mt={1}>
              {entity.name}
            </Typography>
            <Typography fontSize={20}>is a Private {entityText}</Typography>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default SidebarSection;
