import {
  sidebarList,
  sidebarListType,
} from "../pages/Home/Sidebar/sidebar.data";

export interface UserSignupProps {
  name: string;
  email: string;
  password: string;
}

export interface organizationTypeProps {
  name: string;
  id: string;
  icon: string;
}

export interface UserSessionData {
  name: string;
  email: string;
  _id: string;
  drawerData: sidebarListType;
  dp: string;
  organization: organizationTypeProps;
  verified: boolean;
}
export const defaultUserSessionData = {
  name: "",
  email: "",
  _id: "",
  drawerData: sidebarList,
  dp: "",
  organization: {
    name: "Adaily",
    icon: "",
    id: "",
  },
  verified: false,
};

export interface OnboardingData {
  organization: string;
  // areas: [] | [string];
  strength: string;
}

export interface UserProp {
  user: UserSessionData;
}
