import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CardActions,
  FormControl,
  FormLabel,
  Grid,
  Input,
} from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";

export const ResetNewPassword = ({ data }: any) => {
  //const { refreshUser } = useContext(PrimaryAppDataContext) as any;
  const exiosPrivate = useExiosPrivate();
  const [passwords, setPasswords] = useState<any>({
    old: { visible: false, value: "" },
    new: { visible: false, value: "" },
    confirm: { visible: false, value: "" },
  });

  const setData = (name: string, data: any) => {
    const updatedPasswords = { ...passwords };
    updatedPasswords[name] = data;
    setPasswords(updatedPasswords);
  };

  const saveData = async () => {
    if (passwords.new.value !== passwords.confirm.value) {
      return;
    }
    if (!passwords.old.value) {
      alert("Old password cannot be empty");
      return;
    }
    try {
      await exiosPrivate.post(APIs.USER_UPDATE_PASSWORD, {
        oldPassword: passwords.old.value,
        newPassword: passwords.new.value,
      });
      //refreshUser();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Grid sm={12} md={12} lg={12}>
      <div className="full">
        {/* {["old", "new", "confirm"].map((pass) => (
          <div key={getUniqueKey("pass")}>
            <InputPasswordComponent
              name={pass}
              setData={(data: any) => setData(pass, data)}
              data={passwords[pass]}
            />
          </div>
        ))} */}
        <InputPasswordComponent
          name={"old"}
          setData={(data: any) => setData("old", data)}
          data={passwords["old"]}
        />
        <InputPasswordComponent
          name={"new"}
          setData={(data: any) => setData("new", data)}
          data={passwords["new"]}
        />
        <InputPasswordComponent
          name={"confirm"}
          setData={(data: any) => setData("confirm", data)}
          data={passwords["confirm"]}
        />
        <CardActions>
          <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={1}>
            <Grid xs={12} sm={8} md={8} lg={6}></Grid>
            <Grid
              display="flex"
              justifyContent="flex-end"
              xs={12}
              sm={4}
              md={4}
              lg={6}
            >
              <Button
                onClick={saveData}
                fullWidth
                variant="solid"
                color="primary"
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </div>
    </Grid>
  );
};
const InputPasswordComponent = ({
  name,
  data: rawData,
  setData: setRawData,
}: any) => {
  const [data, setData] = useState(rawData);

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  const changeHandlers = (type: string, value: string | boolean) => {
    const updateData: any = { ...data };
    updateData[type] = value;
    setRawData(updateData);
  };
  return (
    <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
      <FormLabel sx={{ textTransform: "capitalize" }}>
        {name} Password
      </FormLabel>
      <Input
        value={data.value}
        onChange={(e) => changeHandlers("value", e.target.value)}
        autoComplete="new-password"
        endDecorator={
          data.visible ? (
            <Visibility
              onClick={() => changeHandlers("visible", !data.visible)}
              sx={{ color: "#a3a3a3" }}
            />
          ) : (
            <VisibilityOff
              onClick={() => changeHandlers("visible", !data.visible)}
              sx={{ color: "#a3a3a3" }}
            />
          )
        }
        type={data.visible ? "text" : "password"}
      />
    </FormControl>
  );
};
