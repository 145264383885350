import React from "react";

import {
  Code,
  DeleteOutline,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatItalicOutlined,
  FormatQuoteOutlined,
  FormatUnderlined,
  Highlight,
  LinkOutlined,
  StrikethroughSOutlined,
} from "@mui/icons-material";
import { TipTapMenuActions } from "../TiptapTypes";
import { Tooltip } from "@mui/joy";

export const BubbleMenuDocumentWrapper = ({ actionMenuClick, selectionType }: any) => {
  return (
    <div className="tippy-bubble">
      {
        selectionType === "text" && <div className="basic-text-formatting">
        <div
          title="Bold Text"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.bold)}
        >
            <FormatBold />
        </div>
        <div
          title="Italic Text"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.italic)}
        >
            <FormatItalicOutlined />
        </div>
        <div
          title="Underlined Text"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.underline)}
        >
            <FormatUnderlined />
        </div>
        <div
          title="Strike Text"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.strike)}
        >
            <StrikethroughSOutlined />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.textLeft)}
        >
          <FormatAlignLeft />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.textCenter)}
        >
          <FormatAlignCenter />
        </div>
        <div
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.textRight)}
        >
          <FormatAlignRight />
        </div>
        <div
          title="Link Text"
          className=" bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.addLink)}
        >
            <LinkOutlined />
        </div>
        
        <div
          title="Code Text"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.code)}
        >
            <Code />
        </div>
        <div
          title="Clear Text Format"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.formatClear)}
        >
            <FormatClear />
        </div>
        <div
          title="Text Highlighter"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.highlight)}
        >
            <Highlight />
        </div>
        <div
          title="Text Block"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.block)}
        >
            <FormatQuoteOutlined />
        </div>
        </div>
      }

      {
        selectionType === "node" && <div className="basic-text-formatting">
            <div
          title="Text Block"
          className="bubble-btn"
          onClick={() => actionMenuClick(TipTapMenuActions.deleteNode)}
        >
            <DeleteOutline/>
        </div>
        </div>
      }

    </div>
  );
};
