import { Grid, Link } from "@mui/joy";
import React from "react";
import { localStorageGetSet } from "../../../utilities/sharedUtilties";
import Names from "../../../constants/names";
import "./index.scss";

function SkipSetOrganization({ onClose }: any) {
  const skipLink = () => {
    localStorageGetSet(Names.persistSkipOrg, "true");
    onClose();
  };
  return (
    <div className="action-bottom">
      <Grid mt={2} justifyContent="center" display="flex">
        <Link
          onClick={() => skipLink()}
          sx={{ color: "orangered !important;", textDecoration: "underline" }}
          href="#"
        >
          Skip it for now
        </Link>
      </Grid>
      <p className="text-center mt0">
        You can always set Organization by <br />
        Clicking top left button where current org set to be Adaily.
      </p>
    </div>
  );
}

export default SkipSetOrganization;
