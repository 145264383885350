import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { exiosPrivate } from "../../api/axios";

import { Header } from "../../components/header";
import {
  Button,
  CardActions,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
} from "@mui/joy";
import { FormCard } from "../../components/cards/formCard";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Names from "../../constants/names";
import { APIs } from "../../constants/apis";
import { SpinnerContainer } from "../../components/SpinnerContainer";
import SomethingWrong from "../../components/SomethingWrong/SomethingWrong";
import { SetObjectWithKey } from "../../utilities/sharedUtilties";
import { useAuth } from "../../utilities/hooks/useAuth";
import { AuthContextProp } from "../../context/AuthProvider";
import PATHS from "../../constants/paths";
import { LOGS } from "../../constants/Logs";
import { FormErrorWithTimeout } from "../../components/cards/formError";

export const InvitationReceiver = () => {
  const [passwordVisibility, togglePasswordVisibility] = useState(false);
  const { org, id, code } = useParams();
  const { setPersist, setAuth } = useAuth() as AuthContextProp;
  const navigate = useNavigate();
  const [loadError, setLoadError] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const togglePersist = () => {
    setPersist((prev: any) => !prev);
  };

  const [invitee, setInvitee] = useState({
    email: "",
    organizationName: "",
    invitationId: "",
  });
  const [loader, toggleLoader] = useState(false);

  const tagStyler = {
    marginLeft: "5px",
    background: "#fd5f13",
    padding: "3px",
    color: "white",
    borderRadius: "4px",
  };

  const getInvitee = async () => {
    const data = { id, code, org };
    try {
      toggleLoader(true);
      const { data: inviteeData }: any = await exiosPrivate.post(
        APIs.USER_INVITATION_JOINER,
        data
      );
      setInvitee(inviteeData);
      setData((prev) => ({ ...prev, email: invitee.email }));
    } catch (error) {
      setLoadError(true);
      console.log(error);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    if (id && code && org) {
      getInvitee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveData = async () => {
    const { password, name } = data;
    if (password) {
      if (name) {
        if (invitee.email) {
          try {
            const response = await exiosPrivate.post(APIs.USER, {
              name,
              email: invitee.email,
              password,
              username: invitee.email,
            });
            const accessToken = response?.data?.token;
            setAuth({ email: invitee.email, accessToken, name });
            navigate(PATHS.home);
          } catch (error) {
            setError("Something went wrong");
            console.log(LOGS.SINGUP_ERROR, error);
          }
        }
      }
    }
  };

  return (
    <div>
      <Header />
      <div>
        {loadError ? (
          <SomethingWrong />
        ) : (
          <>
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid xs></Grid>
              <Grid xs={10} sm={6} md={4} lg={6}>
                <h1 className="text-center">
                  Join&nbsp;
                  <span className="ad-color">
                    <span style={tagStyler}>{invitee.organizationName}</span>
                  </span>
                  &nbsp; on Adaily
                </h1>
              </Grid>
              <Grid xs></Grid>
            </Grid>
            {!loader ? (
              <div>
                <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={2}>
                  <Grid xs></Grid>
                  <Grid xs={10} sm={8} md={6} lg={3}>
                    <FormCard title="Create an account and join">
                      <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                          value={data.name}
                          onChange={(event) =>
                            setData(
                              SetObjectWithKey(data, "name", event.target.value)
                            )
                          }
                          type="text"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input value={invitee.email} disabled type="email" />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input
                          endDecorator={
                            passwordVisibility ? (
                              <Visibility
                                onClick={() =>
                                  togglePasswordVisibility((prev) => !prev)
                                }
                                sx={{ color: "#a3a3a3" }}
                              />
                            ) : (
                              <VisibilityOff
                                onClick={() =>
                                  togglePasswordVisibility((prev) => !prev)
                                }
                                sx={{ color: "#a3a3a3" }}
                              />
                            )
                          }
                          type={passwordVisibility ? "text" : "password"}
                          value={data.password}
                          onChange={(event) =>
                            setData(
                              SetObjectWithKey(
                                data,
                                "password",
                                event.target.value
                              )
                            )
                          }
                        />
                      </FormControl>
                      <Grid mt={1} container spacing={2}>
                        <Grid xs={6}>
                          <Checkbox
                            defaultChecked
                            onChange={togglePersist}
                            label={Names.persistText}
                          />
                        </Grid>
                      </Grid>
                      <FormErrorWithTimeout
                        clearError={() => setError("")}
                        text={error}
                      />
                      <CardActions>
                        <Button
                          onClick={saveData}
                          variant="solid"
                          color="primary"
                        >
                          Sign up & Join
                        </Button>
                      </CardActions>
                    </FormCard>
                  </Grid>
                  <Grid xs></Grid>
                </Grid>
              </div>
            ) : (
              <SpinnerContainer height="400px" />
            )}
          </>
        )}
      </div>
    </div>
  );
};
