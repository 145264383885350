import React, { useState } from "react";

import {
  SetObjectWithKey,
  validateEmail,
} from "../../utilities/sharedUtilties";
import { useNavigate } from "react-router-dom";
import PATHS from "../../constants/paths";

import {
  Button,
  Input,
  Grid,
  FormControl,
  CardActions,
  Stack,
  Link,
  FormLabel,
  Typography,
} from "@mui/joy";
import { Header } from "../../components/header";
import { APIs } from "../../constants/apis";
import { FormCard } from "../../components/cards/formCard";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { FormErrorWithTimeout } from "../../components/cards/formError";

export const ForgotPassword = () => {
  const [data, setData] = useState({
    email: "",
  });
  const { email } = data;

  const [error, setError] = useState("");
  const [successScreen, toggleSuccessScreen] = useState(false);

  const navigate = useNavigate();
  const exiosPrivate = useExiosPrivate();

  const sendEmail = async () => {
    if (email && validateEmail(email)) {
      try {
        const response = await exiosPrivate.post(APIs.RESET_PASSWORD, {
          email,
        });
        if (response.data.status === "fail") {
          setData((prev) => ({ ...prev, email: "" }));
          setError("User not found with this email");
        } else {
          setData((prev) => ({ ...prev, email: "" }));
          toggleSuccessScreen(true);
        }
      } catch (err) {
        setError("Something went wrong, try aagain");
        console.log("err", err);
      }
    } else {
      setError("Invalid email");
    }
  };

  return (
    <div>
      <Header>
        <Button
          className="header-button shadow carrot"
          color="primary"
          onClick={() => navigate(PATHS.signup)}
        >
          Create a new account
        </Button>
      </Header>
      <div className="space-readable-1">
        {successScreen ? (
          <Grid container justifyContent="center" mt={10}>
            <Grid
              justifyContent="center"
              gap={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              xs={6}
            >
              <Typography fontSize={33}>✅</Typography>
              <Typography fontSize={20}>
                Reset password link sent to{" "}
                <strong>mukur.puri@gmail.com</strong>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={10}>
            <Grid xs></Grid>
            <Grid xs={3.5}>
              <FormCard title="Reset Password">
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    placeholder="Enter your email you use at adaily"
                    onChange={(event) =>
                      setData(
                        SetObjectWithKey(data, "email", event.target.value)
                      )
                    }
                  />
                </FormControl>
                {error && (
                  <Grid padding={0} mt={1}>
                    <FormErrorWithTimeout
                      text={error}
                      clearError={() => setError("")}
                    />
                  </Grid>
                )}
                <CardActions>
                  <Button
                    onClick={() => sendEmail()}
                    variant="solid"
                    color="primary"
                  >
                    Send Link to reset password
                  </Button>
                </CardActions>
              </FormCard>
              <Stack justifyContent="center" alignItems="center">
                <p className="carrot-link">
                  Remember your password?{" "}
                  <Link
                    sx={{
                      color: "white",
                      textDecoration: "underline",
                    }}
                    onClick={() => navigate(PATHS.signin)}
                  >
                    Sign in
                  </Link>
                </p>
              </Stack>
            </Grid>
            <Grid xs></Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};
