import { useState, useEffect } from "react";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";
import { FeedCommentCreator } from "./FeedCommentCreator";
import { FeedComments } from "./FeedComments";
export const FeedComment = ({
  comments: rawComments,
  user,
  feedCardId,
  entity,
  hideCollapsedComments = false,
  reverseCommentCreationOrder = false,
  toggleReply
}: any) => {
  const exiosPrivate = useExiosPrivate();
  const [comments, setComments] = useState([]);
  const [commentLoader, toggleCommentLoader] = useState(false);

  useEffect(() => {
    setComments(rawComments);
  }, [rawComments]);

  const deleteContent = async (data: any) => {
    window.location.reload();
  };

  return (
    <div className="feed-comment-container">
      {comments && comments.length > 0 ? (
        <div className="feed-secondary">
          <FeedComments
            user={user}
            comments={comments}
            deleteContent={deleteContent}
            hideCollapsedComments={hideCollapsedComments}
          />
        </div>
      ) : (
        <></>
      )}
      {
        toggleReply && <FeedCommentCreator
        setComments={setComments}
        user={user}
        entity={entity}
        feedCardId={feedCardId}
      />
      }
    </div>
  );
};
