import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { APIs } from '../../../constants/apis';
import { SpaceType } from './Space.types';

// Define a type for the slice state
interface SpaceState {
    data: SpaceType,
    loading: boolean,
    error: boolean,
    loaded: boolean,
    access: boolean,
}

// Define the initial state using that type
export const initialState: SpaceState = {
    data: {
        id: "",
        name: "",
        icon: "",
        members: [],
        admins: [],
        creator: "",
        created_at: "",
        isUserAdmin: false,
        data: [],
        allow_children: true,
    },
    loading: false,
    error: false,
    loaded: true,
    access: false,
}

export const fetchSpaceAsync = createAsyncThunk(
    'space/fetchSpace',
    async (param: any) => {
        const response = await param.exiosPrivate.get(`${APIs.SPACE}?id=${param.id}`);
        return response.data;
    }
);

export const fetchSpaceSectionsAsync = createAsyncThunk(
    'space/fetchSpaceSectionsAsync',
    async (param: any) => {
        const response = await param.exiosPrivate.get(`${APIs.SPACE_SECTIONS}?id=${param.id}`);
        return response.data.data;
    }
);

export const addMembersToSpace = createAsyncThunk(
    'space/addMembersToSpace',
    async (param: any) => {
        const response = await param.exiosPrivate.post(`${APIs.ADD_MEMBER_TO_SPACE}`, {
            userList: param.userList,
            spaceId: param.spaceId
        });
        return response.data;
    }
);

export const removeMemberFromSpace = createAsyncThunk(
    'space/removeMemberFromSpace',
    async (param: any) => {
        const response = await param.exiosPrivate.post(`${APIs.REMOVE_MEMBER_FROM_SPACE}`, {
            userId: param.userId,
            spaceId: param.spaceId
        });
        return response.data;
    }
);

export const makeMemberAdminToSpace = createAsyncThunk(
    'space/makeMemberAdminToSpace',
    async (param: any) => {
        const response = await param.exiosPrivate.post(`${APIs.MAKE_MEMBER_ADMIN_TO_SPACE}`, {
            userId: param.userId,
            spaceId: param.spaceId
        });
        return response.data;
    }
);

export const removeMemberAdminToSpace = createAsyncThunk(
    'space/removeMemberAdminToSpace',
    async (param: any) => {
        const response = await param.exiosPrivate.post(`${APIs.REMOVE_MEMBER_ADMIN_TO_SPACE}`, {
            userId: param.userId,
            spaceId: param.spaceId
        });
        return response.data;
    }
);


export const spaceSlice:any = createSlice({
    name: 'space',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // logout: (state: any) => {
        //     //state.user = initialState.data
        // },
        updateTreeForSpace: (state, action: PayloadAction<number>) => {
            state.data.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpaceAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.loaded = false;
                state.data = initialState.data;
            })
            .addCase(fetchSpaceAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;

                if (action.payload.status === "Success") {
                    state.access = true;
                    state.data = action.payload.data;
                } else {
                    if (action.payload.status === "Private") {
                        state.access = false;
                        state.data = action.payload;
                    } else {
                        state.error = true;
                        state.access = false;
                        state.data = initialState.data;
                    }
                }
            })
            .addCase(fetchSpaceAsync.rejected, (state) => {
                state.error = true;
                state.loading = false;
                state.loaded = true;
                state.data = initialState.data;
            })
            .addCase(fetchSpaceSectionsAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.loaded = false;
                state.data.data = [];
            })
            .addCase(fetchSpaceSectionsAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;

                if (action.payload.status === "Success") {
                    state.access = true;
                    console.log("action.payload.data.data",action.payload.data.data)
                    state.data.data = action.payload.data.data;
                } else {
                    if (action.payload.status === "Private") {
                        state.access = false;
                        state.data.data = [];
                    } else {
                        state.error = true;
                        state.access = false;
                        state.data.data = [];
                    }
                }
            })
            .addCase(fetchSpaceSectionsAsync.rejected, (state) => {
                state.error = true;
                state.loading = false;
                state.loaded = true;
                state.data.data = [];
            })
            .addCase(addMembersToSpace.fulfilled, (state, action) => {
                if (action.payload.status === "Success") {
                    state.access = true;
                    state.data = action.payload.data;
                } else {
                    if (action.payload.status === "Private") {
                        state.access = false;
                        state.data = action.payload;
                    } else {
                        state.error = true;
                        state.access = false;
                        state.data = initialState.data;
                    }
                }
            })
            .addCase(removeMemberFromSpace.fulfilled, (state, action) => {
                if (action.payload.status === "Success") {
                    state.access = true;
                    state.data = action.payload.data;
                } else {
                    if (action.payload.status === "Private") {
                        state.access = false;
                        state.data = action.payload;
                    } else {
                        state.error = true;
                        state.access = false;
                        state.data = initialState.data;
                    }
                }
            })
            ;
    },
})

export const { updateTreeForSpace } = spaceSlice.actions

export const Space = (state: RootState) => state.space

export default spaceSlice.reducer