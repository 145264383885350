import React, { useEffect, useState } from 'react'
import SpaceHeader from './components/header'
import SpaceFooter from './components/footer'
import SidebarSection from '../../components/ContextMenu/Section'
import SpaceMembers from './components/addMembers'
import { AModal } from '../../components/modal/AModal'
import { Resizable } from 're-resizable'
import AddPrivateFolder from '../../components/Folder/AddPrivateFolder'
import useExiosPrivate from '../../utilities/hooks/useAxiosPrivate'
import _ from 'lodash'
import { APIs } from '../../constants/apis'
import { useAppDispatch } from '../../redux/hooks'
import { updateUserWithNewWorkspaceSettings } from '../../redux/reducers/User'

const SpaceSidebar = ({ space, refreshSpace, navigate, openFile, loader, updateTree, navigateToMain, user }: any) => {
    const exiosPrivate = useExiosPrivate();
    const [spaceId, setSpaceId] = useState(false);
    const [addMemberModal, toggleAddMemberModal] = useState(false);
    const [privateSpaceModal, togglePrivateSpaceModal] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(220);
    const dispatch = useAppDispatch();
    
    const addMembers = (members:any) => {
        toggleAddMemberModal(true)
    } 
    const removeUser = () => {

    }
    const updateTeam = () => {

    }

    useEffect(() => {
        setSpaceId(space.id)
    }, [space]);

    useEffect(() => {
        const sidebarSettings = user.preferences.workspaceSettings.length > 0
            ? _.find(user.preferences.workspaceSettings, (workspace: any) => workspace.id === space.id) : {}
        if (sidebarSettings && sidebarSettings.width) {
            setSidebarWidth(sidebarSettings.width);
        }
    }, [space.id]);


    const resizeWorkspaceSidebar = (sidebarWidth:number, widthChange: number) => {
        setSidebarWidth(sidebarWidth+widthChange)
        debouncedResize({id: spaceId, width: sidebarWidth+widthChange })
    }

    const debouncedResize = React.useRef(
        _.debounce(async (data: any) => {
            try {
            const result = await exiosPrivate.post(`${APIs.USER_SPACE_SIDEBAR_RESIZE}`, {
            workspaceId: data.id,
            size: data.width,
            });
                if (result.data.status === "success") {
               dispatch(updateUserWithNewWorkspaceSettings(result.data.workspaceUpdatedSettings))
            }
        } catch (err) {
            console.log("err", err);
        }
        }, 500)
    ).current;

    return (
        <Resizable
            size={{ width: sidebarWidth + "px", height: "auto" }}
            enable={{ right: true }}
            onResizeStop={async (e, direction, ref, d) => {
                resizeWorkspaceSidebar(sidebarWidth,d.width);
            }}
        >
        <div className="card-sidebar">
                <SpaceHeader data={space} refreshSpace={refreshSpace} space={space} navigate={navigate} />
                { space.isUserAdmin && <SpaceFooter addMembers={addMembers} /> }
                <SidebarSection
                    user={user}
                    updateTree={updateTree}
                    openFile={openFile}
                    type="space"
                    entity={space}
                    data={space.data}
                    loader={loader}
                    navigateToMain={navigateToMain}
                    addPrivateSpace={() => togglePrivateSpaceModal(true)}
                    refreshSpace={refreshSpace}
                />
          {
              addMemberModal &&
              <AModal size="md" onClose={() => toggleAddMemberModal(false)} title={`Members of "${space.name}"`}>
                      <SpaceMembers
                          data={space}
                          onClose={() => toggleAddMemberModal(false)}
                />
              </AModal>
          }
          {
              privateSpaceModal &&
                    <AModal size="md" onClose={() => togglePrivateSpaceModal(false)} title={`Add Private Space"`}>
                        <AddPrivateFolder />
              </AModal>
          }
        </div>
        </Resizable>
  )
}

export default SpaceSidebar