import React, { useState } from "react";
import "./index.scss";
import { Tooltip, Typography } from "@mui/joy";
import UploaderButton from "./UploaderButton";
import MediaViewer from "../MediaViewer/MediaViewer";
import { CloseOutlined } from "@mui/icons-material";
import _ from "lodash";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
const FileAdder = ({
  title,
  types,
  maxSize,
  minSize,
  uploadURL,
  subTitle,
  deleteURL,
  setMediaFiles,
}: any) => {
  const [mediaViewer, toggleMediaViewer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [files, setFiles] = useState<any>([]);
  const exiosPrivate = useExiosPrivate();

  const addFile = async (file: any) => {
    const _files = _.cloneDeep(files);
    _files.push(file);
    setFiles(_files);
    setMediaFiles(_files);
  };

  const openMediaviewer = (index: number) => {
    toggleMediaViewer(true);
    setActiveIndex(index);
  };

  const removeItem = async (index: number) => {
    const _files: any = _.cloneDeep(files);
    _.pullAt(_files, [index]);
    await setFiles(_files);
    setMediaFiles(_files);
    exiosPrivate.post(deleteURL, { id: files[index].id });
  };

  const FileViewerType = (file: any, index: number) => {
    if (["jpeg", "png", "jpg", "svg", "ico", "gif"].includes(file.type)) {
      return (
        <ImageViewerThumbnail
          setActiveIndex={(index: number) => openMediaviewer(index)}
          activeIndex={index}
          file={file}
          removeItem={removeItem}
        />
      );
    } else {
      return (
        <FileViewerSimple
          setActiveIndex={(index: number) => openMediaviewer(index)}
          activeIndex={index}
          file={file}
          removeItem={removeItem}
        />
      );
    }
  };

  return (
    <div>
      {mediaViewer && (
        <MediaViewer
          onClose={() => toggleMediaViewer(false)}
          defaultActiveIndex={activeIndex}
          files={files}
        />
      )}

      <span className="section-name">{title}</span>
      <span className="section-subtitle">{subTitle}</span>
      <div className="file-container">
        {files.length > 0 &&
          files.map((file: any, index: number) => FileViewerType(file, index))}
        <Tooltip title="Upload and attach files">
          <UploaderButton
            uploadURL={uploadURL}
            maxSize={maxSize}
            minSize={minSize}
            types={types}
            addFile={addFile}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export const FileViewerSimple = ({
  file,
  setActiveIndex,
  activeIndex,
  removeItem,
  showRemoveButton = true,
  dimensions = {
    width: "200px",
    height: "100px",
  },
}: any) => {
  const remove = (e: any) => {
    e.stopPropagation();
    removeItem(activeIndex);
  };
  return (
    <div
      className="fileViewerThumb"
      onClick={() => setActiveIndex(activeIndex)}
    >
      <Typography fontWeight={500} fontSize={16}>
        {file.name}
      </Typography>
      {showRemoveButton && (
        <span onClick={remove} className="close-thumb">
          <Tooltip title="Remove this file">
            <CloseOutlined />
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export const ImageViewerThumbnail = ({
  file,
  setActiveIndex,
  activeIndex,
  removeItem,
  showRemoveButton = true,
  dimensions = {
    width: "200px",
    height: "100px",
  },
}: any) => {
  const remove = (e: any) => {
    e.stopPropagation();
    removeItem(activeIndex);
  };
  return (
    <div
      style={{
        width: dimensions.width,
        height: dimensions.height,
        backgroundImage: file.url ? `url(${file.url})` : "",
      }}
      className="imageViewerThumb"
      onClick={() => setActiveIndex(activeIndex)}
    >
      {file && file.url && <div className="description">{file.name}</div>}
      {showRemoveButton && (
        <span onClick={remove} className="close-thumb">
          <Tooltip title="Remove this file">
            <CloseOutlined />
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default FileAdder;
