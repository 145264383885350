import React, { useState } from "react";
import "./index.scss";

export const SingleFileUploader = ({
  children,
  types,
  maxSize,
  minSize,
  name,
  setImage,
}: any) => {
  const [error, setError] = useState("");

  const renderTypes = types
    .map((type: string) => {
      if (type.toLowerCase().indexOf("pdf") >= 0) {
        return `application/${type}`;
      }
      return `image/${type}`;
    })
    .join(",");

  const handleFileUpload = async (e: any) => {
    setError("");
    const currentFile = e.target.files[0];
    if (currentFile.size > maxSize) {
      setError(`File size should not exceed ${Math.round(maxSize / 1000)}KB `);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (currentFile.size < minSize) {
      setError(`File size should be atleast ${Math.round(minSize / 1000)}KB `);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    setImage(currentFile);
  };

  return (
    <form style={{ cursor: "pointer" }}>
      <div className="rel">
        <input
          accept={renderTypes}
          className="hidden-input-file"
          type="file"
          onChange={handleFileUpload}
        />
        {children}
      </div>
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
    </form>
  );
};
