import React, { useState } from "react";
import "./index.scss";
import Names from "../../constants/names";
import { UserSignupProps } from "../../types/user";
import { SetObjectWithKey, validateEmail } from "../../utilities/sharedUtilties";
import { APIs } from "../../constants/apis";
import { exiosPrivate } from "../../api/axios";
import { LOGS } from "../../constants/Logs";
import PATHS from "../../constants/paths";
import { Header } from "../../components/header";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Checkbox,
  Card,
  Grid,
  CardContent,
  FormControl,
  FormLabel,
  CardActions,
  Stack,
  Link,
} from "@mui/joy";
import { useAuth } from "../../utilities/hooks/useAuth";
import { AuthContextProp } from "../../context/AuthProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormCard } from "../../components/cards/formCard";
import { useAppDispatch } from "../../redux/hooks";
export const Signup = () => {
  const [passwordVisibility, togglePasswordVisibility] = useState(false);
  const { setPersist, setAuth } = useAuth() as AuthContextProp;
  const [data, setData] = useState<UserSignupProps>({
    name: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const togglePersist = () => {
    setPersist((prev: any) => !prev);
  };

  const { name, email, password } = data;

  const saveData = async () => {
    if (name) {
      if (email && validateEmail(email)) {
        if (password) {
          if (password.length > 4) {
            try {
            const response = await exiosPrivate.post(APIs.USER, {
              name,
              email,
              password,
              username: email,
            });
              const accessToken = response?.data?.token;
              const defaultParams = response.data.defaultUrlParams;
              setAuth({ email, accessToken, name });
              navigate(`../space/${defaultParams.spaceId}/${defaultParams.type}/${defaultParams.entityId}`)
            } catch (error) {
              console.log(LOGS.SINGUP_ERROR, error);
              alert("Something bad happened, can you try again after a page refresh");
              return;
            }
          } else {
            alert("Passsowrd provided is too short");
            return;
          }
        } else {
          alert("Not a valid password provided");
          return;
        }
      } else {
        alert("Not a valid email provided");
        return;
      }
    }
    else {
      alert("No name provided");
      return;
    }
  };

  return (
    <div>
      <Header>
        <Button
          className="header-button carrot"
          color="primary"
          onClick={() => navigate(PATHS.signin)}
        >
          Sign in
        </Button>
      </Header>
      <div className="body">
        <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={1}>
          <Grid xs></Grid>
          <Grid xs={10} sm={8} md={6} lg={3}>
            <FormCard title="Create a new account">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(event) =>
                    setData(SetObjectWithKey(data, "name", event.target.value))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(event) =>
                    setData(SetObjectWithKey(data, "email", event.target.value))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  endDecorator={
                    passwordVisibility ? (
                      <Visibility
                        onClick={() =>
                          togglePasswordVisibility((prev) => !prev)
                        }
                        sx={{ color: "#a3a3a3" }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={() =>
                          togglePasswordVisibility((prev) => !prev)
                        }
                        sx={{ color: "#a3a3a3" }}
                      />
                    )
                  }
                  value={password}
                  type={passwordVisibility ? "text" : "password"}
                  onChange={(event) =>
                    setData(
                      SetObjectWithKey(data, "password", event.target.value)
                    )
                  }
                />
              </FormControl>
              <Grid mt={1} container spacing={2}>
                <Grid xs={6}>
                  <Checkbox
                    defaultChecked
                    onChange={togglePersist}
                    label={Names.persistText}
                  />
                </Grid>
              </Grid>
              <Button onClick={saveData} variant="solid" color="primary" >
                  Sign up
                </Button>
              </FormCard>
            {/* <Card
              sx={{ marginTop: "1rem" }}
              className="min-card"
              variant="outlined"
            >
              <CardContent
                sx={{
                  display: "grid",
                  gap: 1.5,
                }}
              >
                <Stack justifyContent="center" alignItems="center">
                  <p className="m0">
                    Already having an account,{" "}
                    <Link
                      sx={{ color: "white", textDecoration: "underline" }}
                      onClick={() => navigate(PATHS.signin)}
                    >
                      Signin
                    </Link>
                  </p>
                </Stack>
              </CardContent>
            </Card> */}
          </Grid>
          <Grid xs></Grid>
        </Grid>
      </div>
    </div>
  );
};
