import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { APIs } from '../../constants/apis';
import { UserType } from './User.types';

// Define a type for the slice state
interface UserState {
    data: UserType,
    loading: boolean,
    error: boolean,
    loaded: boolean,
}

// Define the initial state using that type
export const initialState: UserState = {
    data: {
        name: "",
        email: "",
        id: "",
        drawerData: [],
        dp: "",
        organization: {
            name: "Adaily",
            icon: "",
            id: "",
            isAdmin: false,
        },
        verified: false,
        preferences: {
            folderStateList: [],
            activeWorkspace: "",
            activeEntityFile: "",
            theme: {
                className: "",
                activeLinkColor: "",
                colorLeft: "",
                colorRight: "",
            },
            workspaceSettings: []
        }
    },
    loading: false,
    error: false,
    loaded: true,
}

export const fetchUserAsync = createAsyncThunk(
    'user/fetchUser',
    async (exiosPrivate: any) => {
        const response = await exiosPrivate.get(APIs.USER);
        return response.data.user;
    }
);

export const userSlice:any = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        logout: (state: any) => {
            //state.user = initialState.data
        },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        updateUserWithNewWorkspaceConfiguration: (state, action: PayloadAction<any>) => {
            const drawerData = [...state.data.drawerData];
            const selectedWorkspace = drawerData.find((workspace: any) => workspace.id === action.payload.id);
            selectedWorkspace.name = action.payload.name;
            selectedWorkspace.icon = action.payload.icon;
            state.data.drawerData = drawerData;
        },
        updateUserWithNewSpaceTreeData: (state, action: PayloadAction<number>) => {
            state.data.drawerData = action.payload;
        },
        updateUserWithNewWorkspaceSettings: (state, action: PayloadAction<any>) => {
            state.data.preferences.workspaceSettings = action.payload;
        },
        updateUserWithNewFolderList: (state, action: PayloadAction<string>) => {
            const newFolderStateList = [...state.data.preferences.folderStateList];
            newFolderStateList.push(action.payload);
            state.data.preferences.folderStateList = newFolderStateList;
        },
        replaceUserWithNewFolderList: (state, action: PayloadAction<any>) => {
            state.data.preferences.folderStateList = action.payload;
        },
        updateWorkspaceActiveEntityhNewWorkspaceSettings: (state, action: PayloadAction<any>) => {
            const { file, id } = action.payload;
            const data = { ...state.data.preferences };
            const workspaceSettings = [...data.workspaceSettings];
            const newWorkspaceSettings = workspaceSettings.map((wor: any) => ({ ...wor }));
            const activeWorkspaceSettings = newWorkspaceSettings.find((workspace: any) => workspace.id === id);
            if (activeWorkspaceSettings && activeWorkspaceSettings.activeEntity) {
                activeWorkspaceSettings.activeEntity = file.id;
                activeWorkspaceSettings.activeEntityType = file.type;
                state.data.preferences.workspaceSettings = newWorkspaceSettings;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserAsync.pending, (state) => {
                state.loading = true;
                state.loaded = false;
                state.data = initialState.data;
            })
            .addCase(fetchUserAsync.fulfilled, (state, action) => {
                state.error = false;
                state.loading = false;
                state.loaded = true;
                state.data = action.payload;
            })
            .addCase(fetchUserAsync.rejected, (state) => {
                state.error = true;
                state.loading = false;
                state.loaded = true;
                state.data = initialState.data;
            });
    },
})

export const { logout, replaceUserWithNewFolderList, updateUserWithNewFolderList, updateUserWithNewSpaceTreeData,updateUserWithNewWorkspaceConfiguration, updateUserWithNewWorkspaceSettings, updateWorkspaceActiveEntityhNewWorkspaceSettings } = userSlice.actions

export const User = (state: RootState) => state.user

export default userSlice.reducer