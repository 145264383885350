import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CardActions,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Textarea,
  Tooltip,
} from "@mui/joy";
import PATHS from "../../../../../constants/paths";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";

export const ProfileInfo = ({user, refreshUser}: any) => {
  const exiosPrivate = useExiosPrivate();
  
  const [userData, setUserData] = useState({
    name: "",
    about: "",
  });

  useEffect(() => {
    setUserData({
      name: user.name,
      about: user.about
    })
  }, [user.name, user.about])

  const handleInput = (value: string, type: string) => {
    setUserData((prev) => ({
      ...prev,
      [type]: value,
    }));
  };
  const saveData = async () => {
    if (!userData.name) {
      alert("Name cannot be empty");
      return;
    }
    try {
      await exiosPrivate.post(APIs.USER_UPDATE_INFO, userData);
      refreshUser();
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <Grid sm={12} md={12} lg={12}>
      <div>
        <Tooltip title="Email cannot be edited, contact Admin">
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input value="mukur.puri@gmail.com" type="email" disabled />
          </FormControl>
        </Tooltip>
        <FormControl className="mt1 mb1">
          <FormLabel>Name</FormLabel>
          <Input
            onChange={(e) => handleInput(e.currentTarget.value, "name")}
            value={userData.name}
            autoComplete="off"
            type="text"
          />
        </FormControl>
        <FormControl className="mt1 mb1">
          <FormLabel>About</FormLabel>
          <Textarea
            onChange={(e) => handleInput(e.currentTarget.value, "about")}
            value={userData.about}
            minRows={2}
          />
        </FormControl>
        <CardActions>
          <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={1}>
            <Grid xs={12} sm={8} md={8} lg={6}></Grid>
            <Grid
              display="flex"
              justifyContent="flex-end"
              xs={12}
              sm={4}
              md={4}
              lg={6}
            >
              <Button
                onClick={saveData}
                fullWidth
                variant="solid"
                color="primary"
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </div>
    </Grid>
  );
};
