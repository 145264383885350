import React, { useEffect, useState } from 'react'
import useExiosPrivate from '../../../utilities/hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { APIs } from '../../../constants/apis';
import _ from 'lodash';

const useTodos = () => {
    const exiosPrivate: any = useExiosPrivate();
    const { subId } = useParams();
    const [toDoData, setToDoData] = useState<any>({});  
    const [todoLoading, setToDoLoading] = useState<Boolean>(false);  
    
    const fetchTodo = async () => {
      setToDoLoading(true);
      if (subId === "main") return;
    const result: any = await exiosPrivate.get(`${APIs.GET_TASKBOARD}?id=${subId}`);
        if (result.data.status === "Success") {
            setToDoData(result.data.data);
        }
        setToDoLoading(false);
    }

    useEffect(() => {
        fetchTodo();

        return () => {
        setToDoData({})
        }
    }, [subId]);

    const addTodo = async (todo: string) => {
    if (!todo) return false;
    const result = await exiosPrivate.post(APIs.CREATE_TASK, {
      title: todo,
      parent_id: subId,
      index: (toDoData.todos && toDoData.todos.length + 1) ?? 0
    });
    if (result.data.status === "Success") {
      setToDoData((prev: any) => ({ ...prev, todos: result.data.data }));
    }
  }

  const deleteTodo = async (id: string) => {
    if (!id) return false;
    const result = await exiosPrivate.post(APIs.DELETE_TASK, {
      id,
    });
    if (result.data.status === "Success") {
      const updatedTodoList = _.cloneDeep(toDoData).todos.filter((todo: any) => todo.id !== id);
      setToDoData((prev: any) => ({ ...prev, todos: updatedTodoList }));
    }
  }

  const updateCheckForTask = async (state: boolean, todoId: string) => {
    const todos = _.cloneDeep(toDoData.todos);
    let selectedTodo:any = todos.find((todo: any) => todo.id === todoId);
    selectedTodo.isChecked = state;
    setToDoData((prev: any) => ({ ...prev, todos }));

    await exiosPrivate.post(APIs.TOGGLE_TODO, {
      todoId,
      state: state
    });
    }
    
    return {
        updateCheckForTask,
        deleteTodo,
        addTodo,
        toDoData,
        todoLoading
  }
}

export default useTodos