export const getApiGateway = ():any => {
  const domain = window.location.origin;
  return domain.includes("localhost") || domain.includes("127")  || domain.includes("192") 
    ? `http://127.0.0.1:8080`
    : `https://adaily-backend.uc.r.appspot.com`;
};

const locator = `${getApiGateway()}/api`;

export const APIs = {
  BASE: locator,

  REFRESH: `${locator}/auth/refresh`,

  USER: `${locator}/user`,
  AUTH_LOGIN: `${locator}/auth/login`,
  AUTH_LOGOUT: `${locator}/auth/logout`,
  RESET_PASSWORD: `${locator}/user/reset-password`,
  RESET_PASSWORD_VERIFY: `${locator}/user/reset-password-verify`,
  CREATE_NEW_PASSWORD: `${locator}/user/create-password`,

  USER_ONBOARDING: `${locator}/user/onBoarding`,

  USER_INVITATION: `${locator}/user/invitation`,
  USER_INVITATION_JOINER: `${locator}/user/invitation-joiner`,

  USER_DRAWER: `${locator}/user/drawer`,
  USER_CREATE_SECTION: `${locator}/user-sections`,
  ORGANIZATION_MEMBERS: `${locator}/user/get-organization-members`,

  USER_UPDATE_INFO: `${locator}/user/update-info`,
  USER_UPDATE_PASSWORD: `${locator}/user/update-password`,
  USER_UPLOAD_DP: `${locator}/user/upload-profile-picture`,
  
  USER_SPACE_SIDEBAR_RESIZE: `${locator}/user/sidebar/resize`,

  ORGANIZATION_CREATE: `${locator}/organization`,
  ORGANIZATION_UPDATE: `${locator}/organization/update`,
  ORGANIZATION_LOGO_CREATE: `${locator}/organization/create/logo`,


  TEAMS: `${locator}/teams`,
  GET_ALL_TEAMS: `${locator}/teams/all`,
  GET_SUB_TEAMS: `${locator}/teams/sub`,
  GET_ALL_USER_TEAMS: `${locator}/teams/user`,
  GET_ALL_TEAM_USERS: `${locator}/teams/all-users`,
  GET_TEAM: `${locator}/teams/team`,
  ADD_SECTION_TO_TEAM: `${locator}/teams/sections`,
  ACTIVATE_SECTION: `${locator}/teams/section/activate`,
  ADD_MEMBER_TO_TEAM: `${locator}/teams/add/member`,
  RENAME_TEAM: `${locator}/teams/rename`,
  DELETE_MEMBERS_TO_TEAM: `${locator}/teams/delete/member`,

  CONTENT_ADD_FEED_REACTION: `${locator}/content/feed/reaction`,
  CONTENT_TOGGLE_FEED_REACTION: `${locator}/content/feed/reaction/toggle`,
  CONTENT_POST_MEDIA_UPLOAD: `${locator}/content/media/add`,
  CONTENT_POST_MEDIA_DELETE: `${locator}/content/media/delete`,
  CONTENT_POST: `${locator}/content`,
  CONTENT_POST_COMMENTS: `${locator}/content/comment`,
  GET_FEED: `${locator}/content/feed`,
  DELETE_FEED: `${locator}/content/feed/delete`,
  DELETE_CONTENT: `${locator}/content/delete`,

  CREATE_DATASET: `${locator}/dataset/create`,
  GET_DATASET_LIST: `${locator}/dataset/list`,
  GET_DATASET: `${locator}/dataset`,

  PRIORITY: "/priority",
  STATUS: "/status",

  FOLDER_ADD: `${locator}/folder`,
  FOLDER_DELETE: `${locator}/folder/delete`,
  FOLDER_RENAME: `${locator}/folder/rename`,
  GET_FOLDER_TREE: `${locator}/folder/tree/`,
  GET_FOLDER_MEMBER: `${locator}/folder/members/`,
  GET_SPACE_FOLDER_MEMBER_DIFF: `${locator}/folder/members/diff`,
  FOLDER_SAVE_MEMBER_CONFIGURATION: `${locator}/folder/members/configuration`,

  CREATE_FEED: `${locator}/feed/`,
  RENAME_FEED: `${locator}/feed/rename`,
  DELETE_FEED_: `${locator}/feed/delete`,

  GET_DOCUMENT: `${locator}/document/`,
  UPDATE_DOCUMENT: `${locator}/document/update`,
  UPDATE_DOCUMENT_SETTINGS: `${locator}/document/update-settings`,

  CREATE_DOCUMENT: `${locator}/document/`,
  RENAME_DOCUMENT: `${locator}/document/rename`,
  DELETE_DOCUMENT: `${locator}/document/delete`,

  CREATE_TASKBOARD: `${locator}/taskBoard/`,
  RENAME_TASKBOARD: `${locator}/taskBoard/rename`,
  DELETE_TASKBOARD: `${locator}/taskBoard/delete`,
  GET_TASKBOARD: `${locator}/taskBoard/todos`,

  CREATE_TASK: `${locator}/task/`,
  EDIT_TASK_TITLE: `${locator}/task/edit/title`,
  DELETE_TASK: `${locator}/task/delete`,

  GET_TODOS: `${locator}/task/get/todos`,
  TOGGLE_TODO: `${locator}/task/todo/toggle`,
  TOGGLE_TODO_INDEXES: `${locator}/task/todo/toggle_indexes`,


  SPACE: `${locator}/space/`,
  SPACE_SECTIONS: `${locator}/space/sections`,
  RENAME_SPACE: `${locator}/space/rename`,
  DELETE_SPACE: `${locator}/space/delete`,
  ADD_MEMBER_TO_SPACE: `${locator}/space/add/member`,
  REMOVE_MEMBER_FROM_SPACE: `${locator}/space/remove/member`,
  MAKE_MEMBER_ADMIN_TO_SPACE: `${locator}/space/admin/member`,
  REMOVE_MEMBER_ADMIN_TO_SPACE: `${locator}/space/admin/remove/member`,
  GET_MEMBER_SPACE: `${locator}/space/members`,
  SPACE_CONFIGURATIONS: `${locator}/space/configurations`,


  SET_USER_WORKSPACE_ACTIVE: `${locator}/user/setActiveWorkspace`,
  SET_USER_ENTITY_ACTIVE: `${locator}/user/setActiveEntityFile`,
  SET_USER_FOLDERSTATE: `${locator}/user/setFolderState`,
  GET_USER_FOLDERSTATE: `${locator}/user/getFolderState`,
  SET_USER_WORKSPACE_ENTITY: `${locator}/user/sidebar/active/entity`,
};
