import React, { useState, useEffect } from "react";

import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../constants/apis";
import { SpinnerContainer } from "../SpinnerContainer";
import { FormErrorWithTimeout } from "../cards/formError";

const DeleteFolder = ({ root_id, updateTree, close, folder }: any) => {
  const exiosPrivate = useExiosPrivate();
  const [folderId, setFolderId] = useState(null);

  const [loader, toggleLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setFolderId(folder.id);
  }, [folder.id]);

  const deleteFolder = async () => {
    if (folderId === "") {
      setError("Something went wrong");
    }

    const result: any = await exiosPrivate.post(APIs.FOLDER_DELETE, {
      folder_id: folder.id,
      root_id,
    });
    if (result && result.data.status === "Success") {
      updateTree(result.data.data);
      close();
    } else {
      setError("Something went wrong");
    }
  };

  return (
    <div>
      {loader ? (
        <SpinnerContainer height="430px" />
      ) : (
        <div>
            <Stack padding="1rem" paddingTop={0}>
              <Grid xs={12} sm={12} md={8} lg={12} >
                <Typography fontWeight={500}>
                  Are you sure you want to delete "{folder.name}"?
                </Typography>
              </Grid>
              <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <FormErrorWithTimeout
                  clearError={() => setError("")}
                  text={error}
                />
                <Button
                  onClick={deleteFolder}
                  size="md"
                  fullWidth
                  variant="solid"
                  color="primary"
                >
                  Confirm & Delete
                </Button>
              </Grid>
            </Grid>
            </Stack>
          </div>
      )}
    </div>
  );
};

export default DeleteFolder;
