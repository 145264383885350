import axios from "axios";
import { APIs } from "../constants/apis";

export const exios = axios.create({
  baseURL: APIs.BASE,
});

export const exiosPrivate = axios.create({
  baseURL: APIs.BASE,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const exiosPrivateMulti = axios.create({
  baseURL: APIs.BASE,
  withCredentials: true,
});
