import { CircularProgress } from "@mui/joy";
import React from "react";
import "./index.scss";
export const SpinnerContainer = ({ height }: any) => {
  return (
    <div className="spinnerContainer" style={{ height: height ?? "100%" }}>
      <CircularProgress variant="plain" />
    </div>
  );
};
