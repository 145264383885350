import { useState, useEffect } from "react";
import { APIs } from "../../constants/apis";

export const useMembers = ({ exiosPrivate }: any) => {
  const [members, setMembers] = useState<any>([]);
  const [loadMembers, setLoadMembers] = useState(false);

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMembers = async () => {
    setLoadMembers(true);
    const result = await exiosPrivate(APIs.ORGANIZATION_MEMBERS);
    if (result.data.length > 0) {
      const data = result.data.map((member: any) => ({
        ...member,
        selected: false,
      }));
      setMembers(data);
    }
    setLoadMembers(false);
  };

  return {
    members,
    setMembers,
    loadMembers,
  };
};
