import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
} from "@mui/joy";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import React, { useState, useEffect } from "react";
import "./index.scss";
import IconSelector from "../../../components/IconSelector";
import { FormErrorWithTimeout } from "../../../components/cards/formError";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";
import SkipSetOrganization from "./SkipSetOrganization";
import { APIs } from "../../../constants/apis";
import _ from "lodash";
import { SpinnerContainer } from "../../../components/SpinnerContainer";

export const OrganizationStep = ({ src, onClose, user, refreshUser }: any) => {
  const exiosPrivate = useExiosPrivate();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    id: user.organization.id ?? "",
    name: user.organization.name ?? "",
    icon: user.organization.icon ?? "",
    scope: user.organization.scope ?? "private",
  });

  useEffect(() => {
    const _data = _.cloneDeep(data);
    _data.name = user.organization.name;
    _data.icon = user.organization.icon;
    _data.scope = user.organization.scope;
    setData(_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const createOrganization = async () => {
    if (data.name === "") {
      setError("Name of organization cannot be empty");
    } else {
      try {
        setLoader(true);
        let result: any = "";
        const API_URL = user.organization.id
          ? APIs.ORGANIZATION_UPDATE
          : APIs.ORGANIZATION_CREATE;
        result = await exiosPrivate.post(API_URL, data);
        if (result.data) {
          await refreshUser();
        }
        onClose();
      } catch (err) {
        console.log("err", err);
      }
      setLoader(false);
    }
  };

  const setName = (e: any) => {
    const value = e.currentTarget.value;
    setData((prev) => ({ ...prev, name: value }));
  };

  const setIcon = (url: string) => {
    setData((prev) => ({ ...prev, icon: url }));
  };

  return (
    <Stack>
      {loader ? (
        <SpinnerContainer height="450px" />
      ) : (
        <Stack sx={{ padding: "1rem" }}>
          <Grid container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid xs={12} sm={12} md={8} lg={8}>
              <FormControl>
                <FormLabel required>Name of the Organization</FormLabel>
                <Input value={data.name} onChange={setName} type="text" />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4}>
              <FormControl>
                <FormLabel required>Scope</FormLabel>
                <Select
                  value={data.scope}
                  onChange={(event) =>
                    setData((prev: any) => ({
                      ...prev,
                      scope: event.target.value,
                    }))
                  }
                  className="aSelect"
                  displayEmpty
                >
                  <MenuItem value="public">Public</MenuItem>
                  <MenuItem value="private">Private</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid spacing={3} mt={2} mb={1} container>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <IconSelector icon={data.icon} setIcon={setIcon} />
            </Grid>
          </Grid>
          <Grid mt={1} mb={2}>
            <Typography sx={{ fontSize: "15px", color: "#999" }}>
              *Private Organization is visible only to its members.
            </Typography>
          </Grid>
          <div style={{ paddingBottom: 0, marginTop: 2 }}>
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <FormErrorWithTimeout
                  clearError={() => setError("")}
                  text={error}
                />
                <Button
                  onClick={createOrganization}
                  size="md"
                  fullWidth
                  variant="solid"
                  color="primary"
                >
                  {user.organization.id === ""
                    ? `Create Organization`
                    : `Update Organization`}
                </Button>
                {src === "drawer" && (
                  <div>
                    <SkipSetOrganization onClose={onClose} />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Stack>
      )}
    </Stack>
  );
};
