import React, { useState, useEffect } from "react";

import { Button, FormControl, FormLabel, Grid, Input, Stack } from "@mui/joy";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../constants/apis";
import { SpinnerContainer } from "../SpinnerContainer";
import { FormErrorWithTimeout } from "../cards/formError";

const RenameFolder = ({ root_id, updateTree, close, folder }: any) => {
  const exiosPrivate = useExiosPrivate();

  const [data, setData] = useState<any>({});
  const [loader, toggleLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setData(folder);
  }, [folder]);

  const RenameFolderModal = async () => {
    if (!data.name || data.name === "") {
      setError("Folder name cannot be empty");
    }

    const result: any = await exiosPrivate.post(APIs.FOLDER_RENAME, {
      ...data,
      root_id: root_id,
    });
    if (result && result.data.status === "Success") {
      updateTree(result.data.data);
      close();
    } else {
      setError("Something went wrong");
    }
  };

  const setName = (e: any) => {
    const newData = { ...data };
    newData.name = e.currentTarget?.value;
    setData(newData);
  };

  return (
    <div>
      {loader ? (
        <SpinnerContainer height="430px" />
      ) : (
          <div>
            <Stack padding="1rem" paddingTop={0}>
              <Grid xs={12} sm={12} md={8} lg={12} mt="1rem">
                  <FormControl>
                    <FormLabel required>Name of the folder</FormLabel>
                    <Input
                      onChange={setName}
                      type="text"
                      defaultValue={data.name}
                      value={data.name}
                    />
                  </FormControl>
              </Grid>
              <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                >
                <FormErrorWithTimeout
                    clearError={() => setError("")}
                    text={error}
                />
                <Button
                    onClick={RenameFolderModal}
                    size="md"
                    fullWidth
                    variant="solid"
                    color="primary"
                >
                    Rename 
                </Button>
                </Grid>
            </Grid>
            </Stack>
          </div>
      )}
    </div>
  );
};

export default RenameFolder;
