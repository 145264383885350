import {
  InsertPhoto,
  PermIdentityOutlined,
  VpnKeyOffOutlined,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/joy";
import React, { useContext, useState } from "react";
import "./index.scss";
import { ProfileInfo } from "./sections/ProfileInfo";
import { ProfilePicture } from "./sections/ProfilePicture";
import { ResetNewPassword } from "./sections/ResetNewPassword";
import { getUniqueKey } from "../../../../utilities/sharedUtilties";

export const ProfileAccount = ({ onClose, logout, user,refreshUser }: any) => {
  const options = {
    RESET_PASSWORD: "Reset Password",
    CHANGE_PHOTO: "Change Profile Picture",
    UPDATE_INFO: "Update Profile Info",
  };
  const [links, setLinks] = useState([
    {
      name: options.UPDATE_INFO,
      active: true,
      component: <ProfileInfo refreshUser={refreshUser} user={user} key="profile-info" />,
      icon: <PermIdentityOutlined />,
    },
    {
      name: options.CHANGE_PHOTO,
      active: false,
      component: (
        <ProfilePicture refreshUser={() => console.log("refresh")} key="profile-picture" />
      ),
      icon: <InsertPhoto />,
    },
    {
      name: options.RESET_PASSWORD,
      active: false,
      component: <ResetNewPassword key="reset-new-password" />,
      icon: <VpnKeyOffOutlined />,
    },
  ]);

  const setCurrentLink = (name: string) => {
    const newLinks = [...links];
    newLinks.forEach((link) => (link.active = false));
    const selectLink: any = links.find((link: any) => link.name === name);
    selectLink.active = true;
    setLinks(newLinks);
  };

  return (
    <div>
      <Grid m={0} container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid
          sx={{ borderRight: "1px solid #d8d8df" }}
          display="flex"
          sm={12}
          md={12}
          lg={4}
          m={0}
          bgcolor="#f9f9f9"
        >
          <ul className="list-link-options">
            {links.map((link, index) => (
              <li
                key={getUniqueKey("list-settings")}
                style={{ display: "flex", flexDirection: "row" }}
                onClick={() => setCurrentLink(link.name)}
              >
                <Typography className={`${link.active && "active"} link`}>
                  {link.name}
                </Typography>
              </li>
            ))}
            <li style={{ display: "flex", flexDirection: "row" }} onClick={logout}>
              <Typography className={`link`}>
                  Logout
                </Typography>
            </li>
          </ul>
        </Grid>
        <Grid minHeight="384px" display="flex" sm={12} md={12} lg={8}>
          {links.map((link) => link.active && link.component)}
        </Grid>
      </Grid>
    </div>
  );
};
