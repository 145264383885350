import {
  closestCenter,
  DndContext, 
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import TodoItemSkeleton from './TodoItemSkeleton'
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { exiosPrivate } from '../../../../api/axios';
import { APIs } from '../../../../constants/apis';

const TodoList = ({ toDoData, deleteTodo, updateCheckForTask }: any) => {
    const [todos, setTodos] = useState([]);
    useEffect(() => {
        setTodos(toDoData.todos)
    }, [toDoData.todos])
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const toggleTodoIndexes = async (newTodoList:any) => {
        const todoIndexes = newTodoList.map((todo: any) => ({ id: todo.id, index: todo.index }));
        try {
            await exiosPrivate.post(APIs.TOGGLE_TODO_INDEXES, todoIndexes);
        } catch (err) {
            console.log("err", err);
        }
    }

    const handleDragEnd = (event:any) => {
        const {active, over} = event;
        if (active.id !== over.id) {
            const oldIndex = _.findIndex(todos, (todo: any) => todo.id === active.id);
            const newIndex = _.findIndex(todos, (todo: any) => todo.id === over.id);
            const newTodoList:any = arrayMove(todos, oldIndex, newIndex);
            newTodoList.forEach((todo: any, index: number) => todo.index = index);
            setTodos(newTodoList);
            toggleTodoIndexes(newTodoList)
        }
    }
    if (!toDoData.todos || toDoData.todos.length < 0) return <></>;

    if(!todos) return <></>

    return (
        <div className='todoList'>
            <DndContext 
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
                >
                <SortableContext 
                    items={todos}
                    strategy={verticalListSortingStrategy}
                >
                    {todos && todos?.sort(
                        (a:any,b:any) => a.index - b.index
                    )?.map((todo: any) =>
                        <TodoItemSkeleton
                            deleteTodo={deleteTodo}
                            updateCheckForTask={updateCheckForTask}
                            todo={todo}
                            key={todo.id}
                            id={todo.id}
                        />
                    )}
                </SortableContext>
            </DndContext>
        </div>
    )
}

export default TodoList