import { useEffect, useRef } from "react";
import FeedItem from "./Feed";

const FeedList = ({ entity, feedData, user, setFeed, feedLoaded, scrollFeedToEnd }: any) => {

  const feedListRef: any = useRef(null);

  const scrollToEnd = () => {
    setTimeout(() => {
        scrollToBottom();
      }, 500);
  }

  useEffect(() => {
    if (feedLoaded) {
      scrollToEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedLoaded]);

  if (scrollFeedToEnd) {
      scrollToEnd();
    }

  const scrollToBottom = () => {
    const lastChildElement = feedListRef.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };
  if (!feedData || feedData.length === 0) {
    return <></>;
  }
  return (
    <div ref={feedListRef} className="feed-container">
      {feedData.map((feedItem: any) => (
        <FeedItem
          key={feedItem.id}
          user={user}
          data={feedItem}
          feedCardData={feedItem}
          setFeed={setFeed}
          entity={entity}
        />
      ))}
    </div>
  );
};

export default FeedList;
