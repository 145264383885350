import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormLabel,
  Grid,
} from "@mui/joy";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useExiosPrivate from '../../utilities/hooks/useAxiosPrivate';
import { APIs } from '../../constants/apis';
import SelectMembersByName from '../AddMembers/SelectMembersByName';

const FolderMembersSelector = ({ parentId, setSelectedMembers }: any) => {
    const exiosPrivate = useExiosPrivate();
    const [members, setMembers] = useState([]);
    const [memberSelectorFor, setMemberSelectorFor] = useState("me");

    const selectMemberType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMemberSelectorFor(event.target.value);
    };

    const fetchWorkspaceMembers = async (parentId: string) => {
        try {
            const result = await exiosPrivate.get(`${APIs.GET_MEMBER_SPACE}?spaceId=${parentId}`);
            if (result && result.data && result.data.spaces.length > 0) {
                setMembers(result.data.spaces);
            }
        }
        catch (err) {
            console.log("err", err);
        }
    }

    useEffect(() => {
        fetchWorkspaceMembers(parentId)
    }, [parentId]);

    return (
    <>
        <Grid xs={12} sm={12} md={12} lg={12} mt="1rem">
            <FormControl>
                <FormLabel id="share-with">Share with</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="share-with"
                    name="row-radio-buttons-group"
                    onChange={selectMemberType}
                    value={memberSelectorFor}
                >
                <FormControlLabel value="me" control={<Radio />} label="Only Me" />
                <FormControlLabel value="custom" control={<Radio />} label="Select members" />                    
                </RadioGroup>
            </FormControl>
            </Grid>
            {
                memberSelectorFor === "custom" &&
                <Grid xs={12} sm={12} md={12} lg={12} mt="1rem">
                    <SelectMembersByName setMembers={setSelectedMembers} members={members} />
                </Grid>
            }
            
    </>
  )
}

export default FolderMembersSelector