import React, { createContext, useState } from "react";

const AuthContext = createContext({});

export interface AuthProp {
  email: string;
  roles: [string];
  accessToken: string;
  name: string;
}

export interface AuthContextProp {
  auth: AuthProp;
  setAuth: (param: object) => void;
  persist: any;
  setPersist: any;
}

export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist") as string) || false
  );
  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
