import { Autocomplete, AutocompleteOption, Avatar, ListItemContent, ListItemDecorator, Typography } from '@mui/joy'
import React from 'react'
import { textIconCreator } from '../../utilities/sharedUtilties'

const SelectMembersByName = ({ members, setMembers }: any) => {
  if(!members || members.length === 0) return <></>
  return (
      <div>
      <Autocomplete
              onChange={(event, value) => setMembers(value)} // prints the selected value
              id="country-select-demo"
              placeholder="Search Member"
              multiple
              slotProps={{
                    input: {
                    autoComplete: 'new-password', // disable autocomplete and autofill
                    },
                }}
                options={members}
                autoHighlight
                getOptionLabel={(option:any) => option.name}
                renderOption={(props, option) => (
                    <AutocompleteOption {...props}>
                    <ListItemDecorator>
                        {option.dp !== "" ? (
                          <Avatar
                            sx={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "4px",
                              backgroundColor: "transparent",
                            }}
                            src={option.dp}
                          />
                        ) : (
                          textIconCreator(option.name, "l")
                        )}
                    </ListItemDecorator>
                    <ListItemContent sx={{ fontSize: 'md' }}>
                        <Typography sx={{fontWeight: "500"}}>{option.name}</Typography>
                    </ListItemContent>
                    </AutocompleteOption>
                )}
        />
    </div>
  )
}

export default SelectMembersByName