import { Button, FormControl, FormLabel, Grid, Input, Stack } from "@mui/joy";
import React, { useEffect, useState } from "react";
import IconSelector from "../../../../IconSelector";
import { FormErrorWithTimeout } from "../../../../cards/formError";
import _ from "lodash";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";
import { useParams } from "react-router-dom";

const FeedCreatorModal = ({ parentId, close, updateTree }: any) => {
  const { id: root_id } = useParams();
  const exiosPrivate = useExiosPrivate();
  const [data, setData] = useState<any>({
    name: "",
    icon: "",
    parent_id: parentId,
    root_id: root_id,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    setDataHandler(parentId, "parent_id");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const setDataHandler = (value: string, type: string) => {
    const _data: any = _.cloneDeep(data);
    _data[type] = value;
    setData(_data);
  };

  useEffect(() => {
    return () => {
      setData({});
      setError("");
    };
  }, []);

  const createTeam = async () => {
    if (!data.name) {
      setError("Name cannot be empty");
    }

    const response = await exiosPrivate.post(APIs.CREATE_FEED, data);
    if (response.data.status === "Success") {
      updateTree(response.data.data);
      close();
    }
  };

  return (
    <div>
      <Stack padding="1rem" paddingTop={0}>
          <Grid xs={12} sm={12} md={12} lg={12} mt="1rem">
            <FormControl>
              <FormLabel required>Name of the Forum</FormLabel>
              <Input
                onChange={(e) => setDataHandler(e.currentTarget.value, "name")}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid mt="1rem" xs={12} sm={12} md={12} lg={12}>
            <IconSelector
              from="F"
              icon={data.icon}
              setIcon={(icon: string) => setDataHandler(icon, "icon")}
              noImg={true}
              name={data.name}
            />
          </Grid>
          <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid
              display="flex"
              justifyContent="flex-end"
              flexDirection="column"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FormErrorWithTimeout
                clearError={() => setError("")}
                text={error}
              />
              <Button
                onClick={createTeam}
                size="md"
                fullWidth
                variant="solid"
                color="primary"
              >
                Create
              </Button>
            </Grid>
          </Grid>
      </Stack>
    </div>
  );
};

export default FeedCreatorModal;
