import React from "react";
import FileAdder from "../../../../../FileAdder";
import { APIs } from "../../../../../../constants/apis";
import { CloseRounded } from "@mui/icons-material";

const FeedFileAdder = ({ setMediaFiles, close }: any) => {
  return (
    <div className="file-upload-section file-adder">
      <div className="close" onClick={() => close()}>
        <CloseRounded />
      </div>
      <FileAdder
        types={[]}
        title="Add Files and Documents"
        subTitle=""
        maxSize={200000000}
        minSize={10}
        uploadURL={APIs.CONTENT_POST_MEDIA_UPLOAD}
        deleteURL={APIs.CONTENT_POST_MEDIA_DELETE}
        setMediaFiles={setMediaFiles}
      />
    </div>
  );
};

export default FeedFileAdder;
