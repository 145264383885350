import {
  ArrowBackOutlined,
  LooksOutlined,
  MoreVert,
} from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/joy";
import { AModal } from "../../../components/modal/AModal";
import ConfigureSpace from "./ConfigureSpace";
import { useState } from "react";

const SpaceHeader = ({ space, navigate, refreshSpace, data }: any) => {
  const [showSettings, toggleSettings] = useState(false);

  return (
    <div className="section-header">
      <div className="flex-row gHalf full" style={{justifyContent: "space-between", alignItems: "center"}}>
      <div className="flex-row gHalf">
      <span className="back-pointer">
        <Tooltip title="Back">
          <ArrowBackOutlined  onClick={() => navigate(-1)} />
        </Tooltip>
      </span>
      <div className="flex-center ">
        {space.icon !== "" ? (
          <img
            src={space.icon}
            style={{ width: "1.3rem" }}
            alt={space.name}
          />
        ) : (
            <LooksOutlined sx={{ color: "#9d9d9d"}} />
        )}&nbsp;&nbsp;
        <Typography textTransform="capitalize" fontWeight={600} fontSize="0.9rem">
          {space.name}
        </Typography>
        </div>
        </div>
        <div className="flex-row flex-center morever">
          <MoreVert onClick={() => toggleSettings(true)} />
        </div>
      </div>
      {
          showSettings && (
          <AModal
            size="sm"
            onClose={() => toggleSettings(false)}
            title={`Edit Space`}
            >
              <ConfigureSpace space={data} close={() => { toggleSettings(false); refreshSpace(); }} />
        </AModal>
        )
        }
    </div>
  );
};

export default SpaceHeader;
