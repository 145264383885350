import React from "react";
import { ContextMenuItem } from "rctx-contextmenu";
import { FolderOutlined, FolderSharedOutlined, LockOutlined } from "@mui/icons-material";
import { ContextMenuTitle } from "./SpaceTree";
import { Divider } from "@mui/joy";
const FolderMenu = ({ addFolder }: any) => {
  return (
    <>
      <Divider />
      <ContextMenuItem onClick={() => addFolder(true)}>
        <ContextMenuTitle>
          <FolderOutlined sx={{ fontSize: 17 }} />
          New Folder
        </ContextMenuTitle>
      </ContextMenuItem>
    </>
  );
};

export default FolderMenu;
