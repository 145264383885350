
export const enum TipTapMenuActions {
  bold = "bold",
  italic = "italic",
  underline = "underline",
  bullet = "bullet",
  ordered = "ordered",
  code = "code",
  color_red = "color_red",
  color_green = "color_green",
  color_blue = "color_blue",
  textLeft = "textLeft",
  textCenter = "textCenter",
  textRight = "textRight",
  block = "block",
  highlight = "highlight",
  strike = "strike",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  hRule = "hRule",
  formatClear = "formatClear",
  addLink = "addLink",
  deleteNode = "deleteNode",
  table = "table",

  color_Default = '#333',
  color_Grey = "rgb(120, 119, 116)",
  color_Brown = "rgb(159, 107, 83)",
  color_Orange = "rgb(217, 115, 13)",
  color_Yellow = "rgb(203, 145, 47)",
  color_Green = "rgb(68, 131, 97)",
  color_Blue = "rgb(51, 126, 169)",
  color_Purple = "rgb(144, 101, 176)",
  color_Pink = "rgb(193, 76, 138)",
  color_Red = "rgb(212, 76, 71)",
  bgColor_Default = '#fff',
  bgColor_Grey = "rgb(241, 241, 239)",
  bgColor_Brown = "rgb(244, 238, 238)",
  bgColor_Orange = "rgb(251, 236, 221)",
  bgColor_Yellow = "rgb(251, 243, 219)",
  bgColor_Green = "rgb(237, 243, 236)",
  bgColor_Blue = "rgb(231, 243, 248)",
  bgColor_Purple = "rgba(244, 240, 247, 0.8)",
  bgColor_Pink = "rgba(249, 238, 243, 0.8)",
  bgColor_Red = "rgb(253, 235, 236)",
}