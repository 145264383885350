import { OrgnizationLogo } from "../MainHeader/OrgnizationLogo";
import { DrawerWrapper } from "./DrawerWrapper";
import UserLink from "./UserLink";
import SpaceList from "./SpaceList/SpaceList";
import { UserType } from "../../../redux/reducers/User.types";
import "../../Home/index.scss";
import { Resizable } from "re-resizable";
import { Tooltip } from "@mui/joy";
import { AddOutlined } from "@mui/icons-material";
import { AModal } from "../../../components/modal/AModal";
import CreateSpaceList from "./SpaceList/CreateSpaceList";
import { useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchUserAsync } from "../../../redux/reducers/User";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";

interface DrawerType {
  user: {
    data: UserType,
    loading: boolean,
    loaded: boolean,
  }
}

export const Drawer = ({ user }: DrawerType) => {
  const [createSpaceModal, setcreateSpaceModal] = useState(false);
  const dispatch = useAppDispatch();
  const exiosPrivate = useExiosPrivate();

  const refreshUser = () => {
    dispatch(fetchUserAsync(exiosPrivate))
  }

  return (
    <>
    <Resizable minWidth={"13rem"} enable={{right: true}}>
      <div className="drawer">
        <OrgnizationLogo
          userData={user}
        />
          <UserLink refreshUser={refreshUser} user={user.data} />
          <span className='section-header-name'>Workspaces
            <span className="action-container">
              <Tooltip title="New Workspace">
                <AddOutlined sx={{fontSize: "18px", cursor: "pointer"}} onClick={() => setcreateSpaceModal(true)} />
              </Tooltip>
            </span>
          </span>
        <DrawerWrapper className="sidebar-overflowed spaces">
          <SpaceList user={user.data} />
        </DrawerWrapper>
      </div>
      </Resizable>
      {
        createSpaceModal && <AModal title="Create New Workspace" onClose={() => setcreateSpaceModal(false)}>
          <CreateSpaceList
            onClose={() => setcreateSpaceModal(false)}
            callback={() => console.log("Call")}
            parentId={user.data.id}
          />
        </AModal>
      }
      </>
  );
};
