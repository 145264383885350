import { Tooltip } from "@mui/joy";
import "./index.scss";
const MembersinCircularGroupHorizontal = ({ members }: any) => {

    let memberNames = members.map((mem: any) => mem.name);
    memberNames = memberNames.join(", ");

    const memberEntity = (member: any) => {
        if (member.dp !== "") {
            return <img
                      src={member.dp}
                  />
        }
        return <div className='text-icon'>
            {member.name[0]}
        </div>;
    }
    return (
      <Tooltip title={`Private Space with ${memberNames}`}>
      <div className='mm-dp-container'>
          {
              members.map((member: any, index: number) => index < 5 ? memberEntity(member) : null)
          }
            </div>
        </Tooltip>
  )
}



export default MembersinCircularGroupHorizontal