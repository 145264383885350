import React from "react";
import "./index.scss";
import { Header } from "../../components/header";
import { Body } from "./Body";
import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import PATHS from "../../constants/paths";

export const Landing = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header>
        <Button
          className="header-button shadow inverted "
          color="primary"
          onClick={() => navigate(PATHS.signin)}
        >
          Sign in
        </Button>
      </Header>

      <Body />
    </div>
  );
};
