import { useState, useEffect } from "react";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../constants/apis";
import { SpinnerContainer } from "../../SpinnerContainer";
import { Button, FormControl, FormLabel, Grid, Input, Stack } from "@mui/joy";
import { FormErrorWithTimeout } from "../../cards/formError";
import { SectionTypeNames } from "../../Sections/Renderer";
import IconSelector from "../../IconSelector";

const RenameSection = ({ file, updateTree, root_id }: any) => {
  const exiosPrivate = useExiosPrivate();

  const [data, setData] = useState<any>({});
  const [loader, toggleLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setData(file);
  }, [file]);

  const RenameFolderModal = async () => {
    if (!data.name || data.name === "") {
      setError("Name cannot be empty");
    }
    let API = "";
    switch (data.type) {
      case SectionTypeNames.FEED:
        API = APIs.RENAME_FEED;
        break;
      case SectionTypeNames.DOCUMENT:
        API = APIs.RENAME_DOCUMENT;
        break;
      case SectionTypeNames.TASKER:
        API = APIs.RENAME_TASKBOARD;
        break;
    }
    if (API) {
      const result: any = await exiosPrivate.post(API, {
        ...data,
        root_id: root_id,
      });
      if (result && result.data.status === "Success") {
        updateTree(result.data.data);
      } else {
        setError("Something went wrong");
      }
    }
  };

  const setName = (e: any) => {
    const newData = { ...data };
    newData.name = e.currentTarget?.value;
    setData(newData);
  };

  return (
    <div>
      {loader ? (
        <SpinnerContainer height="430px" />
      ) : (
          <div>
            <Stack padding="1rem" paddingTop={0}>
              <Grid mt="1rem" xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                  <FormLabel required>Name</FormLabel>
                  <Input
                    onChange={setName}
                    type="text"
                    defaultValue={data.name}
                    value={data.name}
                  />
                </FormControl>
              </Grid>
              <Grid  mt="1rem" xs={12} sm={12} md={12} lg={12}>
                <IconSelector
                  icon={data.icon}
                  setIcon={(icon: string) =>
                    setData((prev: any) => ({ ...prev, icon }))
                  }
                  noImg={true}
                  name={data.name}
                />
              </Grid>
              <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                >
                <FormErrorWithTimeout
                    clearError={() => setError("")}
                    text={error}
                />
                <Button
                    onClick={RenameFolderModal}
                    size="md"
                    fullWidth
                    variant="solid"
                    color="primary"
                >
                    Rename 
                </Button>
                </Grid>
            </Grid>
            </Stack>
            </div>
      )}
    </div>
  );
};

export default RenameSection;
