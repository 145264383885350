import "./index.scss";
import useDocument from "./document.hook";
import Cubical from "../../Cubical";
import TiptapWrapperForDocument from "../../TiptapEditor/DocumentTiptapEditor";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Document = ({ id }: any) => {
  const { subId } = useParams();
  const { document,
    updateDocumentContent,
    documentLoading,
    fetchDocument
  } = useDocument({ id });

  useEffect(() => {
    fetchDocument();
  }, [subId]);
  return (
    <Cubical isLoading={documentLoading}>
      <Cubical.header>
        <Cubical.header.title icon={document.icon} label={document.name} />
      </Cubical.header>
      <Cubical.body>
        <div className="document-wrapper">
        <div className="document-container">
          <div className="editor">
            <TiptapWrapperForDocument
              readOnly={document.readOnly }
              content={document.content}
              placeholder="Write notes, article, topics etc."
              updateDocumentContent={updateDocumentContent}
            /> 
          </div>
          </div>
        </div>
      </Cubical.body>
    </Cubical>
  );
};

export default Document;