import React, { useContext, useEffect, useRef,useState } from "react";
import "./index.scss";
import FeedCreator from "./components/FeedCreator";
import useFeed from "./feed.hooks";
import FeedList from "./components/FeedListing";
import { textIconCreator } from "../../../../utilities/sharedUtilties";
import { Typography } from "@mui/joy";
import { User } from "../../../../redux/reducers/User";
import { useAppSelector } from "../../../../redux/hooks";
import SectionHeader from "../../SectionHeader";
import Cubical from "../../../Cubical";

const Feed = ({ entity, id }: any) => {
  const {
    feed: feedData,
    refreshFeed,
    feedPrimary,
    feedLoaded: feedReady,
  } = useFeed({ feedId: id });
  const { data: user }:any = useAppSelector(User);

  const [feedLoaded, setFeedLoaded] = useState(false);
  const [scrollFeedToEnd, setScrollFeedToEnd] = useState(false);

  const refreshFeedCall = (content: string) => {
    if (refreshFeed) {  
      refreshFeed(content);
      setScrollFeedToEnd(true);
    }
  }

  useEffect(() => {
    if (feedReady) {
      setFeedLoaded(true);
    }
  }, [feedReady])
  
  return (
    <Cubical>
      <Cubical.header>
        <Cubical.header.title icon={feedPrimary?.icon} label={feedPrimary?.name} />
      </Cubical.header>
      <Cubical.body>
        <div className="section-content-container">
          <FeedList
            entity={entity}
            user={user}
            feedData={feedData}
            refreshFeed={refreshFeed}
            setFeed={refreshFeed}
            feedLoaded={feedLoaded}
            scrollFeedToEnd={scrollFeedToEnd}
          />
        </div>
        <FeedCreator
          refreshFeed={(content:any) => refreshFeedCall(content)}
          id={id}
          entity={entity}
          user={user}
        />
      </Cubical.body>
    </Cubical>

    
  );
};

export default Feed;
