import { InsertEmoticonOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import EmojiPicker from 'emoji-picker-react';

const TextEmojiOptions = ({ setEmoji, isEditorFocus }: any) => {
    const [subBubble, setSubBubble] = useState(false);
    const selectEmoji = (emoji: any) => {
        setEmoji(emoji.emoji)
    }

    useEffect(() => {
        if (isEditorFocus) {
            setSubBubble(false)
        }
    }, [isEditorFocus])
    return (
        <>
        <div onClick={() => setSubBubble((prev) => !prev)}  className="bubble-btn rel">
            <div  className="header-text">
                <InsertEmoticonOutlined />
                </div>
                {
                    subBubble &&
                    <div style={{right: "-1rem", bottom: "3rem"}} className="reaction-picker">
                        <EmojiPicker width="100%" onEmojiClick={selectEmoji} />
                    </div>
                }
            </div>
      </>
      
  )
}

export default TextEmojiOptions