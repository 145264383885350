import { useAuth } from "./useAuth";
import { exiosPrivate } from "../../api/axios";
import { AuthContextProp, AuthProp } from "../../context/AuthProvider";
import { APIs } from "../../constants/apis";

export const useRefreshToken = () => {
  const { auth, setAuth } = useAuth() as AuthContextProp;

  const refresh = async () => {
    if (!auth) {
      return;
    }
    try {
      const response = await exiosPrivate.post(APIs.REFRESH);
      const token = response?.data?.token;
      setAuth((prev: AuthProp) => ({
        ...prev,
        accessToken: token,
      }));
      return token;
    } catch (err) {}
  };

  return refresh;
};
