import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import "./index.scss";

export const AModal = ({ onClose, size, children, title, minHeight, }: any) => {
  return (
    <Modal
      sx={{ minHeight: minHeight ?? "" }}
      className={`aModal  ${size}`}
      open={true}
    >
      <ModalDialog layout="center" variant="plain">
        <div className="amodal-header">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            fontWeight="500"
          >
            {title}
          </Typography>
        </div>
        <ModalClose onClick={onClose} />
        {children}
      </ModalDialog>
    </Modal>
  );
};
