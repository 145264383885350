import { Signup } from "./Signup";
import { Signin } from "./Signin";
import { Home } from "./Home";
import { Hello } from "./Hello";

import { PageLoadingScreen } from "./PageLoadingScreen";

import { Landing } from "./Landing";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";

import { InvitationReceiver } from "./Invitation/Receiver";

import { Space } from "./Space"

const Pages = {
  Signup,
  Hello,
  Home,
  Signin,
  Landing,
  ForgotPassword,
  ResetPassword,
  PageLoadingScreen,
  InvitationReceiver,
  Space
};
export default Pages;
