import { useState, useEffect } from "react";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../constants/apis";
import { SpinnerContainer } from "../../SpinnerContainer";
import {
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/joy";
import { FormErrorWithTimeout } from "../../cards/formError";
import { SectionTypeNames } from "../../Sections/Renderer";

const DeleteSection = ({ file, root_id, updateTree, resetEverything, navigateToMain }: any) => {
  const exiosPrivate = useExiosPrivate();
  const [entityId, setEntityId] = useState(null);

  const [loader, toggleLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setEntityId(file.id);
  }, [file.id]);

  const deleteFolder = async () => {
    if (entityId === "") {
      setError("Something went wrong");
    }
    let API = "";
    switch (file.type) {
      case SectionTypeNames.FEED:
        API = APIs.DELETE_FEED_;
        break;
      case SectionTypeNames.DOCUMENT:
        API = APIs.DELETE_DOCUMENT;
        break;
      case SectionTypeNames.TASKER:
        API = APIs.DELETE_TASKBOARD;
        break;
    }
    if (API) {
      const result: any = await exiosPrivate.post(API, {
        entity_id: file.id,
        root_id,
      });

      if (result && result.data.status === "Success") {
        updateTree(result.data.data);
        resetEverything();
        navigateToMain()
      } else {
        setError("Something went wrong");
      }
    }
  };

  return (
    <div>
      {loader ? (
        <SpinnerContainer height="430px" />
      ) : (
        <div>
            <Stack padding="1rem" paddingTop={0}>
             <Grid xs={12} sm={12} md={12} lg={12} mt="1rem">
                <Typography fontWeight={500}>
                  Are you sure you want to delete "{file.name}"?
                </Typography>
              </Grid>
              <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                >
                <FormErrorWithTimeout
                    clearError={() => setError("")}
                    text={error}
                />
                <Button
                    onClick={deleteFolder}
                    size="md"
                    fullWidth
                    variant="solid"
                    color="primary"
                >
                    Confirm & Delete 
                </Button>
                </Grid>
            </Grid>
            </Stack>
            </div>
      )}
    </div>
  );
};

export default DeleteSection;
