const PATHS = {
  home: "/",
  signin: "/signin",
  signup: "/signup",
  fourOfour: "/*",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:code",

  user: "/user",

  organization: "/organization",

  invitationReceive: "/invitation/:org/:code/:id",

  notes: "/notes",
  pins: "/pins",

  team: "/team/:id/:subType/:subId",
  workspace: "/workspace/:id/:subType/:subId",

  space: "/space/:id/:subType/:subId",

  dataset: "/dataset/:id",
};
export default PATHS;
