import React, { useState, useEffect } from "react";

import { AuthContextProp } from "../../context/AuthProvider";

import { exiosPrivate } from "../../api/axios";
import {
  SetObjectWithKey,
  storePersistInLocalStorage,
} from "../../utilities/sharedUtilties";
import { APIs } from "../../constants/apis";
import { LOGS } from "../../constants/Logs";
import Names from "../../constants/names";
import { useNavigate } from "react-router-dom";
import PATHS from "../../constants/paths";
import { useAuth } from "../../utilities/hooks/useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Button,
  Link,
  Input,
  Checkbox,
  Card,
  Grid,
  CardContent,
  FormControl,
  FormLabel,
  CardActions,
  Stack,
} from "@mui/joy";
import { Header } from "../../components/header";
import { FormCard } from "../../components/cards/formCard";
import { FormErrorWithTimeout } from "../../components/cards/formError";

export const Signin = () => {
  const [passwordVisibility, togglePasswordVisibility] = useState(false);
  const { setAuth, persist, setPersist } = useAuth() as AuthContextProp;
  const [error, setError] = useState("");

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = data;

  const navigate = useNavigate();

  const login = async () => {
    if (email || password) {
      try {
        const response = await exiosPrivate.post(APIs.AUTH_LOGIN, {
          username: email,
          password,
        });
        const accessToken = response?.data?.token;
        const name = response?.data?.name;
        const roles = response?.data?.roles;
        setAuth({ email, roles, accessToken, name });
        navigate(PATHS.home);
      } catch (error) {
        setError("Invalid Credentials");
        console.log(LOGS.LOGIN_ERROR, error);
      }
    }
  };
  const togglePersist = () => {
    setPersist((prev: any) => !prev);
  };

  useEffect(() => {
    storePersistInLocalStorage(persist);
  }, [persist]);

  useEffect(() => {
    storePersistInLocalStorage(true);
  }, []);

  return (
    <div>
      <Header>
        <Button
          className="header-button carrot"
          color="primary"
          onClick={() => navigate(PATHS.signup)}
        >
          Create a new account
        </Button>
      </Header>
      <div className="body">
        <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={1}>
          <Grid xs></Grid>
          <Grid xs={10} sm={6} md={4} lg={3}>
            <FormCard title="Signin to Adaily">
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(event) =>
                    setData(SetObjectWithKey(data, "email", event.target.value))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  endDecorator={
                    passwordVisibility ? (
                      <Visibility
                        onClick={() =>
                          togglePasswordVisibility((prev) => !prev)
                        }
                        sx={{ color: "#a3a3a3" }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={() =>
                          togglePasswordVisibility((prev) => !prev)
                        }
                        sx={{ color: "#a3a3a3" }}
                      />
                    )
                  }
                  value={password}
                  type={passwordVisibility ? "text" : "password"}
                  placeholder="Password"
                  onChange={(event) =>
                    setData(
                      SetObjectWithKey(data, "password", event.target.value)
                    )
                  }
                />
              </FormControl>
              <Grid mt={1} container spacing={2}>
                <Grid xs={6}>
                  <Checkbox
                    defaultChecked
                    onChange={togglePersist}
                    label={Names.persistText}
                  />
                </Grid>
                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  xs={6}
                >
                  <Stack alignItems="flex-end" justifyContent="center">
                    <Link onClick={() => navigate(PATHS.forgotPassword)}>
                      Forgot Password?
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
              <FormErrorWithTimeout
                clearError={() => setError("")}
                text={error}
              />
              <Button onClick={login} variant="solid" color="primary">
                  Sign in
                </Button>
            </FormCard>
          </Grid>
          <Grid xs></Grid>
        </Grid>
      </div>
    </div>
  );
};
