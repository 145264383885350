import React from "react";
import { Typography } from "@mui/joy";
import { textIconCreator } from "../../../utilities/sharedUtilties";
import "../index.scss";

export const CubicalHeader = ({ children }:any) => {
    return <div className='cube-header'>{children}</div>
}

const CubicalTitle = ({ icon, label }: any) => {
    return (
        <div className='cube-header-title'>
            <div className="flex-center">
                {icon !== "" ? (
                    <img src={icon} style={{ width: "1.3rem" }} alt={label} />
                ) : (
                    textIconCreator(label ?? "Document", "l")
                )}
            </div>
            <Typography className="flex-center" display="flex" fontSize={16} fontWeight={500}>{label}</Typography>
        </div>
    );
}

const CubicalAction = ({ children }: any) => {
    return <div className='cube-header-action'>
        {children}
    </div>
}

CubicalHeader.title = CubicalTitle;
CubicalHeader.action = CubicalAction;

