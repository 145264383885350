import React, { createContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { HomeWrapper } from "../pages/Home/HomeWrapper";
import MainHome from "../pages/Home/MainHome/MainHome";
import { Main } from "../pages/Home/MainHome/Main";
import { Wrapper } from "../pages/Home/MainHome/Wrapper";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchUserAsync, User } from "../redux/reducers/User";
import useExiosPrivate from "../utilities/hooks/useAxiosPrivate";

export const HomeLayout = ({ children }: any) => {
  const user: any = useAppSelector(User);

  const dispatch = useAppDispatch();
  const exiosPrivate = useExiosPrivate();

  useEffect(() => {
    dispatch(fetchUserAsync(exiosPrivate))
  }, []);


  return (
    <HomeWrapper>
      <MainHome>
        <Main user={user}>
          <Wrapper user={user}>{children || <Outlet />}</Wrapper>
        </Main>
      </MainHome>
    </HomeWrapper>
  );
};
