import { useCallback, useEffect, useState } from "react";
import { BubbleMenu, useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from "@tiptap/extension-text";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Blockquote from "@tiptap/extension-blockquote";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import ImageResize from 'tiptap-extension-resize-image';
import { TipTapMenuActions } from "./TiptapTypes";
import { BubbleMenuDocumentWrapper } from "./Menus/BubbleMenuDocumentWrapper";
import { FloatingMenuWrapper } from "../Sections/Documents/components/TextEditor/FloatingMenuWrapper";



export const TiptapWrapperForDocument = ({
    content,
    updateDocumentContent,
    readOnly = false,
    placeholder = "",
    currentContent,
    emojiContent
}: any) => {
  const extensions = [
    StarterKit,
    Document,
    Paragraph,
    Image,
    ImageResize,
    Text,
    Table.configure({
      resizable: true,
      allowTableNodeSelection: true,
      cellMinWidth: 150,
      handleWidth: 5
    }),
    TableRow,
      TableHeader,
      TableCell,
    Link.configure({
      openOnClick: true,
    }),
    Color,
    TextStyle,
    Highlight.configure({
      multicolor: true,
    }),
    Blockquote,
    Underline,
    TextAlign.configure({
      types: ["heading", "paragraph", "image"],
    }),
    Placeholder.configure({
      placeholder,
    }),
  ];
  const editor = useEditor({
    extensions: extensions,
    content: "",
    onUpdate: ({ editor }) => {
      if (content !== editor.getHTML()) {
        if (editor.getHTML() !== "<p></p>") {          
        updateDocumentContent?.(editor.getHTML())
        }
      }
    }
  });

  useEffect(() => {
    editor?.setEditable(!readOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, readOnly]);


  useEffect(() => {
    editor?.commands.setContent(content);
    editor?.commands.focus('start');
  }, [content, editor]);

  useEffect(() => {
    if (currentContent === "") {
      editor?.commands.setContent("<p></p>");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent]);

  useEffect(() => {
    editor?.commands.insertContent(emojiContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiContent]);

  const actionMenuClick = (type: string) => {
    switch (type) {
      case TipTapMenuActions.bold:
        editor?.chain().focus().toggleBold().run();
        break;

      case TipTapMenuActions.italic:
        editor?.chain().focus().toggleItalic().run();
        break;

      case TipTapMenuActions.underline:
        editor?.chain().focus().toggleUnderline().run();
        break;

      case TipTapMenuActions.strike:
        editor?.chain().focus().toggleStrike().run();
        break;

      case TipTapMenuActions.bullet:
        editor?.chain().focus().toggleBulletList().run();
        break;

      case TipTapMenuActions.ordered:
        editor?.chain().focus().toggleOrderedList().run();
        break;

      case TipTapMenuActions.code:
        editor?.chain().focus().toggleCode().run();
        break;

      case TipTapMenuActions.color_red:
        editor?.chain().focus().setColor("#ff0000").run();
        break;

      case TipTapMenuActions.color_green:
        editor?.chain().focus().setColor("#6cbe00").run();
        break;

      case TipTapMenuActions.color_blue:
        editor?.chain().focus().setColor("#09F").run();
        break;

      case TipTapMenuActions.textLeft:
        editor?.chain().focus().setTextAlign("left").run();
        break;

      case TipTapMenuActions.textCenter:
        editor?.chain().focus().setTextAlign("center").run();
        break;

      case TipTapMenuActions.textRight:
        editor?.chain().focus().setTextAlign("right").run();
        break;

      case TipTapMenuActions.block:
        editor?.chain().focus().toggleBlockquote().run();
        break;

      case TipTapMenuActions.highlight:
        editor?.chain().focus().toggleHighlight().run();
        break;

      case TipTapMenuActions.h1:
        editor?.chain().focus().toggleHeading({ level: 1 }).run();
        break;

      case TipTapMenuActions.h2:
        editor?.chain().focus().toggleHeading({ level: 2 }).run();
        break;

      case TipTapMenuActions.h3:
        editor?.chain().focus().toggleHeading({ level: 3 }).run();
        break;

      case TipTapMenuActions.h4:
        editor?.chain().focus().toggleHeading({ level: 4 }).run();
        break;

      case TipTapMenuActions.hRule:
        editor?.chain().focus().setHorizontalRule().run();
        break;
      
      case TipTapMenuActions.formatClear:
        editor?.chain().focus().unsetAllMarks().run();
        break;
      case TipTapMenuActions.deleteNode:
        editor?.commands.deleteSelection();
        break;
      case TipTapMenuActions.addLink:
        setLink()
        //editor?.chain().focus().unsetAllMarks().run();
        break;
      
      case TipTapMenuActions.color_Default:
        editor?.commands.setColor(TipTapMenuActions.color_Default)
        break;
      case TipTapMenuActions.color_Grey:
        editor?.commands.setColor(TipTapMenuActions.color_Grey)
        break;
      case TipTapMenuActions.color_Brown:
        editor?.commands.setColor(TipTapMenuActions.color_Brown)
        break;
      case TipTapMenuActions.color_Orange:
        editor?.commands.setColor(TipTapMenuActions.color_Orange)
        break;
      case TipTapMenuActions.color_Yellow:
        editor?.commands.setColor(TipTapMenuActions.color_Yellow)
        break;
      case TipTapMenuActions.color_Green:
        editor?.commands.setColor(TipTapMenuActions.color_Green)
        break;
      case TipTapMenuActions.color_Blue:
        editor?.commands.setColor(TipTapMenuActions.color_Blue)
        break;
      case TipTapMenuActions.color_Purple:
        editor?.commands.setColor(TipTapMenuActions.color_Purple)
        break;
      case TipTapMenuActions.color_Pink:
        editor?.commands.setColor(TipTapMenuActions.color_Pink)
        break;
      case TipTapMenuActions.color_Red:
        editor?.commands.setColor(TipTapMenuActions.color_Red)
        break;
      
      case TipTapMenuActions.bgColor_Default:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Default})
        break;
      case TipTapMenuActions.bgColor_Grey:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Grey})
        break;
      case TipTapMenuActions.bgColor_Brown:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Brown})
        break;
      case TipTapMenuActions.bgColor_Orange:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Orange})
        break;
      case TipTapMenuActions.bgColor_Yellow:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Yellow})
        break;
      case TipTapMenuActions.bgColor_Green:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Green})
        break;
      case TipTapMenuActions.bgColor_Blue:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Blue})
        break;
      case TipTapMenuActions.bgColor_Purple:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Purple})
        break;
      case TipTapMenuActions.bgColor_Pink:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Pink})
        break;
      case TipTapMenuActions.bgColor_Red:
        editor?.commands.setHighlight({color: TipTapMenuActions.bgColor_Red})
        break;
      case TipTapMenuActions.table:
        editor?.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
        break;
      
    }
  };

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl);

    window?.getSelection()?.collapseToEnd()

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }
    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  useEffect(() => {
    return () => {
      editor?.commands.setContent(null);
      editor?.destroy();
    };
  }, []);

  const setEmoji = (emoji: string) => {
    editor?.commands.insertContent(emoji)
  }

  const _setImage = (image: any) => {
    editor?.chain().focus().setImage({ src: image.url }).run()
  }

  const selectionType = editor?.state.selection.toJSON().type;

  return (
    <>
      <EditorContent editor={editor}></EditorContent>
      <>
        {editor && (
          <>
            <BubbleMenu editor={editor}>
              <BubbleMenuDocumentWrapper
                actionMenuClick={actionMenuClick}
                selectionType={selectionType}
              ></BubbleMenuDocumentWrapper>
            </BubbleMenu>
            <FloatingMenuWrapper
              setImage={_setImage}
              setEmoji={setEmoji}
              actionMenuClick={actionMenuClick}
              editor={editor}
            />
          </>
        )}
      </>
    </>
  );
};

export default TiptapWrapperForDocument