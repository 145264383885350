import { Button, FormControl, FormLabel, Grid, Input, Stack } from "@mui/joy";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import useExiosPrivate from "../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../constants/apis";
import IconSelector from "../../../IconSelector";
import { FormErrorWithTimeout } from "../../../cards/formError";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PATHS from "../../../../constants/paths";
import { updateUserWithNewFolderList } from "../../../../redux/reducers/User";
import { useAppDispatch } from "../../../../redux/hooks";

export enum DocumentTemplateType {
  BLANK = "",
  TODO = "todo",
}

const DocumentCreatorModal = ({ parentId, close, updateTree }: any) => {
  const { id: root_id } = useParams();
  const exiosPrivate = useExiosPrivate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({
    name: "",
    icon: "",
    parent_id: parentId,
    root_id: root_id,
    template: DocumentTemplateType.BLANK,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    setDataHandler(parentId, "parent_id");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const setDataHandler = (value: string, type: string) => {
    const _data: any = _.cloneDeep(data);
    _data[type] = value;
    setData(_data);
  };

  useEffect(() => {
    return () => {
      setData({});
      setError("");
    };
  }, []);

  const createDocument = async () => {
    if (!data.name) {
      setError("Name cannot be empty");
    }
    dispatch(updateUserWithNewFolderList(parentId));
    const response = await exiosPrivate.post(APIs.CREATE_DOCUMENT, data);
    if (response.data.status === "Success") {
      updateTree(response.data.data);
      navigate(`../space/${root_id}/${response.data.params.type}/${response.data.params.id}`)
      close();
    }
  };

  return (
    <div>
      <Stack padding="1rem" paddingTop={0}>
        <Grid mt="1rem" xs={12} sm={12} md={7} lg={12}>
            <FormControl>
              <FormLabel required>Name of the Document</FormLabel>
              <Input
                onChange={(e) => setDataHandler(e.currentTarget.value, "name")}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid mt="1rem" className="inline-icon" xs={12} sm={12} md={5} lg={5}>
            <IconSelector
              from="F"
              icon={data.icon}
              setIcon={(icon: string) => setDataHandler(icon, "icon")}
              noImg={true}
              name={data.name}
            />
          </Grid>
        <Grid mt="1rem" xs={12} sm={12} md={12} lg={12}>
          <p className="section-name">Select Template</p>
            <Grid display="flex" flexDirection="row" gap="1rem">
              <Select
                value={data.template}
                onChange={(event) =>
                  setData((prev: any) => ({
                    ...prev,
                    template: event.target.value,
                  }))
                }
                sx={{ fontSize: "0.9rem" }}
                className="full aSelect"
                defaultValue={DocumentTemplateType.BLANK}
              >
                <MenuItem value={DocumentTemplateType.BLANK}>
                  Blank Document
                </MenuItem>
              </Select>
            </Grid>
        </Grid>

        <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid
              display="flex"
              justifyContent="flex-end"
              flexDirection="column"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FormErrorWithTimeout
                clearError={() => setError("")}
                text={error}
              />
              <Button
                onClick={createDocument}
                size="md"
                fullWidth
                variant="solid"
                color="primary"
              >
                Create
              </Button>
            </Grid>
          </Grid>
      </Stack>
    </div>
  );
};

export default DocumentCreatorModal;
