import React, { useState } from "react";
import { AddOutlined } from "@mui/icons-material";
import "./index.scss";
import { SingleFileUploader } from "../SingleFileUploader";
import { SpinnerContainer } from "../SpinnerContainer";
import { Tooltip } from "@mui/joy";
import { useExiosPrivateMulti } from "../../utilities/hooks/useAxiosPrivate";
import { getMediaFileName } from "../../utilities/sharedUtilties";

const UploaderButton = ({
  addFile,
  types,
  maxSize,
  minSize,
  uploadURL,
}: any) => {
  const exiosPrivateMulti = useExiosPrivateMulti();
  const [imageLoad, toggleImageLoad] = useState(false);
  const handleImageUpload = async (file: any) => {
    try {
      toggleImageLoad(true);
      const formData = new FormData();
      formData.append("file", file);
      const result = await exiosPrivateMulti.post(uploadURL, formData);
      if (result.data.status === "success") {
        addFile({
          name: result.data.file.originalname,
          type: getMediaFileName(result.data.file.mimetype),
          url: result.data.url,
          id: result.data.id,
          extension: result.data.file.mimetype
            ? result.data.file.mimetype.split("/")[1]
            : "",
        });
      }
      toggleImageLoad(false);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <Tooltip title="Select and upload file">
      <div className="file-add-button">
        {imageLoad ? (
          <div className="uploader-load">
            <SpinnerContainer />
          </div>
        ) : (
          <SingleFileUploader
            setImage={handleImageUpload}
            maxSize={maxSize}
            minSize={minSize}
            types={types}
          >
            <AddOutlined />
          </SingleFileUploader>
        )}
      </div>
    </Tooltip>
  );
};

export default UploaderButton;
