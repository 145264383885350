import React from "react";
import "../index.scss";
import { Drawer } from "../Sidebar/Drawer";

export const Wrapper = ({ children, user }: any) => {
  return <div className="main-wrapper">
    <Drawer user={user} />
    {children}
  </div>;
};
