import { useState } from "react";
import { Typography } from "@mui/joy";
import { FeedComment } from "./FeedComment";
import { FeedMediaRenderer } from "./FeedMediaRenderer";
import { FeedReaction } from "./FeedReaction";
import { textIconCreator } from "../../../../../utilities/sharedUtilties";

const FeedSkeleton = ({
  data,
  inlineDate = false,
  userId,
  user,
  reactions: rawReactions,
  feedCardData,
  entity,
  toggleReply
}: any) => {
  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <div className="feed-wrapper">
      <div className="feed-main">
        <div>
          <div className="feed-header-title">
            <div>
              <div className="feed-left">
                <div className="feed-header-dp">
                  {
                    data.creatorId?.dp !== "" ? <img src={data.creatorId?.dp} alt={data.creatorId?.name} /> : textIconCreator("Mukur Puri", "l")
                  }
                </div>
              </div>
            </div>
            <div className="full">
              <div
                className="flex-row"
                style={{ gap: "6px", marginTop: "-5px" }}
              >
                <Typography fontWeight={500}>{data.creatorId?.name}</Typography>
                <span className="feed-header-subtitle">
                  {data.date?.createdAt}
                </span>
              </div>
              <div className="feed-main-card">
                <div
                  className="feed-header-body"
                  dangerouslySetInnerHTML={createMarkup(data.content)}
                />
                {data.media && data.media.length > 0 && (
                  <FeedMediaRenderer files={data.media} />
                )}
                <FeedReaction
                  userId={userId}
                  feedId={data.id}
                  reactions={data.reactions}
                />
                {
                  feedCardData.allow_comments && <div className="feed-comments">
                  <FeedComment
                    feed={feedCardData}
                    user={user}
                    comments={feedCardData.comments}
                    feedCardId={feedCardData.id}
                    entity={entity}
                    toggleReply={toggleReply}
                  />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedSkeleton;
