import { FeedReaction } from "./FeedReaction";
import { useEffect, useState } from "react";
import { FeedMediaRenderer } from "./FeedMediaRenderer";
import { Typography } from "@mui/joy";
import FeedCommentOptions from "./FeedCommentOptions";
import _ from "lodash";
import { textIconCreator } from "../../../../../utilities/sharedUtilties";
export const FeedComments = ({
  comments: rawComments,
  user,
  deleteContent,
}: any) => {
  const [showComments, toggleComments] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setComments(rawComments);
  }, [rawComments]);

  const updateCommentRection = (reactData: any, id: string) => {
    const newComments = _.cloneDeep(comments);
    const comment: any = newComments.find((com: any) => com.id === id);
    comment.reactions = reactData;
    setComments(newComments);
  };

  return (
    <>
      {showComments ? (
        <div id="comment-lister" className="comment-list">
          {comments.map((comment: any) => (
            <CommentWrapper
              deleteContent={deleteContent}
              user={user}
              comment={comment}
              updateCommentRection={updateCommentRection}
            />
          ))}
        </div>
      ) : (
        <CommentsExpander
          onClick={() => toggleComments(true)}
          comments={comments}
        />
      )}
    </>
  );
};

const CommentWrapper = ({
  comment,
  user,
  deleteContent,
  updateCommentRection,
}: any) => {
  const createMarkup = (content: string) => {
    return { __html: content };
  };
  const updateCommentReaction = (emoji: any, id: any) => {
    updateCommentRection(emoji, id);
  };
  return (
    <div className={`feed-secondary-item`}>
      {/* <div className="feed-sec-action">
        {comment.creatorId.id === user._id && (
          <div className="feed-delete">
            <span>
              <CloseOutlined onClick={() => deleteContent(comment.id)} />
            </span>
          </div>
        )}
      </div> */}
      <div style={{ alignItems: "flex-start" }} className="full flex-row gHalf">
        <FeedCommentOptions
          isAdmin={comment.creatorId.id === user._id}
          reactions={comment.reactions}
          updateCommentReaction={(emoji: any) =>
            updateCommentReaction(emoji, comment.id)
          }
          data={comment}
          deleteContent={deleteContent}
        />
        <div>
          <div className="feed-left">
            <div className="feed-header-dp">
              {
                comment.creatorId?.dp === "" ? textIconCreator(comment.creatorId?.name, "l") : <img src={comment.creatorId?.dp} alt={comment.creatorId?.name} />
              }
            </div>
          </div>
        </div>
        <div>
          <div className="flex-row" style={{ gap: "6px", marginTop: "-5px" }}>
            <Typography fontWeight={500}>{comment.creatorId?.name}</Typography>
            <span className="feed-header-subtitle">
              {comment.date?.createdAt}
            </span>
          </div>
          <div className="feed-main-card">
            <div
              className="feed-header-body"
              dangerouslySetInnerHTML={createMarkup(comment.content)}
            />
            {comment.media && comment.media.length > 0 && (
              <FeedMediaRenderer files={comment.media} />
            )}
            <FeedReaction
              userId={comment.creatorId.id}
              feedId={comment.id}
              reactions={comment.reactions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CommentsExpander = ({ comments, onClick }: any) => {
  const commentCreators = comments.map((comment: any) => comment.creatorId);
  return (
    <div onClick={onClick} className="comment-expander">
      <div className="img-text">View {commentCreators.length} comments</div>
    </div>
  );
};
