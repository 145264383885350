import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppLayout } from "../layouts/app";
import Pages from "../pages";
import PATHS from "../constants/paths";
import PersistLogin from "../utilities/hooks/usePersistLogin";
import { HomeLayout } from "../layouts/home";
import { useAuthRefresher } from "../utilities/hooks/useAuthRefresher";
import SomethingWrong from "../components/SomethingWrong/SomethingWrong";

export const AppRouter = () => {
  const { isRefreshing, loggedIn } = useAuthRefresher();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATHS.home} element={<AppLayout loggedIn={loggedIn} />}>
          {isRefreshing && (
            <Route element={<Pages.PageLoadingScreen />}></Route>
          )}

          {/* PUBLIC ROUTES */}
          {!loggedIn && !isRefreshing && (
            <Route>
              <Route path={PATHS.home} element={<Pages.Landing />} />
              <Route path={PATHS.signup} element={<Pages.Signup />} />
              <Route path={PATHS.signin} element={<Pages.Signin />} />
              <Route
                path={PATHS.forgotPassword}
                element={<Pages.ForgotPassword />}
              />
              <Route
                path={PATHS.invitationReceive}
                element={<Pages.InvitationReceiver />}
              />
              <Route
                path={PATHS.resetPassword}
                element={<Pages.ResetPassword />}
              />
            </Route>
          )}
          {/* PRIVATE ROUTE */}
          {loggedIn && !isRefreshing && (
            <Route element={<PersistLogin />}>
              <Route path={PATHS.home} element={<HomeLayout />}>
                <Route path={PATHS.home} element={<Pages.Home />} />
                <Route path={PATHS.space} element={<Pages.Space />} />
              </Route>
            </Route>
          )}

          {!isRefreshing && (
            <Route
              path={PATHS.fourOfour}
              element={
                <div>
                  <SomethingWrong />
                </div>
              }
            />
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
