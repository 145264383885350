import React, { useEffect, useState } from 'react'
import { Autocomplete, AutocompleteOption, Avatar, Button, ListItemContent, ListItemDecorator, Typography } from '@mui/joy'
import useExiosPrivate from '../../../utilities/hooks/useAxiosPrivate';
import { APIs } from '../../../constants/apis';
import { Grid } from '@mui/joy';
import SelectMembersByName from '../../AddMembers/SelectMembersByName';
import { textIconCreator } from '../../../utilities/sharedUtilties';
import { FormErrorWithTimeout } from '../../cards/formError';

const EditPrivateMembers = ({ folder, close }: any) => {
    const exiosPrivate = useExiosPrivate();
    const [members, setMembers] = useState([]);
    const [error, setError] = useState("");
    const [memberSelectorFor, setMemberSelectorFor] = useState("me");

    const selectMemberType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMemberSelectorFor(event.target.value);
    };

    const fetchFolderMembers = async (folderId: string) => {
        try {
            const result = await exiosPrivate.post(`${APIs.GET_SPACE_FOLDER_MEMBER_DIFF}`, { folderId });
            if (result && result.data.data && result.data.data.length > 0) {
                setMembers(result.data.data);
            }
        }
        catch (err) {
            console.log("err", err);
        }
    }

    const saveConfiguration = async () => {
        const result: any = await exiosPrivate.post(APIs.FOLDER_SAVE_MEMBER_CONFIGURATION, {
            memberList: [ ...members ],
            folderId: folder.id
        });
        if (result && result.data.status === "Success") {
            close();
        } else {
        setError("Something went wrong");
        }
    }

    const configureMembers = (event: any, value: any) => {
        const memberList = value.map((mem: any) => mem.id);
        const mainMemberList = [...members];
        mainMemberList.forEach((member: any) => {
            member.selected = memberList.includes(member.id);
        })
        setMembers(mainMemberList);
    }

    useEffect(() => {
        fetchFolderMembers(folder.id)
    }, [folder]);
  return (
      <div>
          <Grid xs={12} sm={12} md={12} lg={12} mt="1rem" p="1rem" pt="0">
            <Typography fontWeight={500} sx={{marginBottom: "0.5rem"}}>Members</Typography>
            <Autocomplete
                onChange={(event, value) => configureMembers(event,value)} // prints the selected value
                placeholder="Search Member"
                multiple
                slotProps={{
                    input: {
                    autoComplete: 'new-password', // disable autocomplete and autofill
                    },
                    }}
                value={members.filter((member:any) => member.selected === true)}
                options={members}
                autoHighlight
                getOptionLabel={(option:any) => option.name}
                renderOption={(props:any, option:any) => (
                <AutocompleteOption {...props}>
                <ListItemDecorator>
                    {option.dp !== "" ? (
                        <Avatar
                        sx={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                        }}
                        src={option.dp}
                        />
                    ) : (
                        textIconCreator(option.name, "l")
                    )}
                </ListItemDecorator>
                <ListItemContent sx={{ fontSize: 'md' }}>
                    <Typography sx={{fontWeight: "500"}}>{option.name}</Typography>
                </ListItemContent>
                </AutocompleteOption>
                    )}
        />
          </Grid>
            <Grid mt="1rem" container m="1rem">
                <FormErrorWithTimeout
                    clearError={() => setError("")}
                    text={error}
                />
                <Grid
                  display="flex"
                  justifyContent="flex-end"
                  sx={{width: "100%"}}
                >
                <Button
                    onClick={saveConfiguration}
                    size="md"
                    variant="solid"
                    color="primary"
                >
                    Apply Changes 
                </Button>
                </Grid>
            </Grid>
    </div>
  )
}

export default EditPrivateMembers