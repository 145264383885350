import React, { useState, useEffect } from "react";
import "../index.scss";
import { Typography } from "@mui/joy";
import { AModal } from "../../../components/modal/AModal";
import { OrganizationStep } from "../../Hello/OnBoarding/Organization";
import Names from "../../../constants/names";
import { UserType } from "../../../redux/reducers/User.types";
import { initialState } from "../../../redux/reducers/User";

export const OrgnizationLogo = ({ userData  }: any) => {
  const [showSetOrganization, toggleShowSetOrganization] =
    useState<Boolean>(false);
  const [showOnBoardingSetOrganization, setShowOnBoardingSetOrganization] =
    useState(false);
  const [user, setUser] = useState<UserType>(initialState.data);


  useEffect(() => {
    if (userData.loaded) {
      setUser(userData.data);
      // if (userData.data.organization.id !== "") {
      //   setShowOnBoardingSetOrganization(true);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.data]);

  const refreshUser = () => {
    console.log("Refresh user");
  }

  let SetOrganizationModal = (
    <AModal
      onClose={() => {
        toggleShowSetOrganization(false);
        setShowOnBoardingSetOrganization(false);
      }}
      title="Set your Organization"
      size="sm"
    >
      <OrganizationStep
        refreshUser={refreshUser}
        onClose={() => setShowOnBoardingSetOrganization(false)}
        user={user}
        src="drawer"
      />
    </AModal>
  );

  if (showOnBoardingSetOrganization === false) {
    SetOrganizationModal = <></>;
  } else {
    if (localStorage.getItem(Names.persistSkipOrg) === "true") {
      SetOrganizationModal = <></>;
    }
  }

  const editOrg = () => {
    // if (user.organization.isAdmin) {
    //   toggleShowSetOrganization((prev: Boolean) => !prev);
    // }
  };


  return (
    <>
      <div className="org-logo-container">
        {SetOrganizationModal}
        <div onClick={editOrg} className="adaily-logo">
          <span style={{ gap: "0.5rem" }} className="flex-row">
            <img
              src={"../../../LOGO.svg"}
              alt={`Adaily logo`}
            />
            <Typography
              sx={{
                overflow: "overlay",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflowX: "hidden",
                textAlign: "left",
              }}
              fontWeight={600}
              fontSize="0.9rem"
            >
              {" "}
              Adaily
            </Typography>
          </span>
        </div>
      </div>
      {showSetOrganization && (
        <AModal
          onClose={() => toggleShowSetOrganization(false)}
          title={
            user.organization.id === ""
              ? `Set Organization`
              : `Update Organization`
          }
          size="sm"
        >
          <OrganizationStep
            refreshUser={refreshUser}
            user={user}
            src="header"
            onClose={() => toggleShowSetOrganization(false)}
          />
        </AModal>
      )}
    </>
  );
};
