import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";
import { useNavigate, useParams } from 'react-router-dom';
import useExiosPrivate from '../../utilities/hooks/useAxiosPrivate';
import { fetchSpaceAsync, Space as SpaceData, updateTreeForSpace } from '../../redux/reducers/Space/Space';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import SomethingWrongCustom from '../../components/SomethingWrong/SomethingWrongCustom';
import { Stack, Typography } from '@mui/joy';
import SpaceSidebar from './Sidebar';
import "./index.scss";
import { SectionBoard } from '../../components/ContextMenu/Section';

import { User, updateWorkspaceActiveEntityhNewWorkspaceSettings } from '../../redux/reducers/User';
import { APIs } from '../../constants/apis';

export const Space = () => {
  const { id, subType, subId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: user }:any = useAppSelector(User);

  const exiosPrivate = useExiosPrivate();
  const space: any = useAppSelector(SpaceData);
  const [fileTitle, setFileTitle] = useState("Adaily Workspace");

  useEffect(() => {
    dispatch(fetchSpaceAsync({
      exiosPrivate,
      id
    }))
  }, [id]);

  const updateTree = (data: any): any => {
    dispatch(updateTreeForSpace(data))
  }

  const navigateToPage = (file: any) => {
    setFileTitle(file.name + " : " + space.data.name);
    navigate(`/space/${id}/${file.type}/${file.id}`);
    dispatch(updateWorkspaceActiveEntityhNewWorkspaceSettings({
      exiosPrivate,
      id,
      file
    }));

    exiosPrivate.post(APIs.SET_USER_WORKSPACE_ENTITY, { entityType: file.type, entityId: file.id, workspaceId: id })
  }

  const navigateToMain = () => {
    navigate(`../../space/${id}/home/main`)
  }

  const refreshSpace = () => {
    dispatch(fetchSpaceAsync({
      exiosPrivate,
      id
    }))
  }

  return (
    <div className="section-app">
      <Helmet>
        <title>{fileTitle}</title>
      </Helmet>
      {space.error ? (
        <div className="full">
          <SomethingWrongCustom
            title="Workspace not found"
            subTitle="Workspace might have been deleted, contact Admin"
          />
        </div>
      ) : (
          <div className="card-app">
          {space.loading && <div className="loader" />}
          {!space.access ? (
              <PrivateSpace entityText="Workspace" entity={space} />
          ) : (
                <>
                  <SpaceSidebar
                    space={space.data}
                    loader={space.loader}
                    navigate={navigate}
                    openFile={navigateToPage}
                    updateTree={updateTree}
                    navigateToMain={navigateToMain}
                    user={user}
                    refreshSpace={refreshSpace}
                  />
              <SectionBoard
                subType={subType}
                subId={subId}
                navigate={navigate}
                entity={space}
                entityText="space"
              /> 
            </>
          )}
        </div>
      )}
      </div>
  );
}

export const PrivateSpace = ({ entity, entityText }: any) => {
  return (
    <>
      <div style={{ paddingLeft: 0 }} className="cube flex-center-full">
        <div>
          <Stack display="flex" alignItems="center">
            <img style={{ width: "60px" }} src={entity.icon} alt="team-icon" />
            <Typography fontSize={20} fontWeight={600} mt={1}>
              {entity.name}
            </Typography>
            <Typography fontSize={20}>is a Private {entityText}</Typography>
          </Stack>
        </div>
      </div>
    </>
  );
};