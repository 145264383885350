import { useEffect, useState } from "react";
import { useRefreshToken } from "./useRefresh";
import { useAuth } from "./useAuth";
import { AuthContextProp } from "../../context/AuthProvider";

export const useAuthRefresher = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth() as AuthContextProp;

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isRefreshing: isLoading,
    loggedIn: auth.accessToken,
  };
};
