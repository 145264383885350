export const sidebarList: sidebarListType = [
  {
    name: "My",
    items: [
      {
        name: "Workspace",
        icon: "file_folder",
        link: "",
        active: true,
      },
      {
        name: "Notes",
        icon: "newspaper",
        link: "/notes",
        active: false,
      },
      {
        name: "Pins",
        icon: "pushpin",
        link: "/pins",
        active: false,
      },
    ],
  },
  {
    name: "Teams",
    items: [
      {
        name: "All",
        icon: "earth_asia",
        link: "/organization",
        active: false,
      },
    ],
  },
];
export type sidebarListType = sidebarListItems[];

export interface sidebarListItems {
  name: string;
  items: sidebarItem[];
}
export interface sidebarItem {
  name: string;
  icon: string;
  link: string;
  active: boolean;
}
