import { AuthContextProp } from "../../context/AuthProvider";
import { useAuth } from "./useAuth";

import { APIs } from "../../constants/apis";
import { useNavigate } from "react-router-dom";
import PATHS from "../../constants/paths";
import useExiosPrivate from "./useAxiosPrivate";
import Names from "../../constants/names";

const useLogout = () => {
  const exiosPrivate = useExiosPrivate();

  const { setAuth } = useAuth() as AuthContextProp;
  const navigate = useNavigate();
  const logout = async () => {
    setAuth({});
    try {
      await exiosPrivate.post(APIs.AUTH_LOGOUT, {
        withCredentials: true,
      });

      navigate(PATHS.signin);
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
