import { exiosPrivate, exiosPrivateMulti } from "../../api/axios";
import { useEffect } from "react";
import { useRefreshToken } from "./useRefresh";
import { useAuth } from "./useAuth";
import { AuthContextProp } from "../../context/AuthProvider";

const useExiosPrivate = () => {
  const { auth } = useAuth() as AuthContextProp;
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = exiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = exiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config?.sent;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return exiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      exiosPrivate.interceptors.response.eject(responseIntercept);
      exiosPrivate.interceptors.request.eject(requestIntercept);
    };
  }, [auth, refresh]);

  return exiosPrivate;
};

const useExiosPrivateMulti = () => {
  const { auth } = useAuth() as AuthContextProp;
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = exiosPrivateMulti.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = exiosPrivateMulti.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config?.sent;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return exiosPrivateMulti(prevRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      exiosPrivateMulti.interceptors.response.eject(responseIntercept);
      exiosPrivateMulti.interceptors.request.eject(requestIntercept);
    };
  }, [auth, refresh]);

  return exiosPrivateMulti;
};
export default useExiosPrivate;
export { useExiosPrivateMulti };
