import { useState } from "react";
import { DeleteOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/joy";
import { EmojiSelectButton } from "./FeedReaction";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";
import { DeletePost } from "./Feed";
import { AModal } from "../../../../modal/AModal";

const FeedCommentOptions = ({
  feedCardData,
  isAdmin,
  setFeed,
  data,
  updateCommentReaction,
  reactions,
  deleteContent,
}: any) => {
  const exiosPrivate = useExiosPrivate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [reactionInProgress, toggleReactionProgress] = useState(false);
  const [showEmojiContainer, toggleEmojiContainer] = useState(false);

  const defaultEmojis = [
    {
      code: "2705",
      title: "Completed",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2705.png",
    },
    {
      code: "1f44d",
      title: "Like",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44d.png",
    },
    {
      code: "1f197",
      title: "Confirmative",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f197.png",
    },
    {
      code: "2795",
      title: "Agreed",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2795.png",
    },
    {
      code: "1f923",
      title: "Laugh",
      src: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f923.png",
    },
  ];

  const setEmoji = async (emoji: any) => {
    const ifReactionExists = reactions.find(
      (reaction: any) => reaction.code === emoji.code
    );
    if (!ifReactionExists) {
      const result = await exiosPrivate.post(APIs.CONTENT_ADD_FEED_REACTION, {
        feedId: data.id,
        reactionId: emoji.code,
      });
      if (result.data.status === "Success") {
        updateCommentReaction(result.data.data);
      }
    }
  };

  const selectEmoji = async (emoji: any) => {
    const ifReactionExists = reactions.find(
      (reaction: any) => reaction.code === emoji.unified
    );
    if (!ifReactionExists) {
      try {
        toggleReactionProgress(true);
        setEmoji(emoji);
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <div className="feed-comment-option">
      <div className="feed-option-menu-container">
        {defaultEmojis.map((emojiO: any) => (
          <div onClick={() => setEmoji(emojiO)} className="emoji-btn">
            <Tooltip title={emojiO.title}>
              <img src={emojiO.src} alt={emojiO.title} />
            </Tooltip>
          </div>
        ))}

        <div className="emoji-btn feed-emoji-picker">
          <EmojiSelectButton
            title="Add Reaction"
            showEmojiContainer={showEmojiContainer}
            toggleEmojiContainer={toggleEmojiContainer}
            selectEmoji={selectEmoji}
          />
        </div>
      </div>
      {isAdmin && (
        <div className="feed-option-menu-container">
          <div className="emoji-btn reply-emoji-btn">
            <Tooltip title="Delete this comment">
              <span>
                <DeleteOutline onClick={() => setDeleteModal(true)} />
              </span>
            </Tooltip>
          </div>
        </div>
      )}

      {deleteModal && (
        <AModal title="Delete" onClose={() => setDeleteModal(false)}>
          <DeletePost
            title="Are you sure you want to delete this comment"
            deleteContent={deleteContent}
            id={data.id}
            close={() => setDeleteModal(false)}
          />
        </AModal>
      )}
      {/* <StarBorderOutlined /> */}
    </div>
  );
};

export default FeedCommentOptions;
