import React from "react";
import { AModal } from "../modal/AModal";
import { SectionTypeNames } from "../Sections/Renderer";
import FeedCreatorModal from "./SubSections/Forums/Feed/FeedCreatorModal";
import DocumentCreatorModal from "./SubSections/Documents/DocumentCreatorModal";
import TodoCreatorModal from "./SubSections/Todo/TodoCreatorModal";

const SectionCreatorModal = ({ onClose, type, parentId, updateTree }: any) => {
  let render = <></>;
  let size = "";
  switch (type) {
    case SectionTypeNames.FEED:
      size = "sm";
      render = (
        <FeedCreatorModal
          updateTree={updateTree}
          parentId={parentId}
          close={onClose}
        />
      );
      break;

    case SectionTypeNames.DOCUMENT:
      size = "sm";
      render = (
        <DocumentCreatorModal
          updateTree={updateTree}
          parentId={parentId}
          close={onClose}
        />
      );
      break;
    case SectionTypeNames.TASKER:
      size = "sm";
      render = (
        <TodoCreatorModal
          updateTree={updateTree}
          parentId={parentId}
          close={onClose}
        />
      );
      break;
  }
  return (
    <AModal size={size} onClose={onClose} title={`Create ${type}`}>
      {render}
    </AModal>
  );
};

export default SectionCreatorModal;
