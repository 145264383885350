import { useState } from "react";
import { ImageViewerThumbnail } from "../../../../../components/FileAdder";
import MediaViewer from "../../../../../components/MediaViewer/MediaViewer";

export const FeedMediaRenderer = ({ files }: any) => {
  const [mediaViewer, toggleMediaViewer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const openMediaviewer = (index: number) => {
    toggleMediaViewer(true);
    setActiveIndex(index);
  };
  if (files.length > 0) {
    return (
      <div className="media-file-container-thu">
        {mediaViewer && (
          <MediaViewer
            onClose={() => toggleMediaViewer(false)}
            defaultActiveIndex={activeIndex}
            files={files}
          />
        )}
        {files.map((file: any, index: number) => (
          <ImageViewerThumbnail
            setActiveIndex={(index: number) => openMediaviewer(index)}
            activeIndex={index}
            file={file}
            showRemoveButton={false}
            dimensions={{
              width: files.length === 1 ? "100%" : "216px",
              height: files.length === 1 ? "300px" : "137px",
            }}
          />
        ))}
      </div>
    );
  }
  return <></>
};
