import React, { useEffect, useState } from "react";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../constants/apis";
import _ from "lodash";

const useDocument = ({ id }: any) => {
  const exiosPrivate = useExiosPrivate();
  const [documentId, setDocumentId] = useState(id);
  const [document, setDocument] = useState<any>({});
  const [documentLoading, setDocumentLoading] = useState(false);

  useEffect(() => {
    setDocumentId(id);
  }, [id])

  const fetchDocument = async () => {
    setDocumentLoading(true);
    try {
      const result = await exiosPrivate.get(
        `${APIs.GET_DOCUMENT}?id=${documentId}`
      );
        setDocument(result.data.data);
        setDocumentLoading(false)
      } catch (err) {
      setDocumentLoading(false);
      console.log("err", err);
    }
  };

  const updateDocumentContent = async (content: any) => {
    if (!document.readOnly || !documentLoading) {
      debouncedContentUpdate({ content, documentId });
    }
  };

  const debouncedContentUpdate = React.useRef(
    _.debounce(async (data: any) => {
      try {
        await exiosPrivate.post(`${APIs.UPDATE_DOCUMENT}`, {
          id: data.documentId,
          content: data.content,
        });
      } catch (err) {
        console.log("err", err);
      }
    }, 500)
  ).current;

  useEffect(() => {
    fetchDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  return { document, updateDocumentContent, documentLoading, fetchDocument };
};

export default useDocument;
