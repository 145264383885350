import React from "react";
import { ArticleOutlined, BlurOnOutlined, CategoryOutlined, ChecklistOutlined, DashboardCustomizeOutlined, FormatListBulletedOutlined, Grid4x4Outlined, ListAltOutlined } from "@mui/icons-material";
import { ContextMenuItem, Submenu } from "rctx-contextmenu";
import { Divider } from "@mui/joy";
import "./index.scss";
import FeedMenu from "./SubSections/Forums/Feed/Feed";
import { ContextMenuTitle } from "./SpaceTree";
import { SectionTypeNames } from "../Sections/Renderer";
const SectionsSubMenu = ({ parentId, openCreator, updateTree }: any) => {
  return (
    <ContextMenuItem className="submreged-submenu">
      <Submenu title="">
        {/* <FeedMenu
          parentId={parentId}
          openCreator={openCreator}
          updateTree={updateTree}
        />
        <Divider /> */}
        <ContextMenuItem onClick={() => openCreator(SectionTypeNames.DOCUMENT)}>
          <ContextMenuTitle>
            <ArticleOutlined sx={{ fontSize: 17 }} />
            <span>
              <span>Document</span>
            </span>
          </ContextMenuTitle>
        </ContextMenuItem>
        <Divider />
        <ContextMenuItem onClick={() => openCreator(SectionTypeNames.TASKER)}>
          <ContextMenuTitle>
              <ChecklistOutlined sx={{ fontSize: 17 }} />
              <span>
                <span>Tasker</span>
              </span>
          </ContextMenuTitle>
        </ContextMenuItem>
        {/* <Divider />
        <ContextMenuItem onClick={() => openCreator(SectionTypeNames.TASKER)}>
          <ContextMenuTitle>
              <Grid4x4Outlined sx={{ fontSize: 17 }} />
              <span>
                <span>Canvas</span>
              </span>
          </ContextMenuTitle>
        </ContextMenuItem> */}
        {/* <Divider />
        <ContextMenuItem onClick={() => openCreator(SectionTypeNames.DOCUMENT)}>
          <ContextMenuTitle>
                <ListAltOutlined sx={{ fontSize: 17 }} />
                <span>
                  <span>Task Board</span>
                </span>
          </ContextMenuTitle>
        </ContextMenuItem> */}
      </Submenu>

      <ContextMenuTitle>
        <CategoryOutlined sx={{ fontSize: 17 }} />
        <span>
          <span>New</span>
        </span>
      </ContextMenuTitle>
    </ContextMenuItem>
  );
};

export default SectionsSubMenu;
