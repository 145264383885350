import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { isPageActive } from '../../../../utilities/sharedUtilties';
import { LooksOutlined } from '@mui/icons-material';
import { AModal } from '../../../../components/modal/AModal';
import CreateSpaceList from './CreateSpaceList';
import useExiosPrivate from '../../../../utilities/hooks/useAxiosPrivate';
import { APIs } from '../../../../constants/apis';

const SpaceLister = ({ user }: any) => {
    const { id } = useParams();
    if (!user.drawerData || user.drawerData.length === 0) return <></>;
    return (
        <ul className='space-list'>
            {user.drawerData.map((space: any) => (
                <RenderSpace
                    space={space}
                    workspaceSettings={user.preferences.workspaceSettings}
                />
            ))}
        </ul>
    );
};

const RenderSpace = ({ space, workspaceSettings }: any) => {
    const [showSubSpace, setShowSubSpace] = useState(false);
    const exiosPrivate = useExiosPrivate();
    const navigate = useNavigate();

    const navigateToPage = (space: any) => {
        const activeWorkspace = workspaceSettings.find((workspace: any) => workspace.id === space.id);
        let entity = {
            type: null,
            id: null,
        };
        if (activeWorkspace) {
            entity.id = activeWorkspace.activeEntity;    
            entity.type = activeWorkspace.activeEntityType;    
        }
        console.log("space", space.name, activeWorkspace);
        if (entity.id) {
            navigate(`space/${space.id}/${entity.type}/${entity.id}`)
        } else {
            navigate(`space/${space.id}/home/main`);
        }
        exiosPrivate.post(APIs.SET_USER_WORKSPACE_ACTIVE, { workspaceId: space.id })
    }
    
    return (
        <>
            <li onClick={() => navigateToPage(space)} key={space.id}
            className={`${isPageActive(window.location.pathname, space.id) && "active"
                    } ${space.spaces.length > 0 && "isTree"}`}>
                <div className='space-name'>
                <span className='flex-center-vertical'>
                    {space.icon !== "" ? (
                        <img
                            src={space.icon}
                            style={{ width: "1.1rem" }}
                            alt="Workspace logo"
                        />
                    ) : (
                        <LooksOutlined sx={{ fontSize: "1.2rem", color: "#9e9e9e" }} />
                    )}
                </span>
                    <div className='flex-center-vertical space-action'>{space.name}
                    </div>
                </div>
            </li>
            {
                space.spaces.length > 0 && <SpaceLister spaces={space.spaces} />
            }
            {showSubSpace &&
                <AModal title={`Sub Space for "${space.name}"`} onClose={() => setShowSubSpace(false)}>
                    <CreateSpaceList
                        onClose={() => setShowSubSpace(false)}
                        parentId={space.id}
                    />
                </AModal>
            }
        </>
    );
};

export default SpaceLister