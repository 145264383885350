import React, { useEffect, useState } from "react";
import { Tooltip, Typography, Checkbox } from "@mui/joy";
import "../index.scss";
import {
  AssistantOutlined,
  FilePresentOutlined,
  SendRounded,
  TaskAlt,
} from "@mui/icons-material";
import FeedFileAdder from "./FeedCreatorComponent/FeedFileAdder";
import { APIs } from "../../../../../constants/apis";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { EmojiSelectButton } from "./FeedReaction";
import { SimpleTiptapWrapper } from "../../../Documents/components/TextEditor/TiptapWrapper";

const FeedCreator = ({ entity, user, id, refreshFeed }: any) => {
  const exiosPrivate = useExiosPrivate();

  const [showFileUploader, setShowFileUploader] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showEmojiContainer, setShowEmojiContainer] = useState(false);
  const [emojiContent, setEmojiContent] = useState("");

  const [feedUploading, setFeedUploading] = useState(false);
  const [feedError, setFeedError] = useState("");

  const [newFeed, setNewFeed] = useState({
    content: "",
    media: [],
    allowComments: true,
  });

  const clearFiles = () => {
    setShowFileUploader(false);
    setNewFeed((prev: any) => ({ ...prev, media: [] }));
  };

  const post = async () => {
    if (!newFeed.content || newFeed.content === "") {
      setFeedError("Update cannot be empty");
    } else {
      setFeedUploading(true);
      try {
        const res = await exiosPrivate.post(APIs.CONTENT_POST, {
          ...newFeed,
          parentId: id,
        });
        if (res.data.data && res.data.status === "Success") {
          refreshFeed(res.data.data.feedItems);
        }
      } catch (err) {
        console.log("err", err);
      }
      setNewFeed({
        content: "",
        media: [],
        allowComments: true,
      });
      setFeedUploading(false);
    }
  };

  useEffect(() => {
    if (enterPressed) {
      post();
      setEnterPressed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed]);

  const selectEmoji = (emoji: any) => {
    setEmojiContent(emoji.emoji);
    setShowEmojiContainer(false);
  };

  return (
    <div className="post-creator">
      <div className="option-container">
        {showFileUploader && (
          <FeedFileAdder
            close={() => clearFiles()}
            setMediaFiles={(files: any) =>
              setNewFeed((prev: any) => ({ ...prev, media: files }))
            }
          />
        )}
      </div>
      <div className="post-creator-card">
        <SimpleTiptapWrapper
          content={""}
          placeholder={`Write message`}
          emojiContent={emojiContent}
          updateDocumentContent={(content: string) =>
            setNewFeed((prev) => ({ ...prev, content: content }))
          }
          currentContent={newFeed.content}
          enterContent={() => setEnterPressed(true)}
        />
        <div className="feed-menubar">
          <div style={{ justifyContent: "space-between" }} className="flex-row">
            <div className="flex-row">
              <div
                onClick={() => setShowFileUploader(true)}
                className="ffu-menu"
              >
                <Tooltip title="Add Files, Documents, Images etc">
                  <FilePresentOutlined className="ffu-menu-btn" />
                </Tooltip>
              </div>
              <div className="ffu-menu">
                &nbsp;&nbsp;
                <Tooltip title="Add Emoji">
                  <EmojiSelectButton
                    showEmojiContainer={showEmojiContainer}
                    toggleEmojiContainer={setShowEmojiContainer}
                    selectEmoji={selectEmoji}
                  />
                </Tooltip>
                &nbsp;&nbsp;
              </div>
              {showMoreOptions ? (
                <div className="ffu-menu">
                  &nbsp;&nbsp;
                  <Checkbox
                    onChange={(e) =>
                      setNewFeed((prev: any) => ({
                        ...prev,
                        allowComments: !prev.allowComments,
                      }))
                    }
                    defaultChecked
                    checked={newFeed.allowComments}
                    className="checkbox-medium"
                  />
                  <Typography
                    textColor="#888"
                    fontSize="0.9rem"
                    fontWeight={500}
                    onClick={(e) =>
                      setNewFeed((prev: any) => ({
                        ...prev,
                        allowComments: !prev.allowComments,
                      }))
                    }
                  >
                    Allow Comments
                  </Typography>
                  &nbsp;&nbsp;
                </div>
              ) : (
                <div
                  onClick={() => setShowMoreOptions(true)}
                  className="ffu-menu "
                >
                  &nbsp;&nbsp;
                  <Typography textColor="#888" fontSize={14} fontWeight={500}>
                    More..
                  </Typography>
                  &nbsp;&nbsp;
                </div>
              )}
            </div>
            <div className="flex-row">
              {newFeed.content && (
                <div className="mr1">
                  <small className="dim">
                    <strong>Shift + Enter</strong> to add a new line
                  </small>
                </div>
              )}

              <div
                style={{ borderLeft: "1px solid #ddd", borderRight: 0 }}
                className="ffu-menu"
                onClick={post}
              >
                <SendRounded
                  className="ffu-menu-btn send-button"
                />
              </div>
            </div>
          </div>
          <div className="feed-file-settings"></div>
        </div>
      </div>
    </div>
  );
};

export default FeedCreator;
