import { Button, FormControl, FormLabel, Grid, Input, Stack } from '@mui/joy'

import React, { useState, useEffect } from 'react'
import IconSelector from '../../../../components/IconSelector';
import useExiosPrivate from '../../../../utilities/hooks/useAxiosPrivate';
import _ from 'lodash';
import { FormErrorWithTimeout } from '../../../../components/cards/formError';
import { APIs } from '../../../../constants/apis';
import { useAppDispatch } from '../../../../redux/hooks';
import { updateUserWithNewSpaceTreeData } from '../../../../redux/reducers/User';

const CreateSpaceList = ({callback, onClose, parentId}:any) => {
    const exiosPrivate = useExiosPrivate();
    const dispatch = useAppDispatch();

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState({
        name: "",
        icon: "",
        parent_id: "",
    });

    useEffect(() => {
        setData((prev:any) => ({...prev, parent_id: parentId}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId])
    
    const setDataHandler = (value: string, type: string) => {
        const _data: any = _.cloneDeep(data);
        _data[type] = value;
        setData(_data);
    };

    const createSpace = async () => {
        if (data.name === "") {
            setError("Name of a space is required");
        } else {
            setLoader(true);
            const result = await exiosPrivate.post(APIs.SPACE, data);
            if (result.data.status === "Success") {
                dispatch(updateUserWithNewSpaceTreeData(result.data.data))
            }
            setLoader(false);
            onClose();
        }
    }

    return (
        <div>
        <Stack padding="1rem" paddingTop={0}>
            
                <Grid mt="1rem" xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                    <FormLabel required>Name of the Workspace</FormLabel>
                    <Input
                        onChange={(e) =>
                            setDataHandler(e.currentTarget.value, "name")
                        }
                        type="text"
                    />
                </FormControl>
                </Grid>
                <Grid mt="1rem" xs={12} sm={12} md={12} lg={12}>
                        <IconSelector
                            from="none"
                            icon={data.icon}
                            setIcon={(icon: string) =>
                                setData((prev) => ({ ...prev, icon }))
                            }
                            noImg={true}
                            name={data.name}
                        />
                    </Grid>
            <Grid mt="1rem" container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                >
                <FormErrorWithTimeout
                    clearError={() => setError("")}
                    text={error}
                />
                <Button
                    onClick={createSpace}
                    size="md"
                    fullWidth
                    variant="solid"
                    color="primary"
                >
                    Create
                </Button>
                </Grid>
            </Grid>
        </Stack>
        </div>
    )
}

export default CreateSpaceList