import { AddPhotoAlternateOutlined, FontDownload, FormatClearOutlined, FormatColorTextOutlined, FormatListBulleted, FormatListNumbered, TextFieldsOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { SingleFileUploader } from '../../../../../SingleFileUploader';
import { useExiosPrivateMulti } from '../../../../../../utilities/hooks/useAxiosPrivate';
import { SpinnerContainer } from '../../../../../SpinnerContainer';
import { APIs } from '../../../../../../constants/apis';
import { getMediaFileName } from '../../../../../../utilities/sharedUtilties';

const TextImageOptions = ({ setImage }: any) => {
  const exiosPrivateMulti = useExiosPrivateMulti();
  const [imageLoad, toggleImageLoad] = useState(false);
  
  const handleImageUpload = async (file: any) => {
    try {
      toggleImageLoad(true);
      const formData = new FormData();
      formData.append("file", file);
      const result = await exiosPrivateMulti.post(APIs.CONTENT_POST_MEDIA_UPLOAD, formData);
      if (result.data.status === "success") {
        setImage({
          name: result.data.file.originalname,
          type: getMediaFileName(result.data.file.mimetype),
          url: result.data.url,
          id: result.data.id,
          extension: result.data.file.mimetype
            ? result.data.file.mimetype.split("/")[1]
            : "",
        });
      }
      toggleImageLoad(false);
    } catch (err) {
      console.log("err", err);
    }
  };

    return (
        <>
          <div className="bubble-btn rel">
            <div className="header-text">
              {
                imageLoad ? <SpinnerContainer /> :
                  <SingleFileUploader
                    setImage={handleImageUpload}
                    maxSize={200000000000}
                    minSize={10}
                    types={["jpeg", "png", "jpg", "svg"]}
                  >
                  <AddPhotoAlternateOutlined />
                  </SingleFileUploader>
              }    
            </div>
          </div>
        </>
      
  )
}

export default TextImageOptions;