import React, { useContext, useState } from 'react'
import { textIconCreator } from "../../../utilities/sharedUtilties";
import { UserType } from '../../../redux/reducers/User.types';
import useLogout from '../../../utilities/hooks/useLogout';
import { AModal } from '../../../components/modal/AModal';
import { ProfileAccount } from '../MainHeader/Settings/ProfileAccount';

interface UserLinkType {
  user: UserType,
  refreshUser: () => void
}

const UserLink = ({ user,refreshUser }: UserLinkType) => {
  const logout = useLogout();
  const [showAccountSetting, toggleShowAccountSetting] = useState(false);

  const openSettings = () => {
    toggleShowAccountSetting(true);
  };
  
  const wdw = () => {
    toggleShowAccountSetting((prev) => !prev)
  }

  return (
    <div className="user-menu">
        <ul>
          <li className="flex-row gHalf" onClick={wdw}>
            {
            user.dp === "" ? textIconCreator(user.name, "m1") :
              <img src={user.dp} alt={user.name} /> 
            }
            {user.name}
          </li>
      </ul>
      {showAccountSetting && (
        <AModal
          onClose={() => toggleShowAccountSetting((prev) => !prev)}
          size="md"
          title="Account Settings"
        >
          <ProfileAccount
            onClose={() => toggleShowAccountSetting((prev) => !prev)}
            logout={logout}
            user={user}
            refreshUser={refreshUser}
          />
        </AModal>
      )}
      </div>
  )
}

export default UserLink