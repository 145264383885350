import _, { isArray } from "lodash";
import Names from "../constants/names";
import { ForumOutlined } from "@mui/icons-material";

export const isAdmin = (roles: string[]) => {
  if (!roles || roles.length === 0) return false;
  return roles.indexOf("admin") > 0;
};

export const isPageActive = (path: string, name: string) => {
  //if (path === "/" && name === "Workspace") return true;
  return path.toLowerCase().indexOf(name.toLowerCase()) >= 0;
};

export const SetObjectWithKey = (obj: object, key: string, value: string) => {
  const newObj: any = _.cloneDeep(obj);
  newObj[key] = value;
  return newObj;
};

export const storePersistInLocalStorage = (persist: boolean) => {
  localStorage.setItem(Names.persistStorage, persist.toString());
};

export const localStorageGetSet = (key: string, value: string) => {
  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, value);
  } else {
    return localStorage.getItem(key);
  }
};

export const loginCookieExists = () => {
  return ":";
};

export const getUniqueKey = (key: string) => {
  return `${_.uniqueId(key)}`;
};

export const textInitials = (name: string) => {
  if (!name) return "";
  const namespace = name.split(" ");

  if (isArray(namespace)) {
    if (namespace.length === 1) {
      return name[0];
    }
    return `${namespace[0].split("")[0]}${namespace[1].split("")[0]}`;
  }
};

export const validateEmail = (email: string) => {
  var input = document.createElement("input");

  input.type = "email";
  input.required = true;
  input.value = email;

  const d =
    typeof input.checkValidity === "function"
      ? input.checkValidity()
      : /\S+@\S+\.\S+/.test(email);
  return d;
};

export const getMediaFileName = (name: string) => {
  if (name.toLowerCase().includes("image")) {
    return "image";
  }
  if (name.toLowerCase().includes("pdf")) {
    return "pdf";
  }
};

export const wrapURL = (text: string) => {
  // Create your regex pattern for urls
  let urlPattern =
    /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\\z`!()\\[\]{};:'".,<>?«»“”‘’]))/gi;

  // Check string for any url patterns and wrap them in anchor tags
  let result = text.replace(urlPattern, function (url) {
    return `<a href="${url.trim()}">${url.trim()}</a>`;
  });

  return result;
};

export const textIconCreator = (
  text: string,
  size: string = "m",
  style?: any
) => {
  const firstLetter = text && text.split("") && text[0];
  return (
    <div
      style={style}
      className={`lettered-icon size-${size}`}
      data-name={firstLetter}
    ></div>
  );
};

export const getSectionIcons = (icon: string) => {
  switch (icon) {
    case "forum":
      return <ForumOutlined />;
  }
};

export const ifUserIsMe = () => {
  return "hello"
}
