import { useState, useEffect } from "react";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { textIconCreator } from "../../../../../utilities/sharedUtilties";
import { APIs } from "../../../../../constants/apis";
import { SpinnerContainer } from "../../../../../components/SpinnerContainer";
import { FormErrorWithTimeout } from "../../../../../components/cards/formError";
import { Tooltip, Typography } from "@mui/joy";
import { FilePresentOutlined } from "@mui/icons-material";
import FileAdder from "../../../../../components/FileAdder";
import { SimpleTiptapWrapper } from "../../../Documents/components/TextEditor/TiptapWrapper";

export const FeedCommentCreator = ({
  user,
  setComments,
  feedCardId,
}: any) => {
  const exiosPrivate = useExiosPrivate();
  const [comment, setComment] = useState({
    content: "",
    media: [],
  });
  const [feedUploading, setFeedUploading] = useState(false);
  const [feedError, setFeedError] = useState("");
  const [showMedia, toggleMedia] = useState(false);
  const [showAction, toggleShowAction] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const setMediaFiles = (files: any) => {
    setComment((prev: any) => ({ ...prev, media: files }));
  };

  const post = async () => {
    if (!comment.content || comment.content === "") {
      setFeedError("Comment cannot be empty");
    } else {
      setFeedUploading(true);
      try {
        const result = await exiosPrivate.post(APIs.CONTENT_POST_COMMENTS, {
          ...comment,
          parentId: feedCardId,
        });
        const commentList = document.querySelector(
          ".expanded-comment .comment-lister"
        );
        if (commentList) {
          commentList.scrollIntoView({ behavior: "smooth" });
        }
        setComments(result.data);
        toggleMedia(false);
        setFeedUploading(false);
        setComment({
          content: "",
          media: [],
        });
      } catch (err) {
        console.log("err", err);
      }
      setFeedUploading(false);
    }
  };

  const updateDocumentContent = (value: string) => {
    setComment((prev: any) => ({ ...prev, content: value }));
    toggleShowAction(true);
  };

  useEffect(() => {
    if (enterPressed) {
      post();
      setEnterPressed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed]);


  return (
    <>
      {feedUploading ? (
        <SpinnerContainer />
      ) : (
        <div className="feed-comment-creator">
          <div className="feed-reply-container">
            <div className="user-dp">
              <div className="dp">
                {user.dp ? (
                  <img src={user.dp} alt={user.name} className="commentor-dp" />
                ) : (
                  textIconCreator(user.name, "l")
                )}
              </div>
            </div>
            <div className="full feed-reply-container">
              <SimpleTiptapWrapper
                content=""
                placeholder="Reply to Mukur"
                updateDocumentContent={updateDocumentContent}
                enterContent={() => setEnterPressed(true)}
              />
            </div>
            {showAction && (
              <div className="flex-row" style={{ gap: "0.5rem" }}>
                <span
                  onClick={() => toggleMedia((prev) => !prev)}
                  className="file-media-toggler"
                >
                  <Tooltip title="Add Images with comment">
                    <FilePresentOutlined sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </span>
                <Tooltip title="Add reply">
                  <span className="comment-action-btn" onClick={post}>
                    Reply
                  </span>
                </Tooltip>
              </div>
            )}

            {/* {feedError && (
              <FormErrorWithTimeout
                text="This is an error"
                clearError={() => setFeedError("")}
              />
            )} */}
          </div>
          {showMedia && (
            <div className="comment-media-file-container">
              <FileAdder
                types={["jpeg", "png", "jpg", "svg"]}
                title="Attach Files"
                subTitle="(jpeg, png, jpg, svg)"
                maxSize={200000000}
                minSize={10}
                uploadURL={APIs.CONTENT_POST_MEDIA_UPLOAD}
                deleteURL={APIs.CONTENT_POST_MEDIA_DELETE}
                setMediaFiles={setMediaFiles}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
