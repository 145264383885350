import { FontDownload, FormatClearOutlined, FormatColorTextOutlined, FormatListBulleted, FormatListNumbered, TextFieldsOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { TipTapMenuActions } from '../TiptapWrapper'
import { Divider, Tooltip, Typography } from '@mui/joy';

const TextColorOptions = ({ actionMenuClick, isEditorFocus }: any) => {
    const [subBubble, setSubBubble] = useState(false);

    const setAction = (type: string) => {
        setSubBubble(false);
        actionMenuClick(type)
  }
  
  useEffect(() => {
        if (isEditorFocus) {
            setSubBubble(false)
        }
    }, [isEditorFocus])
  
    return (
        <>
            <div onClick={() => setSubBubble((prev) => !prev)}  className="bubble-btn rel">
          <div  className="header-text">
            <Tooltip title="Text Color & Background color">  
              <FormatColorTextOutlined />
              </Tooltip>
                </div>
            </div>
            {
              subBubble && <div className='bubble-subBubble'>
                  <ul>
                      <li className='flex-center g1'>
                          <Tooltip title="Remove Formatting">
                            <Typography onClick={() => setAction(TipTapMenuActions.formatClear) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatClearOutlined sx={{color: TipTapMenuActions.color_Default}} /></Typography>
                          </Tooltip> 
                      </li>
                      <li className='flex-center g1'>
                          <Tooltip title="Grey foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Grey) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Grey}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Grey background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Grey) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Grey}} /></Typography>
                      </Tooltip>
                      </li>
                      <li className='flex-center g1'>
                          <Tooltip title="Brown foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Brown) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Brown}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Brown background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Brown) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Brown}} /></Typography>
                      </Tooltip>
                      </li>
                      <li className='flex-center g1'>
                          <Tooltip title="Orange foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Orange) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Orange}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Orange background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Orange) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Orange}} /></Typography>
                        </Tooltip>
                        </li>
                        <li className='flex-center g1'>
                          <Tooltip title="Yellow foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Yellow) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Yellow}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Yellow background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Yellow) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Yellow}} /></Typography>
                        </Tooltip>
                        </li>
                        <li className='flex-center g1'>
                          <Tooltip title="Green foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Green) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Green}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Green background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Green) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Green}} /></Typography>
                        </Tooltip>
                        </li>
                        <li className='flex-center g1'>
                          <Tooltip title="Blue foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Blue) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Blue}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Blue background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Blue) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Blue}} /></Typography>
                        </Tooltip>
                        </li>
                        <li className='flex-center g1'>
                          <Tooltip title="Purple foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Purple) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Purple}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Purple background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Purple) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Purple}} /></Typography>
                        </Tooltip>
                        </li>
                        <li className='flex-center g1'>
                          <Tooltip title="Pink foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Pink) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Pink}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Pink background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Pink) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Pink}} /></Typography>
                        </Tooltip>
                        </li>
                        <li className='flex-center g1'>
                          <Tooltip title="Red foreground">
                          <Typography onClick={() => setAction(TipTapMenuActions.color_Red) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FormatColorTextOutlined sx={{color: TipTapMenuActions.color_Red}} /></Typography>
                          </Tooltip>
                          <Tooltip title="Red background">
                          <Typography onClick={() => setAction(TipTapMenuActions.bgColor_Red) } className="flex-center" sx={{display: "flex", gap: "0.5rem"}} ><FontDownload sx={{color: TipTapMenuActions.color_Red}} /></Typography>
                      </Tooltip>
                      </li>
                      
              </ul>
          </div>
          }
      </>
      
  )
}

export default TextColorOptions