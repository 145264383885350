import {
  ArticleOutlined,
  ChecklistOutlined,
  ChevronRightRounded,
  DeleteOutlined,
  DesignServicesOutlined,
  ForumOutlined,
  Group,
  Groups,
  ListAltOutlined,
  MoreVert,
  StarRateRounded,
  StarRounded,
  StarsRounded,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuTrigger,
} from "rctx-contextmenu";
import { Divider, Tooltip, Typography } from "@mui/joy";
import { AModal } from "../modal/AModal";
import AddFolder from "../Folder/AddFolder";
import RenameFolder from "../Folder/RenameFolder";
import DeleteFolder from "../Folder/DeleteFolder";
import SectionsSubMenu from "./SectionsSubMenu";
import FolderMenu from "./FolderMenu";
import SectionCreatorModal from "./SectionCreatorModal";
import { SectionTypeNames } from "../Sections/Renderer";
import SectionSettings from "./settings/SectionSettings";
import RenameSection from "./settings/RenameSection";
import DeleteSection from "./settings/DeleteSection";
import { useParams } from "react-router-dom";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../constants/apis";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {User, replaceUserWithNewFolderList } from "../../redux/reducers/User";
import EditPrivateMembers from "./settings/EditPrivateMembers";
import MembersinCircularGroupHorizontal from "../MembersinCircularGroupHorizontal";
import _ from "lodash";

const SpaceTree = ({ refreshSpace, sectionTree, spaceTree, entity, updateTree, openFile, navigateToMain }: any):any => {
  const { subId } = useParams();
  if (!spaceTree || spaceTree.length === 0) return <></>;

  const getActiveClass = (id: string) => {
    if (subId === id) {
      return "active";
    }
  };

  return (
    <>
      <RenderSection
        sectionTree={sectionTree}
        openFile={openFile}
        entity={entity}
        updateTree={updateTree}
        getActiveClass={getActiveClass}
        navigateToMain={navigateToMain}
      />
      {spaceTree.map((folder: any) => (
        <RenderSectionFolder
          folder={folder}
          entity={entity}
          updateTree={updateTree}
          openFile={openFile}
          getActiveClass={getActiveClass}
          navigateToMain={navigateToMain}
          key={folder.id}
          refreshSpace={refreshSpace}
        />
      ))}
    </>
  );
};

const RenderSectionFolder = ({ refreshSpace, navigateToMain, folder:fld, entity, updateTree, openFile, getActiveClass }: any) => {
  const exiosPrivate = useExiosPrivate();
  const [isFolderOpen, setFolderOpen] = useState(false);
  const [folder, setFolder] = useState<any>(fld);
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [addRemoveMembersModal, setAddRemoveMembersModal] = useState(false);
  const [renameFolderModal, setRenameFolderModal] = useState(false);
  const [deleteFolderModal, setDeleteFolderModal] = useState(false);
  const [sectionCreatorModal, setSectionCreatorModal] = useState(false);
  const [sectionCreatorType, setSectionCreatorType] = useState("");
  const { data: user }: any = useAppSelector(User);
  const dispatch = useAppDispatch();

  const checkFolderState = async () => {
    const folderState = await folderOpenState(folder.id);
    if (folderState) {
      setFolderOpen(true);
    }
  }

  useEffect(() => {
    checkFolderState();
    const newFolder:any = { ...fld };
    if (newFolder.sections && newFolder.sections.length > 0) {
      newFolder.sections = _.sortBy(newFolder.sections, [function (o) { return o.created_at; }]);
    }
    setFolder(newFolder);
  }, [fld]);

  const updateFolderStateForUser = (folderState: any) => {
    exiosPrivate.post(APIs.SET_USER_FOLDERSTATE, { folderState })
  }

  const folderOpenState = async (folderId: string) => {
    if (user.preferences.folderStateList && user.preferences.folderStateList.includes(folderId)) {
      return true;
    }
    return false;
  };

  const setUserFolderState = (folderId: string) => {
    let folderStateList:any = [];
    if (user.preferences.folderStateList && user.preferences.folderStateList.length) {
      if (user.preferences.folderStateList.includes(folderId)) {
        folderStateList = [...user.preferences.folderStateList].filter((folder: string) => folder !== folderId);
      } else {
        folderStateList = [...user.preferences.folderStateList, folderId];
      }
      updateFolderStateForUser(folderStateList);
      dispatch(replaceUserWithNewFolderList(folderStateList));
    } else {
      folderStateList = [folderId];
      dispatch(replaceUserWithNewFolderList(folderStateList));
      updateFolderStateForUser(folderStateList);
    }
};

  const toggleFolder = (folderId: string) => {
    setFolderOpen((prev: any) => !prev);
    setUserFolderState(folderId);
  };
  const addFolder = () => {
    setNewFolderModal(true);
  };
  const renameFolder = () => {
    setRenameFolderModal(true);
  };
  const deleteModal = () => {
    setDeleteFolderModal(true);
  };

  const openCreator = (type: string) => {
    setSectionCreatorModal(true);
    setSectionCreatorType(type);
  };

  const closeCreatorModal = () => {
    setSectionCreatorModal(false);
    setSectionCreatorType("");
  };

  const [currentFile, setCurrentFile] = useState<any>({
    name: "",
    icon: "",
  });

  const [renameFileModal, setRenameFileModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const resetEverything = () => {
    setRenameFileModal(false);
    setDeleteFileModal(false);
    setCurrentFile(null);
  };

  const addRemoveMembers = () => {
    setAddRemoveMembersModal(true);
  }

  const isUserAdmin = entity.admins.find((entity: any) => entity.id === user._id);



  return (
    <li key={folder.id}>
      <ContextMenuTrigger className="full-wh" id={folder.id}>
        <SectionContextMenu
          parentId={folder.id}
          deleteFolder={deleteModal}
          renameFolder={renameFolder}
          addFolder={addFolder}
          folder={folder}
          openCreator={openCreator}
          updateTree={updateTree}
          user={user}
          addRemoveMembers={addRemoveMembers}
        />
        <span className="folder-name">
          <span className="folder-title"  onClick={() => toggleFolder(folder.id)}>
            <ChevronRightRounded
              style={{
                transform: isFolderOpen ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
            <span>
              <span>{folder.name}</span>
            </span>
          </span>
          {
            folder.scope === "private" && <span onClick={addRemoveMembers} className="mm-icon">
              <MembersinCircularGroupHorizontal members={folder.members} />
          </span>
          }
          
        </span>

        <ul className={`${isFolderOpen ? "open" : "close"}`}>
          {folder.sections.length > 0 &&
            folder.sections.map((fileSection: any) => (
              <ContextMenuTrigger key={fileSection.id} id={fileSection.id}>
                <li onClick={() => openFile(fileSection)}
                  className={`file-section ${getActiveClass(fileSection.id)}`}
                >
                  {
                    user._id === fileSection.creator_id ||
                     isUserAdmin
                      ?
                      <ContextMenu
                    className="section-tree-contextMenu"
                    id={fileSection.id}
                  >
                    <SectionSettings
                      fileSection={fileSection}
                      user={user}
                      renameSection={() => {
                        setRenameFileModal(true);
                        setCurrentFile(fileSection);
                      }}
                      deleteSection={() => {
                        setDeleteFileModal(true);
                        setCurrentFile(fileSection);
                      }}
                      resetEverything={resetEverything}
                    />
                  </ContextMenu> : null
                  } 
                  
                  {fileSection.icon ? (
                    <img src={fileSection.icon} alt={fileSection.name} />
                  ) : (
                    <span>{sectionTypeRendered(fileSection.type)}</span>
                  )}
                  <span>
                    {fileSection.name}
                  </span>
                </li>
              </ContextMenuTrigger>
            ))}
          {folder.folders.length > 0 &&
            folder.folders.map((f: any) => (
              <RenderSectionFolder
                entity={entity}
                updateTree={updateTree}
                folder={f}
                openFile={openFile}
                getActiveClass={getActiveClass}
                navigateToMain={navigateToMain}
                />
            ))}
        </ul>
      </ContextMenuTrigger>
      {renameFileModal && (
        <AModal title="Rename Section" onClose={resetEverything}>
          <RenameSection
            root_id={entity.id}
            updateTree={(data: any) => {
              updateTree(data);
              resetEverything();
            }}
            file={currentFile}
          />
        </AModal>
      )}
      {deleteFileModal && (
        <AModal title="Delete Section" onClose={resetEverything}>
          <DeleteSection
            updateTree={(data: any) => {
              updateTree(data);
              resetEverything();
            }}
            navigateToMain={navigateToMain}
            file={currentFile}
            root_id={entity.id}
            resetEverything={resetEverything}
          />
        </AModal>
      )}
      {newFolderModal && (
        <AModal size="sm" onClose={() => setNewFolderModal(false)} title="Add Folder">
          <AddFolder
            updateTree={updateTree}
            parent_id={folder.id}
            root_id={entity.id}
            close={() => setNewFolderModal(false)}
            members={entity.members}
          />
        </AModal>
      )}
      
      {renameFolderModal && (
        <AModal
          onClose={() => setRenameFolderModal(false)}
          title="Rename Folder"
        >
          <RenameFolder
            updateTree={updateTree}
            root_id={entity.id}
            close={() => setRenameFolderModal(false)}
            folder={folder}
          />
        </AModal>
      )}
      {deleteFolderModal && (
        <AModal
          onClose={() => setDeleteFolderModal(false)}
          title="Delete Folder"
        >
          <DeleteFolder
            updateTree={updateTree}
            root_id={entity.id}
            close={() => setDeleteFolderModal(false)}
            folder={folder}
          />
        </AModal>
      )}
      {sectionCreatorModal && (
        <SectionCreatorModal
          onClose={closeCreatorModal}
          type={sectionCreatorType}
          parentId={folder.id}
          updateTree={updateTree}
        />
      )}
      {
        addRemoveMembersModal && (
          <AModal
            size="md"
            onClose={() => setAddRemoveMembersModal(false)}
            title={`Add/Remove Members in "${folder.name}"`}
        >
            <EditPrivateMembers close={() => setAddRemoveMembersModal(false)} folder={folder} />
        </AModal>
        )
      }
    </li>
  );
};

const sectionTypeRendered = (type: string) => {
  switch (type) {
    case SectionTypeNames.FEED:
      return <ForumOutlined/>;
    case SectionTypeNames.DOCUMENT:
      return <ArticleOutlined />;
    case SectionTypeNames.TASKER:
      return <ChecklistOutlined />;
    case SectionTypeNames.BOARD:
      return <ListAltOutlined />;
  }
  return <></>;
};

const SectionContextMenu = ({
  folder,
  addFolder,
  deleteFolder,
  renameFolder,
  openCreator,
  updateTree,
  addRemoveMembers,
  user
}: any) => {
  if (folder.creator_id) {
   if(!folder.allow_children && user._id !== folder.creator_id) {
      return <></>
    } 
  }
 
  return (
    <div>
      <ContextMenu className="section-tree-contextMenu" id={folder.id}>
        <SectionsSubMenu
          updateTree={updateTree}
          parentId={folder.id}
          openCreator={openCreator}
        />
        <FolderMenu addFolder={addFolder} />
        {
          user._id === folder.creator_id ? <>
        
        {
          folder.scope === "private" ? 
          <>
          <Divider />
          <ContextMenuItem onClick={() => addRemoveMembers()}>
            <ContextMenuTitle>
              <Groups sx={{ fontSize: 17 }} />
              Add/Remove members
            </ContextMenuTitle>
          </ContextMenuItem>  
          </> : <></>
        }
        <Divider />
        <ContextMenuItem onClick={() => renameFolder()}>
          <ContextMenuTitle>
            <DesignServicesOutlined sx={{ fontSize: 17 }} />
            Rename
          </ContextMenuTitle>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => deleteFolder()}>
          <Typography
            textColor="red"
            fontSize={15}
            textTransform="none"
            alignItems="center"
            gap={1}
            display="flex"
          >
            <DeleteOutlined sx={{ fontSize: 17, color: "red" }} />
            Delete
          </Typography>
          </ContextMenuItem>
        </> : <></>
        }
        
      </ContextMenu>
    </div>
  );
};

const RenderSection = ({ navigateToMain, sectionTree, openFile,entity, getActiveClass,updateTree }: any) => {
  const [renameFileModal, setRenameFileModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [currentFile, setCurrentFile] = useState<any>({
    name: "",
    icon: "",
  });

  const resetEverything = () => {
    setRenameFileModal(false);
    setDeleteFileModal(false);
  };

  return <>{sectionTree.length > 0 &&
    sectionTree.map((fileSection: any) => (
      <ContextMenuTrigger id={fileSection.id}>
        <li
          onClick={() => openFile(fileSection)}
          className={`file-section ${getActiveClass(fileSection.id)}`}
        >
            <ContextMenu
            className="section-tree-contextMenu"
            id={fileSection.id}
          >
            <SectionSettings
              renameSection={() => {
                setRenameFileModal(true);
                setCurrentFile(fileSection);
              }}
              deleteSection={() => {
                setDeleteFileModal(true);
                setCurrentFile(fileSection);
              }}
            />
          </ContextMenu>
          {fileSection.icon ? (
            <img src={fileSection.icon} alt={fileSection.name} />
          ) : (
            <span>{sectionTypeRendered(fileSection.type)}</span>
          )}
          <span>{fileSection.name}</span>
        </li>
      </ContextMenuTrigger>
    ))}
      {renameFileModal && (
        <AModal title="Rename Section" onClose={resetEverything}>
          <RenameSection
            root_id={entity.id}
            updateTree={(data: any) => {
              updateTree(data);
              resetEverything();
            }}
            file={currentFile}
          />
        </AModal>
      )}
      {deleteFileModal && (
        <AModal title="Delete Section" onClose={resetEverything}>
          <DeleteSection
            updateTree={(data: any) => {
              updateTree(data);
              resetEverything();
            }}
            file={currentFile}
          root_id={entity.id}
          resetEverything={resetEverything}
          navigateToMain={navigateToMain}
          />
        </AModal>
      )}
  </>
}

export const ContextMenuTitle = ({ children }: any) => {
  return (
    <Typography
      fontSize={15}
      textTransform="none"
      alignItems="center"
      gap={1}
      display="flex"
    >
      {children}
    </Typography>
  );
};

export default SpaceTree;
