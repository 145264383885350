import { Provider } from "react-redux";
import "../src/styles/app.scss";
import { AuthProvider } from "./context/AuthProvider";
import { AppRouter } from "./router";
import { store } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
    <AuthProvider>
      <AppRouter />
      </AuthProvider>
    </Provider>
  );
}

export default App;
