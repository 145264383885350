import { Outlet } from "react-router-dom";
import "../styles/app.scss";

export const AppLayout = ({ loggedIn }: any) => {
  return (
    <div className={`main-content`}>
      <Outlet />
    </div>
  );
};
