import { useEffect, useState } from "react";
import useExiosPrivate from "../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../constants/apis";

const useFeed = ({ feedId }: any) => {
  const exiosPrivate = useExiosPrivate();
  const [feed, setFeed] = useState([]);
  const [feedLoaded, setFeedLoaded] = useState(false);
  const [feedPrimary, setFeedPrimaryData] = useState({
    name: "",
    icon: "",
  });

  const getFeed = async () => {
    setFeedLoaded(true);
    try {
      const result = await exiosPrivate.get(`${APIs.GET_FEED}/?id=${feedId}`);
      setFeed(result.data.feedItems);
      setFeedPrimaryData({ name: result.data.name, icon: result.data.icon });
    } catch (err) {
      console.log("err", err);
      window.location.reload()
    }
    setFeedLoaded(false);
  };

  const refreshFeed = (updatedFeed: any) => {
    setFeed(updatedFeed);
  };

  useEffect(() => {
    getFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId]);

  if (!feedId) {
    return {
      feed: "",
    };
  }
  return { feed, refreshFeed, setFeed, feedPrimary, feedLoaded };
};

export default useFeed;
