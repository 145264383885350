import React, { useEffect, useState } from "react";
import PATHS from "../../constants/paths";

import {
  Button,
  Input,
  Grid,
  FormControl,
  CardActions,
  FormLabel,
  Typography,
} from "@mui/joy";
import { Header } from "../../components/header";
import { FormCard } from "../../components/cards/formCard";
import { useNavigate, useParams } from "react-router-dom";
import { FormErrorWithTimeout } from "../../components/cards/formError";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import _ from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { APIs } from "../../constants/apis";
import SomethingWrong from "../../components/SomethingWrong/SomethingWrong";

export const ResetPassword = () => {
  const [passwordVisibility, togglePasswordVisibility] = useState(false);
  const { code } = useParams();

  const [wrong, setWrong] = useState(false);
  const [success, setSuccess] = useState(false);

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const exiosPrivate = useExiosPrivate();

  const getResetCode = async () => {
    const data = { code };
    try {
      const response = await exiosPrivate.post(
        APIs.RESET_PASSWORD_VERIFY,
        data
      );
      if (response.data.id !== code) {
        setWrong(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getResetCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPassword = async () => {
    if (data.password && data.confirmPassword) {
      if (data.password === data.confirmPassword) {
        try {
          const response = await exiosPrivate.post(APIs.CREATE_NEW_PASSWORD, {
            password: data.password,
            code,
          });
          if (response.data.status === "fail") {
            setError("Something went wrong");
          } else {
            if (response.data.status === "success") {
              console.log("gweeg");
              setSuccess(true);
            }
          }
        } catch (err) {
          console.log("err", err);
        }
      } else {
        setError("Password should be equal");
      }
    } else {
      setError("Password value should be there");
    }
  };

  const updatePassword = (value: string, type: string) => {
    const updateData: any = _.cloneDeep(data);
    updateData[type] = value;
    setData(updateData);
  };

  if (wrong) {
    return <SomethingWrong />;
  }

  return (
    <div>
      <Header>
        <Button
          sx={{ marginRight: "1rem" }}
          className="header-button shadow carrot"
          color="primary"
          onClick={() => navigate(PATHS.signin)}
        >
          Signin
        </Button>
      </Header>
      <div className="space-readable-1">
        {success ? (
          <Grid container justifyContent="center" mt={10}>
            <Grid
              justifyContent="center"
              gap={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              xs={6}
            >
              <Typography fontSize={33}>✅</Typography>
              <Typography fontSize={20}>
                Password successfully updated.
                <br />{" "}
                <strong>Now you can Signin with updated password.</strong>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} sx={{ flexGrow: 1 }} mt={10}>
            <Grid xs></Grid>
            <Grid xs={3.5}>
              <FormCard title="Reset Password">
                <FormControl>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    onChange={(e) =>
                      updatePassword(e.currentTarget?.value, "password")
                    }
                    autoComplete="off"
                    endDecorator={
                      passwordVisibility ? (
                        <Visibility
                          onClick={() =>
                            togglePasswordVisibility((prev) => !prev)
                          }
                          sx={{ color: "#a3a3a3" }}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() =>
                            togglePasswordVisibility((prev) => !prev)
                          }
                          sx={{ color: "#a3a3a3" }}
                        />
                      )
                    }
                    type={passwordVisibility ? "text" : "password"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Confirm New Password</FormLabel>
                  <Input
                    onChange={(e) =>
                      updatePassword(e.currentTarget?.value, "confirmPassword")
                    }
                    type="password"
                    autoComplete="off"
                  />
                </FormControl>
                <Grid padding={0} mt={1}>
                  <FormErrorWithTimeout
                    text={error}
                    clearError={() => setError("")}
                  />
                </Grid>
                <CardActions>
                  <Button
                    onClick={resetPassword}
                    variant="solid"
                    color="primary"
                  >
                    Reset Password
                  </Button>
                </CardActions>
              </FormCard>
            </Grid>
            <Grid xs></Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};
