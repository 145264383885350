  import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  Tooltip,
  Typography,
} from "@mui/joy";
import { AModal } from "../modal/AModal";
import { CloseRounded, SearchOutlined } from "@mui/icons-material";
import React, { useState } from "react";

import "./index.scss";
import { SpinnerContainer } from "../SpinnerContainer";
import useExiosPrivate from "../../utilities/hooks/useAxiosPrivate";
import { useMembers } from "./members.hook";
import { textInitials } from "../../utilities/sharedUtilties";
import _ from "lodash";

function AddMembers({
  title,
  list,
  setList,
  onClose,
  existingMembers,
  membersOnly = false,
}: any) {

  const exiosPrivate = useExiosPrivate();
  const { members, loadMembers, setMembers } = useMembers({
    exiosPrivate,
  });

  const [allSelected, setAllSelected] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const updateMainMembers = (state: boolean, _member: any) => {
    const _selectedMembers = _.cloneDeep(members);
    const _selectedMember = _.find(
      _selectedMembers,
      (member: any) => member.name === _member.name
    );
    _selectedMember["selected"] = state;
    setMembers(_selectedMembers);
  };

  let memberList: any = [];

  if (members.length > 0) {
    memberList = _.map(
      _.filter(members, (member: any) => {
        const ifExisting = existingMembers.find(
          (member_: any) =>
            member_.name.toLowerCase() === member.name.toLowerCase()
        );
        if (!ifExisting) {
          if (filterValue) {
            return (
              member.name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
            );
          } else {
            return member;
          }
        }
      })
    );
  }

  const setData = () => {
    setList(_.filter(members, (member) => member.selected === true));
    onClose();
  };

  const selectAll = () => {
    const newMemberStates:any = [];
    _.forEach(
    _.cloneDeep(members),
      (member: any) => {
        member.selected = !allSelected;
        newMemberStates.push(member);
      }
    );
    setAllSelected(prev => !prev);
    setMembers(newMemberStates);
  }

  //const unSelectedMembers = members.map((member: any) => member.selected ===).filter((member: any) => member);

  return (
    <AModal onClose={onClose} size="md" title={title}>
      <div>
        <Grid m={0} container spacing={3} sx={{ flexGrow: 1 }}>
          <Grid flexDirection="column" display="flex" sm={12} md={12} lg={12}>
              <div className="full ">
                <Input
                  fullWidth
                  endDecorator={<SearchOutlined />}
                  type="text"
                  placeholder="Search Member"
                  onChange={(e) => setFilterValue(e.currentTarget.value)}
                />
                <div className="list-container">
                  {loadMembers ? (
                    <SpinnerContainer />
                  ) : memberList.length > 0 ? (
                    memberList.map((member: any) => (
                      <FormControl key={_.uniqueId("members-")}>
                        <div className="flex-row checkbox-list" style={{justifyContent: "flex-start"}}>
                          <Checkbox
                            onChange={(e) =>
                              updateMainMembers(e.currentTarget.checked, member)
                            }
                            checked={member.selected}
                          />
                          {member.dp && (
                            <Tooltip title={member.name}>
                              <div
                                data-username={textInitials(member.name)}
                                className={`${
                                  member.dp.indexOf("undefined") > 0 &&
                                  `default-dp`
                                } profile-logo`}
                              >
                                {member.dp.indexOf("undefined") < 0 && (
                                  <img
                                    style={{
                                      borderRadius: "4px",
                                    }}
                                    alt="dp"
                                    src={member.dp}
                                  />
                                )}
                              </div>
                            </Tooltip>
                          )}
                          {!member.dp && (
                            <div
                              data-username={textInitials(member.name)}
                              className={`member-logo`}
                            ></div>
                          )}
                          <Typography fontWeight={500}>
                            {member.name}
                          </Typography>
                        </div>
                      </FormControl>
                    ))
                  ) : (
                    <NoTeamMemberFound />
                  )}
              </div>
              <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid display="flex" xs={12} sm={8} md={8} lg={8}>
                  <Typography
                    fontWeight={500}
                    textColor="#fb3d01"
                    alignContent="center"
                    display="flex"
                    sx={{ cursor: "pointer", fontSize: "0.9rem" }}
                    alignItems="center"
                    onClick={selectAll}
                  >
                    {allSelected ? "Deselect all" : "Select All"}
                  </Typography>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="flex-end"
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                >
                  <Button
                    onClick={setData}
                    size="md"
                    fullWidth
                    variant="solid"
                    color="primary"
                  >
                    Add Members
                  </Button>
                </Grid>
              </Grid>
              </div>
          </Grid>
        </Grid>
      </div>
    </AModal>
  );
}

export const MemberItem = ({ member, removeMember }: any) => {
  if (!member.selected) return <></>;
  return (
    <div className="member-tile">
      <div
        onClick={() => removeMember(member)}
        title="Remove"
        className="member-remove"
      >
        <CloseRounded sx={{ fontSize: "16px" }} />
      </div>
      <Tooltip title={member.name}>
        {member.dp ? (
          <Tooltip title={member.name}>
            <div
              data-username={textInitials(member.name)}
              className={`${
                member.dp.indexOf("undefined") > 0 && `default-dp`
              } profile-logo`}
            >
              {member.dp.indexOf("undefined") < 0 && (
                <img
                  style={{
                    borderRadius: "4px",
                  }}
                  alt="dp"
                  src={member.dp}
                />
              )}
            </div>
          </Tooltip>
        ) : (
          <div
            data-username={textInitials(member.name)}
            className="member-logo"
          ></div>
        )}
      </Tooltip>
    </div>
  );
};
const NoTeamMemberFound = () => {
  return (
    <div className="no-member">
      <p>No member found</p>
    </div>
  );
};
export default AddMembers;
