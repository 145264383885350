import React, { useState } from 'react'
import "./index.scss";
import { AddOutlined } from '@mui/icons-material';
import _ from 'lodash';
import Cubical from '../../Cubical';
import useTodos from './todo.hooks';
import TodoList from './components/TodoList';

const Todo = ({ id, entity }: any) => {
  
  const {
    toDoData, todoLoading, addTodo, deleteTodo,
    updateCheckForTask
  } = useTodos();

  if (!toDoData) return <></>;

  return (
    <Cubical isLoading={todoLoading}>
      <Cubical.header>
        <Cubical.header.title icon={toDoData.icon} label={toDoData.name} />
      </Cubical.header>
      <Cubical.drawer>
      </Cubical.drawer>
      <Cubical.body>
        <div className='todoBody'>
          <AddNewTodo
            addTodo={addTodo}
          />
          <TodoList
            toDoData={toDoData}
            deleteTodo={deleteTodo}
            updateCheckForTask={updateCheckForTask}
          />
        </div>
      </Cubical.body>
    </Cubical>
    
  )
}

const AddNewTodo = ({ addTodo }: any) => {
  const [addTodoText, setAddTodoText] = useState("");

  const submitTodo = (e: any) => {
    setAddTodoText(e.currentTarget.value);
  }
  
  return <div className="todoItemRichText">
    <div className='todoAddIcon flex-center'>
      <AddOutlined onClick={() => { addTodo(addTodoText);setAddTodoText(""); }} sx={{color: "#999"}} />
    </div>
    <form className='full' autoComplete="off">
    <input placeholder="Write a new Task, then press enter"
        autoComplete='new-password'
        className='todoInput full'
        value={addTodoText}
        onChange={submitTodo}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            if (addTodoText && addTodoText.length > 0) {
              addTodo(addTodoText);
            }
            e.preventDefault();
            setAddTodoText("");
          }
        }}
      /></form>
  </div>
}

export default Todo