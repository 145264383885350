import { ContextMenuItem } from "rctx-contextmenu";
import { ContextMenuTitle } from "../SpaceTree";
import { DeleteOutlined, DesignServicesOutlined } from "@mui/icons-material";
import { Divider, Typography } from "@mui/joy";

const SectionSettings = ({ renameSection, deleteSection }: any) => {
  
  return (
    <>
      <ContextMenuItem onClick={() => renameSection(true)}>
        <ContextMenuTitle>
          <DesignServicesOutlined sx={{ fontSize: 17 }} />
          Rename
        </ContextMenuTitle>
      </ContextMenuItem>
      <Divider />
      <ContextMenuItem onClick={() => deleteSection(true)}>
        <Typography
          textColor="red"
          fontSize={15}
          textTransform="none"
          alignItems="center"
          gap={1}
          display="flex"
        >
          <DeleteOutlined sx={{ fontSize: 17, color: "red" }} />
          Delete
        </Typography>
      </ContextMenuItem>
    </>
  );
};

export default SectionSettings;
