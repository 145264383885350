import { Button, FormControl, Grid, Tooltip, Typography } from "@mui/joy";
import _ from "lodash";
import { useState } from "react";
import { textInitials } from "../../../utilities/sharedUtilties";
import AddMembers from "../../../components/AddMembers";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {User} from "../../../redux/reducers/User";
import { addMembersToSpace,removeMemberFromSpace, makeMemberAdminToSpace,removeMemberAdminToSpace } from "../../../redux/reducers/Space/Space";
import useExiosPrivate from "../../../utilities/hooks/useAxiosPrivate";

const SpaceMembers = ({ data, onClose }: any) => {
  const user: any = useAppSelector(User);
  const dispatch = useAppDispatch();
  const exiosPrivate = useExiosPrivate();
  const [newMembersModal, setNewMemberModal] = useState(false);
  const setList = (members: any) => {
    dispatch(addMembersToSpace({ userList: members, exiosPrivate, spaceId: data.id }))
  };

  const removeUser = (userId: any) => {
    dispatch(removeMemberFromSpace({ userId, exiosPrivate, spaceId: data.id }))
  };

  const makeUserAdmin = (userId: any) => {
    dispatch(makeMemberAdminToSpace({ userId, exiosPrivate, spaceId: data.id }))
  };

  const removeAdminAccess = (userId: any) => {
    dispatch(removeMemberAdminToSpace({ userId, exiosPrivate, spaceId: data.id }))
  };

  const currentUserAdmin = data.isUserAdmin ?? false;
  const currentUserId = user.data._id;

  return (
    <Grid flexDirection="column" display="flex" sm={12} md={12} lg={8} mt={2}>
      <div className="p1 pt0">
        <Grid display="flex" alignItems="center" justifyContent="space-between">
          {data.members.length > 0 && (
            <Typography fontWeight={500}>
              {data.members.length}
              {`${data.members.length > 1 ? " Members" : " Member"}`}
            </Typography>
          )}
          {
            currentUserAdmin && <Typography
            fontWeight={500}
            textColor="#fb3d01"
            alignContent="center"
            display="flex"
            sx={{ cursor: "pointer", fontSize: "0.9rem" }}
            onClick={() => setNewMemberModal(true)}
          >
            Add Members
          </Typography>
          }
          
        </Grid>
        <div className="list-container">
          {data.members.length > 0 ? (
            data.members.map((member: any) => (
              <FormControl key={_.uniqueId("members-")}>
                <div className="flex-row checkbox-list user-list">
                  <div className="member-list-primary flex-row gHalf">
                    {member.dp && (
                      <Tooltip title={member.name}>
                        <div
                          data-username={textInitials(member.name)}
                          className={`${
                            member.dp.indexOf("undefined") > 0 && `default-dp`
                          } profile-logo`}
                        >
                          {member.dp.indexOf("undefined") < 0 && (
                            <img
                              style={{
                                borderRadius: "4px",
                              }}
                              alt="dp"
                              src={member.dp}
                            />
                          )}
                        </div>
                      </Tooltip>
                    )}
                    {!member.dp && (
                      <div
                        data-username={textInitials(member.name)}
                        className={`member-logo`}
                      ></div>
                    )}
                    <Typography fontWeight={500}>{member.name} {member.isAdmin && <span className="admin-pill">Admin</span>}</Typography>
                  </div>
                  {
                    currentUserId !== member.id && <div className="member-list-actions flex-row g1">
                    {
                        currentUserAdmin && !member.isAdmin &&
                        <Typography
                        className="remove-user-button"
                        textColor="#999"
                        fontSize={14}
                        fontWeight={500}
                        onClick={() => {
                          makeUserAdmin(member.id);
                          onClose();
                        }}
                      >
                      Make Admin
                            </Typography>
                      }
                      {
                        currentUserAdmin && member.isAdmin &&
                        <Typography
                        className="remove-user-button"
                        textColor="#999"
                        fontSize={14}
                        fontWeight={500}
                        onClick={() => {
                          removeAdminAccess(member.id);
                          onClose();
                        }}
                      >
                      Remove as admin
                            </Typography>
                      }
                    {
                        currentUserAdmin &&
                        <Typography
                        className="remove-user-button"
                        textColor="#999"
                        fontSize={14}
                        fontWeight={500}
                        onClick={() => {
                          removeUser(member.id);
                          onClose();
                        }}
                      >
                      Remove
                            </Typography>
                    }
                  </div>
                  }
                  
                  
                  
                </div>
              </FormControl>
            ))
          ) : (
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="300px"
              textColor={"#777"}
            >
              No members found.
            </Typography>
          )}
        </div>
      </div>
      {newMembersModal && (
        <AddMembers
          existingMembers={data.members}
          membersOnly={true}
          title="Add members"
          onClose={() => {
            setNewMemberModal(false);
          }}
          setList={setList}
        />
      )}
    </Grid>
  );
};

export default SpaceMembers;
