import React, { useState, useEffect } from "react";
import useExiosPrivate from "../../../../../utilities/hooks/useAxiosPrivate";
import { APIs } from "../../../../../constants/apis";
import { Tooltip } from "@mui/joy";
import { AddReactionOutlined } from "@mui/icons-material";
import EmojiPicker, { Emoji } from "emoji-picker-react";

export const FeedReaction = ({
  userId,
  reactions: rawReactions,
  feedId,
}: any) => {
  const exiosPrivate = useExiosPrivate();
  const [showEmojiContainer, toggleEmojiContainer] = useState(false);
  const [reactionInProgress, toggleReactionProgress] = useState(false);
  const [reactions, setReactions] = useState<any>([]);

  useEffect(() => {
    setReactions(rawReactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawReactions]);

  const setEmoji = async (reaction: string, onOff: boolean) => {
    try {
      toggleReactionProgress(true);
      const result = await exiosPrivate.post(
        APIs.CONTENT_TOGGLE_FEED_REACTION,
        {
          feedId,
          reactionId: reaction,
          onOff: !onOff,
        }
      );
      if (result.data) {
        setReactions(result.data);
      }
      toggleReactionProgress(false);
    } catch (err) {
      console.log("err", err);
    }
    toggleEmojiContainer(false);
  };

  const selectEmoji = async (emoji: any) => {
    const ifReactionExists = reactions.find(
      (reaction: any) => reaction.code === emoji.unified
    );
    if (!ifReactionExists) {
      try {
        toggleReactionProgress(true);
        const result = await exiosPrivate.post(APIs.CONTENT_ADD_FEED_REACTION, {
          feedId,
          reactionId: emoji.unified,
        });
        if (result.data) {
          setReactions(result.data);
        }
        toggleReactionProgress(false);
      } catch (err) {
        console.log("err", err);
      }
    }
    toggleEmojiContainer(false);
  };

  return (
    <>
      {reactions && reactions.length ? (
        <div className="feed-action">
          <div className="emojis-container">
            {reactions && reactions.length ? (
              <div className={`emoji-list ${reactionInProgress && `disable`}`}>
                {reactions.map((reaction: any) => (
                  <FeedEmojiReactionButton
                    setEmoji={setEmoji}
                    userId={userId}
                    reaction={reaction}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
            {/* <EmojiSelectButton
              title="Add Reaction"
              showEmojiContainer={showEmojiContainer}
              toggleEmojiContainer={toggleEmojiContainer}
              selectEmoji={selectEmoji}
            /> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const EmojiSelectButton = ({
  title,
  showEmojiContainer,
  toggleEmojiContainer,
  selectEmoji,
}: any) => {
  return (
    <>
      <Tooltip title={title}>
        <AddReactionOutlined
          onClick={() => toggleEmojiContainer(true)}
          className="reaction-add-btn"
        />
      </Tooltip>{" "}
      {showEmojiContainer && (
        <>
          <div
            onClick={() => toggleEmojiContainer(false)}
            className="reaction-picker-wrapper"
          ></div>
          <div className="reaction-picker">
            <EmojiPicker width="100%" onEmojiClick={selectEmoji} />
          </div>
        </>
      )}
    </>
  );
};

const FeedEmojiReactionButton = ({ userId, reaction, setEmoji }: any) => {
  const reactionerList = (
    <React.Fragment>
      {reaction.users.map((user: any) => (
        <div>{user.name}</div>
      ))}
    </React.Fragment>
  );

  const alreadyReacted = reaction.users.find((user: any) => user.id === userId);

  return (
    <Tooltip title={reaction.users.length > 0 ? reactionerList : ""}>
      <div
        onClick={() => setEmoji(reaction.code, !!alreadyReacted)}
        className={`emoji-button ${!!alreadyReacted && "active"}`}
      >
        <Emoji unified={reaction.code} />
        <span>{reaction.users.length}</span>
      </div>
    </Tooltip>
  );
};
