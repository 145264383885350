import TextFieldOptions from "./FloatingMenuComponents/TextFieldOptions";
import TextColorOptions from "./FloatingMenuComponents/TextColorOptions";
import TextEmojiOptions from "./FloatingMenuComponents/TextEmojiOptions";
import TextImageOptions from "./FloatingMenuComponents/TextImageOptions";
import TextTableOptions from "./FloatingMenuComponents/TextTableOptions";
export const FloatingMenuWrapper = ({ editor, actionMenuClick, setEmoji, setImage }: any) => {

  return (
    <div className="tippy-floater">
      <div className="basic-text-formatting">
        <TextFieldOptions isEditorFocus={editor?.isFocused} actionMenuClick={(type: string) => actionMenuClick(type)} />
          <TextColorOptions isEditorFocus={editor?.isFocused} actionMenuClick={(type: string) => actionMenuClick(type)} />
        <TextTableOptions actionMenuClick={ (type:string) => actionMenuClick(type) } />
        <TextEmojiOptions isEditorFocus={editor?.isFocused} setEmoji={setEmoji} />
        <TextImageOptions setImage={setImage} />
      </div>
    </div>
  );
};
