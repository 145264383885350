import { useEffect, useState } from "react";
import { BubbleMenu, useEditor, EditorContent, Extension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Blockquote from "@tiptap/extension-blockquote";
import Underline from "@tiptap/extension-underline";

import { BubbleComponentContent } from "./BubbleMenuWrapper";
import Placeholder from "@tiptap/extension-placeholder";
import { BubbleMenuDocumentWrapper } from "./BubbleMenuDocumentWrapper";

export const enum TipTapMenuActions {
  bold = "bold",
  italic = "italic",
  underline = "underline",
  bullet = "bullet",
  ordered = "ordered",
  code = "code",
  color_red = "color_red",
  color_green = "color_green",
  color_blue = "color_blue",
  textLeft = "textLeft",
  textCenter = "textCenter",
  textRight = "textRight",
  block = "block",
  highlight = "highlight",
  strike = "strike",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  hRule = "hRule",
  formatClear = "formatClear",
  table = "table",

  color_Default = '#333',
  color_Grey = "rgb(120, 119, 116)",
  color_Brown = "rgb(159, 107, 83)",
  color_Orange = "rgb(217, 115, 13)",
  color_Yellow = "rgb(203, 145, 47)",
  color_Green = "rgb(68, 131, 97)",
  color_Blue = "rgb(51, 126, 169)",
  color_Purple = "rgb(144, 101, 176)",
  color_Pink = "rgb(193, 76, 138)",
  color_Red = "rgb(212, 76, 71)",
  bgColor_Default = '#fff',
  bgColor_Grey = "rgb(241, 241, 239)",
  bgColor_Brown = "rgb(244, 238, 238)",
  bgColor_Orange = "rgb(251, 236, 221)",
  bgColor_Yellow = "rgb(251, 243, 219)",
  bgColor_Green = "rgb(237, 243, 236)",
  bgColor_Blue = "rgb(231, 243, 248)",
  bgColor_Purple = "rgba(244, 240, 247, 0.8)",
  bgColor_Pink = "rgba(249, 238, 243, 0.8)",
  bgColor_Red = "rgb(253, 235, 236)",
}

export const SimpleTiptapWrapper = ({
  content,
  updateDocumentContent,
  readOnly = false,
  placeholder = "",
  currentContent,
  enterContent,
  emojiContent,
  autofocus
}: any) => {
  const ShiftEnterCreateExtension = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          enterContent();
          return true;
        },
      };
    },
  });

  const extensions = [
    StarterKit,
    Document,
    Paragraph,
    Text,
    Link.configure({
      openOnClick: true,
    }),
    Color,
    TextStyle,
    Highlight,
    Blockquote,
    Underline,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Placeholder.configure({
      placeholder,
    }),
    ShiftEnterCreateExtension,
  ];
  const editor = useEditor({
    extensions: extensions,
    content: "",
  });

  useEffect(() => {
    editor?.setEditable(!readOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, readOnly]);

  useEffect(() => {
    editor?.commands.setContent(content);
    editor?.commands.focus();
  }, [content, editor]);

  useEffect(() => {
    if (currentContent === "") {
      editor?.commands.setContent("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent]);

  useEffect(() => {
    editor?.off("update");
    editor?.on("update", ({ editor: updatedEditor }) =>
      updateDocumentContent(updatedEditor.getHTML())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    editor?.commands.insertContent(emojiContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiContent]);

  const actionMenuClick = (type: string) => {
    switch (type) {
      case TipTapMenuActions.bold:
        editor?.chain().focus().toggleBold().run();
        break;

      case TipTapMenuActions.italic:
        editor?.chain().focus().toggleItalic().run();
        break;

      case TipTapMenuActions.underline:
        editor?.chain().focus().toggleUnderline().run();
        break;

      case TipTapMenuActions.strike:
        editor?.chain().focus().toggleStrike().run();
        break;

      case TipTapMenuActions.bullet:
        editor?.chain().focus().toggleBulletList().run();
        break;

      case TipTapMenuActions.ordered:
        editor?.chain().focus().toggleOrderedList().run();
        break;

      case TipTapMenuActions.code:
        editor?.chain().focus().toggleCode().run();
        break;

      case TipTapMenuActions.color_red:
        editor?.chain().focus().setColor("#ff0000").run();
        break;

      case TipTapMenuActions.color_green:
        editor?.chain().focus().setColor("#6cbe00").run();
        break;

      case TipTapMenuActions.color_blue:
        editor?.chain().focus().setColor("#09F").run();
        break;

      case TipTapMenuActions.textLeft:
        editor?.chain().focus().setTextAlign("left").run();
        break;

      case TipTapMenuActions.textCenter:
        editor?.chain().focus().setTextAlign("center").run();
        break;

      case TipTapMenuActions.textRight:
        editor?.chain().focus().setTextAlign("right").run();
        break;

      case TipTapMenuActions.block:
        editor?.chain().focus().toggleBlockquote().run();
        break;

      case TipTapMenuActions.highlight:
        editor?.chain().focus().toggleHighlight().run();
        break;

      case TipTapMenuActions.h1:
        editor?.chain().focus().toggleHeading({ level: 1 }).run();
        break;

      case TipTapMenuActions.h2:
        editor?.chain().focus().toggleHeading({ level: 2 }).run();
        break;

      case TipTapMenuActions.h3:
        editor?.chain().focus().toggleHeading({ level: 3 }).run();
        break;

      case TipTapMenuActions.h4:
        editor?.chain().focus().toggleHeading({ level: 4 }).run();
        break;

      case TipTapMenuActions.hRule:
        editor?.chain().focus().setHorizontalRule().run();
        break;
      
      case TipTapMenuActions.formatClear:
        editor?.chain().focus().clearNodes().run();
        break;
    }
  };

  useEffect(() => {
    return () => {
      editor?.commands.setContent(null);
      if (autofocus) {
        editor?.commands.focus();
      }
    };
  }, []);

  return (
    <>
      <EditorContent editor={editor}></EditorContent>
      <>
        {editor && (
          <BubbleMenu editor={editor}>
            <BubbleComponentContent
              actionMenuClick={actionMenuClick}
            ></BubbleComponentContent>
          </BubbleMenu>
        )}
      </>
    </>
  );
};

export const DocumentTiptapWrapper = ({
  content,
  updateDocumentContent,
  readOnly = false,
  placeholder = "",
  currentContent,
  emojiContent,
  parentId
}: any) => {

  const extensions = [
    StarterKit,
    Document,
    Paragraph,
    Text,
    Link.configure({
      openOnClick: true,
    }),
    Color,
    TextStyle,
    Highlight,
    Blockquote,
    Underline,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Placeholder.configure({
      placeholder,
    }),
  ];
  const editor = useEditor({
    extensions: extensions,
    content: "",
  });

  useEffect(() => {
    editor?.setEditable(!readOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, readOnly]);

  // useEffect(() => {
  //   return () => {
  //     editor?.destroy()
  //   }
  // }, [parentId])


  useEffect(() => {
    editor?.commands.setContent(content);
    editor?.commands.focus();
  }, [content, editor]);

  useEffect(() => {
    if (currentContent === "") {
      editor?.commands.setContent("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent]);

  useEffect(() => {
    editor?.off("update");
    editor?.on("update", ({ editor: updatedEditor }) => {
      updateDocumentContent(updatedEditor.getHTML());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    editor?.commands.insertContent(emojiContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiContent]);

  useEffect(() => {
    return () => {
      editor?.destroy()
    }
  }, [editor])

  const actionMenuClick = (type: string) => {
    switch (type) {
      case TipTapMenuActions.bold:
        editor?.chain().focus().toggleBold().run();
        break;

      case TipTapMenuActions.italic:
        editor?.chain().focus().toggleItalic().run();
        break;

      case TipTapMenuActions.underline:
        editor?.chain().focus().toggleUnderline().run();
        break;

      case TipTapMenuActions.strike:
        editor?.chain().focus().toggleStrike().run();
        break;

      case TipTapMenuActions.bullet:
        editor?.chain().focus().toggleBulletList().run();
        break;

      case TipTapMenuActions.ordered:
        editor?.chain().focus().toggleOrderedList().run();
        break;

      case TipTapMenuActions.code:
        editor?.chain().focus().toggleCode().run();
        break;

      case TipTapMenuActions.color_red:
        editor?.chain().focus().setColor("#ff0000").run();
        break;

      case TipTapMenuActions.color_green:
        editor?.chain().focus().setColor("#6cbe00").run();
        break;

      case TipTapMenuActions.color_blue:
        editor?.chain().focus().setColor("#09F").run();
        break;

      case TipTapMenuActions.textLeft:
        editor?.chain().focus().setTextAlign("left").run();
        break;

      case TipTapMenuActions.textCenter:
        editor?.chain().focus().setTextAlign("center").run();
        break;

      case TipTapMenuActions.textRight:
        editor?.chain().focus().setTextAlign("right").run();
        break;

      case TipTapMenuActions.block:
        editor?.chain().focus().toggleBlockquote().run();
        break;

      case TipTapMenuActions.highlight:
        editor?.chain().focus().toggleHighlight().run();
        break;

      case TipTapMenuActions.h1:
        editor?.chain().focus().toggleHeading({ level: 1 }).run();
        break;

      case TipTapMenuActions.h2:
        editor?.chain().focus().toggleHeading({ level: 2 }).run();
        break;

      case TipTapMenuActions.h3:
        editor?.chain().focus().toggleHeading({ level: 3 }).run();
        break;

      case TipTapMenuActions.h4:
        editor?.chain().focus().toggleHeading({ level: 4 }).run();
        break;

      case TipTapMenuActions.hRule:
        editor?.chain().focus().setHorizontalRule().run();
        break;
      
      case TipTapMenuActions.formatClear:
        editor?.chain().focus().unsetAllMarks().run();
        break;
    }
  };

  useEffect(() => {
    return () => {
      editor?.commands.setContent(null);
      editor?.destroy();
    };
  }, []);

  return (
    <>
      <EditorContent editor={editor}></EditorContent>
      <>
        {editor && (
          <BubbleMenu editor={editor}>
            <BubbleMenuDocumentWrapper
              actionMenuClick={actionMenuClick}
            ></BubbleMenuDocumentWrapper>
          </BubbleMenu>
        )}
      </>
    </>
  );
};