import React from "react";
import Feed from "../Forums/Feed";
import Document from "../Documents";
import Todo from "../Todo";
import _ from "lodash";

export const enum SectionTypeNames {
  CHAT = "chat",
  FEED = "feed",
  TASKER = "tasker",
  BOARD = "board",
  QA = "qa",
  DOCUMENT = "document",
}

const SectionRenderer = ({ entity, type, id }: any) => {
  switch (type) {
    case SectionTypeNames.FEED:
      return <div id={_.uniqueId("feed-")}><Feed entity={entity} id={id} /></div>;
    case SectionTypeNames.DOCUMENT:
      return <div id={_.uniqueId("document-")}><Document entity={entity} id={id} /></div>;
    case SectionTypeNames.TASKER:
      return <div id={_.uniqueId("tasker-")}><Todo entity={entity} id={id} /></div>;
  }
  return <div>Section Invalid</div>;
};

export default SectionRenderer;
