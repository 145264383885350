import React from "react";
import { Button, Grid } from "@mui/joy";
import { Stack } from "@mui/material";
import { ButtonWrapper } from "../../components/Misc/ButtonWrapper";
import { Typography } from "@mui/material";
import PATHS from "../../constants/paths";
import { useNavigate } from "react-router-dom";
import { FormCard } from "../../components/cards/formCard";

export const Body = () => {
  const navigate = useNavigate();
  return (
    <div className="body">
      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid xs></Grid>
        <Grid xs={10} sm={8} md={6} lg={4}>
          <FormCard noHeader={true}>
            <Grid container>
              <Grid xs>
                <div className="hero text-center">
                  <div className="landing-illustration"></div>
                  <br />
                  <h1>Adaily is epitome of doing great work.</h1>
                  <h3 className="lines">Collaborative, Efficient & Minimal.</h3>
                </div>
              </Grid>
            </Grid>
            <Stack alignItems="center" mb={4}>
              <ButtonWrapper>
                <Button
                  size="lg"
                  className="shadown carrot"
                  color="primary"
                  onClick={() => navigate(PATHS.signup)}
                >
                  Sign up for Adaily
                </Button>
              </ButtonWrapper>
            </Stack>
          </FormCard>
        </Grid>
        <Grid xs></Grid>
      </Grid>
    </div>
  );
};
